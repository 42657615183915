import { Tooltip } from "@mui/joy";
import { Paper, styled, TableCell } from "@mui/material";
import { grey } from "@mui/material/colors";
import { PureComponent, type ReactNode } from "react";
import {
  AutoSizer,
  Column,
  Table,
  type TableCellDataGetter,
  type TableCellProps,
  type TableCellRenderer,
  type TableHeaderProps,
  type TableProps,
} from "react-virtualized";

interface ColumnData {
  dataKey: string;
  label: ReactNode;
  numeric?: boolean;
  widthFactor?: number;
  withTooltip?: boolean;
  style?: React.CSSProperties;
  cellDataGetter?: TableCellDataGetter;
  maxWidth?: number;
}

type TableHeaderRenderer = {
  columnIndex: number;
} & TableHeaderProps;

const StyledTable = styled(Table)(({ theme: { spacing }, striped }) => ({
  "& .MuiTableCell-root": {
    border: "none",
  },
  "& .ReactVirtualized__Table__headerColumn": {
    overflow: "hidden",
  },
  "& .ReactVirtualized__Table__headerRow": {
    borderBottom: `1px solid lightgrey`,
    display: "flex",
    margin: 0,
    padding: spacing(1, 0.5),
  },
  "& .ReactVirtualized__Table__rowColumn": {
    height: "100%",
  },
  ...(striped
    ? {
        "& .ReactVirtualized__Table__row:nth-of-type(even)": {
          backgroundColor: "whitesmoke",
        },
      }
    : {
        "& .ReactVirtualized__Table__row": {
          "&:hover": {
            background: grey[200],
          },
          "&:not(:last-of-type)": {
            borderBottom: `2px solid ${grey[200]}`,
          },
          cursor: "pointer",
          transition: "all 0.2s linear",
        },
        "& .ReactVirtualized__Table__rowColumn": {
          "& > *": {
            cursor: "pointer",
          },
        },
      }),
}));

interface VirtualizedTableProps extends Partial<TableProps> {
  columns: ColumnData[];
  headerHeight?: number;
  rowCount: number;
  rowHeight?: number;
  height?: number;
  width?: number;
  minColumnWidth?: number;
  withHeader?: boolean;
  maxRowsShown?: number;
  striped?: boolean;
}

class VirtualizedTable extends PureComponent<VirtualizedTableProps> {
  static defaultProps: Partial<VirtualizedTableProps> = {
    headerHeight: 38,
    minColumnWidth: 200,
    rowHeight: 34,
  };

  cellRenderer: TableCellRenderer = ({
    cellData,
    columnIndex,
    ...restTableCellProps
  }: TableCellProps) => {
    const { columns, cellRenderer } = this.props;
    const content = cellRenderer
      ? cellRenderer({ cellData, columnIndex, ...restTableCellProps })
      : cellData;
    const withTooltip = columns[columnIndex].withTooltip ?? true;
    return (
      <TableCell
        align={
          (columnIndex != null && columns[columnIndex].numeric) || false
            ? "right"
            : "left"
        }
        component="div"
        style={{
          alignItems: "center",
          boxSizing: "border-box",
          display: "flex",
          flex: 1,
          padding: "12px",
          width: "100%",
        }}
        variant="body"
      >
        {withTooltip ? (
          <Tooltip placement="top" title={content}>
            <span
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {content}
            </span>
          </Tooltip>
        ) : (
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {content}
          </span>
        )}
      </TableCell>
    );
  };

  headerRenderer = (headerProps: TableHeaderRenderer) => {
    const { columns, headerRenderer } = this.props;
    const headerContent = headerRenderer
      ? headerRenderer({
          ...headerProps,
          columnData: columns[headerProps?.columnIndex],
        })
      : headerProps?.label;
    return (
      <TableCell
        align={
          columns[headerProps?.columnIndex].numeric || false ? "right" : "left"
        }
        component="div"
        style={{
          alignItems: "center",
          boxSizing: "border-box",
          color: "inherit",
          display: "flex",
          flex: 1,
          padding: "8px",
          width: "100%",
        }}
        variant="head"
      >
        <span
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
          title={
            typeof headerProps?.label === "string"
              ? (headerProps?.label as string)
              : undefined
          }
        >
          {headerContent}
        </span>
      </TableCell>
    );
  };

  render() {
    const {
      columns,
      rowHeight,
      headerHeight,
      withHeader,
      maxRowsShown: maxRowsShow,
      striped = true,
      minColumnWidth = VirtualizedTable.defaultProps.minColumnWidth,
      height,
      width,
      ...tableProps
    } = this.props;
    const shouldShrinkContainer =
      maxRowsShow && maxRowsShow < tableProps?.rowCount;
    const containerHeight =
      ((shouldShrinkContainer ? maxRowsShow! : tableProps?.rowCount) +
        (withHeader ? 1 : 0)) *
      rowHeight!;
    return (
      <div
        style={{
          height: containerHeight,
          overflowX: "auto",
          width: "100%",
        }}
      >
        <AutoSizer>
          {({ height, width }) => {
            const columnWidth = width / columns.length;
            const isColumnWidthTooSmall = columnWidth < minColumnWidth!;
            const tableWidth = isColumnWidthTooSmall
              ? minColumnWidth! * columns.length
              : width;
            const tableHeight = height || 500;
            return (
              <Paper
                style={{
                  boxShadow: "none",
                  height: tableHeight,
                  width: tableWidth,
                }}
              >
                <StyledTable
                  disableHeader={!withHeader}
                  gridStyle={{
                    direction: "inherit",
                  }}
                  headerHeight={headerHeight!}
                  height={tableHeight}
                  rowHeight={rowHeight!}
                  rowStyle={{
                    alignItems: "center",
                    boxSizing: "border-box",
                    display: "flex",
                    flex: 1,
                    width: tableWidth,
                  }}
                  striped={striped}
                  width={tableWidth}
                  {...tableProps}
                >
                  {columns.map(
                    ({ dataKey, widthFactor, style = {}, ...other }, index) => (
                      <Column
                        cellRenderer={this.cellRenderer}
                        dataKey={dataKey}
                        headerRenderer={
                          withHeader
                            ? (headerProps) =>
                                this.headerRenderer({
                                  ...headerProps,
                                  columnIndex: index,
                                })
                            : undefined
                        }
                        key={dataKey}
                        style={{
                          alignItems: "center",
                          border: "none",
                          boxSizing: "border-box",
                          display: "flex",
                          flex: 1,
                          ...style,
                        }}
                        {...other}
                        width={
                          (isColumnWidthTooSmall
                            ? minColumnWidth!
                            : columnWidth) * (widthFactor || 1)
                        }
                      />
                    )
                  )}
                </StyledTable>
              </Paper>
            );
          }}
        </AutoSizer>
      </div>
    );
  }
}

export default VirtualizedTable;
