import { useAuth0 } from "@auth0/auth0-react";
import { InfoTooltip } from "@decentriq/components";
import { useDataRoomRequestTempApproversQuery } from "@decentriq/graphql/dist/hooks";
import { Box, Typography } from "@mui/joy";
import { memo } from "react";
import DataRoomTableParticipantAvatar from "components/entities/dataRoom/DataRoomParticipantAvatar/DataRoomParticipantAvatar";

interface RequestApproversProps {
  requestId: string;
}

const RequestApprovers: React.FC<RequestApproversProps> = ({ requestId }) => {
  const { user } = useAuth0();
  const currentUserEmail = user?.email;
  const { data } = useDataRoomRequestTempApproversQuery({
    variables: {
      id: requestId,
    },
  });
  const approvers = data?.dataRoomRequest?.tempApprovers;
  return (
    <>
      <Typography level="body-sm">
        <strong>Affected data owners:</strong>
        <InfoTooltip tooltip="All participants with permissions to provision datasets to dependencies of this computation are required to approve the request." />
      </Typography>
      {approvers?.length ? (
        approvers.map((a) => (
          <Box alignItems="center" display="flex" key={a}>
            <DataRoomTableParticipantAvatar name={a} />
            <Typography
              style={{
                marginLeft: "0.25rem",
              }}
            >
              {a}
              {a === currentUserEmail && (
                <strong style={{ marginLeft: ".5rem" }}>(You)</strong>
              )}
            </Typography>
          </Box>
        ))
      ) : (
        <Typography level="body-sm" sx={{ marginTop: "6px" }}>
          {approvers
            ? "no dependencies yet"
            : "Please validate the computation to determine the affected data owners."}
        </Typography>
      )}
    </>
  );
};

RequestApprovers.displayName = "RequestApprovers";

export default memo(RequestApprovers);
