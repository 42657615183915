import { testIds } from "@decentriq/utils";
import { Box, Tab, TabList, TabPanel, Tabs, Typography } from "@mui/joy";
import { memo } from "react";
import { Link, useResolvedPath } from "react-router-dom";
import { OrganizationsPage, UsersPage } from "pages";

export enum AdminPortalTabs {
  ORGANIZATIONS = "organizations",
  USERS = "users",
}

type AdminPortalProps = {
  activeTab: AdminPortalTabs;
};

const AdminPortal: React.FC<AdminPortalProps> = ({ activeTab }) => {
  const { pathname } = useResolvedPath("/admin");

  return (
    <Tabs value={activeTab}>
      <Box
        sx={{
          backgroundColor: "background.bar",
          display: "flex",
          justifyContent: "space-between",
          padding: 2,
        }}
      >
        <Typography level="title-md">
          <strong>Admin Portal</strong>
        </Typography>
      </Box>
      <Box sx={{ backgroundColor: "background.bar" }}>
        <TabList>
          <Tab
            component={Link}
            data-testid={testIds.adminPortal.tabList.organizations}
            to={`${pathname}/${AdminPortalTabs.ORGANIZATIONS}`}
            value={AdminPortalTabs.ORGANIZATIONS}
          >
            Organizations
          </Tab>
          <Tab
            component={Link}
            to={`${pathname}/${AdminPortalTabs.USERS}`}
            value={AdminPortalTabs.USERS}
          >
            Users
          </Tab>
        </TabList>
      </Box>
      <Box sx={{ flex: 1, margin: 2.5, overflow: "hidden" }}>
        <TabPanel
          sx={{
            flexDirection: "column",
            height: "100%",
            padding: "0 !important",
          }}
          value={AdminPortalTabs.ORGANIZATIONS}
        >
          <OrganizationsPage />
        </TabPanel>
        <TabPanel
          sx={{
            flexDirection: "column",
            height: "100%",
            padding: "0 !important",
          }}
          value={AdminPortalTabs.USERS}
        >
          <UsersPage />
        </TabPanel>
      </Box>
    </Tabs>
  );
};

AdminPortal.displayName = "AdminPortal";

export default memo(AdminPortal);
