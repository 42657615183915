import { testIds } from "@decentriq/utils";
import { Box } from "@mui/joy";
import { Link, List, styled } from "@mui/material";
import { memo } from "react";
import { Link as RouterLink } from "react-router-dom";
import DQLogoSquareBlack from "assets/logos/decentriq-platform-logo-square-black.svg";
import { useOrganizationPreferences, useUserRole } from "hooks";
import DataPartnerPortalMenuItem from "./components/DataPartnerPortalMenuItem/DataPartnerPortalMenuItem";
import {
  AdminPortalSidebarMenuItem,
  DataRoomsListSidebarMenuItem,
  DatasetsSidebarMenuItem,
  DocumentationSidebarMenuItem,
  KeychainSidebarMenuItem,
  NewDataRoomSidebarMenuItem,
  OrganizationLogoSidebarItem,
  PublisherPortalMenuItem,
  UserMenuSidebarItem,
} from "./components";

export const SIDEBAR_WIDTH: number = 100;

export const SidebarWrapper = styled(List)(({ theme }) => ({
  alignItems: "center",
  backgroundColor: theme.palette.background.bar,
  display: "flex",
  flexDirection: "column",
  height: "100%",
  minWidth: "100px",
  overflowY: "auto",
  padding: theme.spacing(1, 1, 2, 1),
  width: `${SIDEBAR_WIDTH}px`,
}));

const Sidebar: React.FC = () => {
  const {
    isSubscribedState,
    isSuspendedState,
    hasPublisherFeatures,
    hasDataPartnerFeatures,
  } = useOrganizationPreferences();
  const { isSuperAdmin, isOrganizationAdmin, isSuperAdminReadOnly } =
    useUserRole();

  return (
    <SidebarWrapper>
      <Link
        color="inherit"
        component={RouterLink}
        data-testid={testIds.sidebar.sidebarMenuItems.homeButton}
        m={1}
        sx={{
          display: "flex",
          justifyContent: "center",
          mb: 2,
        }}
        to="/"
      >
        <DQLogoSquareBlack height="auto" width="100%" />
      </Link>
      <Box mb={2} overflow="auto" width="100%">
        <NewDataRoomSidebarMenuItem />
        <DataRoomsListSidebarMenuItem />
        {hasPublisherFeatures && <PublisherPortalMenuItem />}
        {hasDataPartnerFeatures && <DataPartnerPortalMenuItem />}
        <DatasetsSidebarMenuItem />
        <KeychainSidebarMenuItem />
        {(isSuperAdmin || isOrganizationAdmin || isSuperAdminReadOnly) &&
          (isSubscribedState || isSuspendedState) && (
            <AdminPortalSidebarMenuItem />
          )}
        <DocumentationSidebarMenuItem />
      </Box>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          marginTop: "auto",
          width: "100%",
        }}
      >
        <OrganizationLogoSidebarItem />
        <UserMenuSidebarItem />
      </Box>
    </SidebarWrapper>
  );
};

Sidebar.displayName = "Sidebar";

export default memo(Sidebar);
