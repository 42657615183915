import {
  useRequestsQuery,
  useSubmittedDataRoomRequestsQuery,
} from "@decentriq/graphql/dist/hooks";
import { Box, CircularProgress, Typography } from "@mui/joy";
import { List, styled } from "@mui/material";
import React, { Fragment, useMemo } from "react";
import useDataRoomEnableImmutability from "components/entities/dataRoomParticipants/EnableImmutabilityCheckbox/useDataRoomEnableImmutability";
import { RequestProvider } from "contexts";
import { RequestsVarsWrapper, SubmittedRequestsVarsWrapper } from "wrappers";
import RequestCreatorPlaceholder from "../RequestCreatorPlaceholder/RequestCreatorPlaceholder";
import RequestItem from "../RequestItem/RequestItem";

const StyledList = styled(List)(({ theme }) => ({
  padding: theme.spacing(0),
}));

interface RequestsProps {
  dataRoomId: string;
  defaultExpandedRequests?: string[];
  defaultExpandedComputations?: string[];
}

const Requests: React.FC<RequestsProps> = ({
  dataRoomId,
  defaultExpandedRequests,
  defaultExpandedComputations,
}) => {
  const hasDevTab = !useDataRoomEnableImmutability();
  const { data: requestsData, loading: requestsLoading } = useRequestsQuery({
    fetchPolicy: "network-only",
    variables: { id: dataRoomId },
  });
  const { data: submittedRequestsData, loading: submittedRequestsLoading } =
    useSubmittedDataRoomRequestsQuery({
      variables: { id: dataRoomId },
    });
  const requests = useMemo(
    () =>
      requestsData?.publishedDataRoom?.requests?.nodes?.map((n) => ({
        computeNodeId: n.draftNode.id,
        requestId: n.id,
      })) || [],
    [requestsData]
  );
  const requestsIds = useMemo(
    () => requests.map((r) => r.requestId),
    [requests]
  );
  const submittedRequests = useMemo(
    () =>
      submittedRequestsData?.publishedDataRoom?.submittedRequests?.nodes?.map(
        (n) => ({
          commitId: n.node.commitId,
          computeNodeId: n.node.id,
          requestId: n.id,
        })
      ) || [],
    [submittedRequestsData]
  );
  const submittedRequestsIds = useMemo(
    () => submittedRequests.map((r) => r.requestId),
    [submittedRequests]
  );
  const loading =
    (requestsLoading || submittedRequestsLoading) &&
    !(requests.length || submittedRequests.length);
  return (
    <>
      <Typography level="title-md">Requests for new computations</Typography>
      {loading ? (
        <Box
          alignItems="center"
          display="flex"
          justifyContent="center"
          padding="1rem"
        >
          <CircularProgress sx={{ "--CircularProgress-size": "1.5rem" }} />
        </Box>
      ) : (
        <>
          <Typography level="body-md" style={{ marginBottom: "1rem" }}>
            This data clean room allows requests for new computations.
            <br />
            Before a computation can be integrated, it needs to be submitted for
            approval by the affected Data Owner(s).
          </Typography>
          {submittedRequests.length > 0 ? (
            <SubmittedRequestsVarsWrapper
              defaultExpandedComputations={defaultExpandedComputations}
              defaultExpandedRequests={defaultExpandedRequests}
              submittedRequestsIds={submittedRequestsIds}
            >
              <StyledList>
                {submittedRequests.map(
                  ({ computeNodeId, requestId, commitId }) => (
                    <RequestProvider
                      key={requestId}
                      value={{ commitId, computeNodeId, requestId }}
                    >
                      <RequestItem requestId={requestId} />
                    </RequestProvider>
                  )
                )}
              </StyledList>
            </SubmittedRequestsVarsWrapper>
          ) : undefined}
          {requests.length > 0 ? (
            <RequestsVarsWrapper
              defaultExpandedComputations={defaultExpandedComputations}
              defaultExpandedRequests={defaultExpandedRequests}
              requestsIds={requestsIds}
            >
              <StyledList>
                {requests.map(({ computeNodeId, requestId }) => (
                  <RequestProvider
                    key={requestId}
                    value={{ commitId: undefined, computeNodeId, requestId }}
                  >
                    <RequestItem requestId={requestId} />
                  </RequestProvider>
                ))}
              </StyledList>
            </RequestsVarsWrapper>
          ) : undefined}
          {hasDevTab && <RequestCreatorPlaceholder />}
        </>
      )}
    </>
  );
};

export default Requests;
