import { testIds } from "@decentriq/utils";
import { CircularProgress, Tab, TabList, TabPanel, Tabs } from "@mui/joy";
import { memo } from "react";
import { Link, Navigate, Outlet } from "react-router-dom";
import { DataRoomAuditLogs } from "containers";
import {
  MediaDataRoomData,
  MediaDataRoomInsights,
  MediaDataRoomInsightsWrapper,
  MediaDataRoomTab,
  OverlapInsightsExecutionContext,
  useMediaInsightsDcrData,
  usePublishedMediaInsightsDcr,
} from "features/MediaInsightsDcr";
import { AdvertiserAudiencesContextWrapper } from "../contexts/advertiserAudiences/advertiserAudiences";

interface MediaDataRoomTabsProps {
  activeTab: MediaDataRoomTab;
  baseUrl: string;
}

const MediaDataRoomTabs: React.FC<MediaDataRoomTabsProps> = ({
  activeTab,
  baseUrl,
}) => {
  const {
    isAdvertiser,
    isAgency,
    isObserver,
    isPublisher,
    loading: dataRoomLoading,
    dataRoomId,
    driverAttestationHash,
    features: {
      insights: enableInsights,
      lookalike: enableLookalike,
      retargeting: enableRetargeting,
      exclusionTargeting: enableExclusionTargeting,
    },
  } = usePublishedMediaInsightsDcr();

  const { dataLabDataLoading } = useMediaInsightsDcrData();
  const loading = dataRoomLoading || dataLabDataLoading;

  if (loading) {
    return (
      <CircularProgress
        sx={{ "--CircularProgress-size": "2.5rem", margin: "auto" }}
      />
    );
  }

  const isInsightsTabEnabled =
    enableInsights && (isObserver || isPublisher || isAdvertiser || isAgency);

  const isActivationTabEnabled =
    (enableLookalike || enableRetargeting || enableExclusionTargeting) &&
    (isObserver || isPublisher || isAdvertiser || isAgency);

  if (
    (activeTab === MediaDataRoomTab.insights && !enableInsights) ||
    (activeTab === MediaDataRoomTab.activation && !isActivationTabEnabled)
  ) {
    return <Navigate to={`${baseUrl}/${MediaDataRoomTab.data}`} />;
  }
  return (
    <Tabs key={dataRoomId} sx={{ overflow: "hidden" }} value={activeTab}>
      <TabList>
        <Tab
          component={Link}
          data-testid={testIds.containers.mediaDataRoom.data}
          to={`${baseUrl}/${MediaDataRoomTab.data}`}
          value={MediaDataRoomTab.data}
        >
          Data
        </Tab>
        {isInsightsTabEnabled && (
          <Tab
            component={Link}
            data-testid={testIds.containers.mediaDataRoom.insights}
            to={`${baseUrl}/${MediaDataRoomTab.insights}`}
            value={MediaDataRoomTab.insights}
          >
            Insights
          </Tab>
        )}
        {isActivationTabEnabled && (
          <Tab
            component={Link}
            data-testid={testIds.containers.mediaDataRoom.activation}
            to={`${baseUrl}/${MediaDataRoomTab.activation}`}
            value={MediaDataRoomTab.activation}
          >
            Activation
          </Tab>
        )}
        <Tab
          component={Link}
          data-testid={testIds.containers.mediaDataRoom.auditLog}
          to={`${baseUrl}/${MediaDataRoomTab.auditLog}`}
          value={MediaDataRoomTab.auditLog}
        >
          Audit log
        </Tab>
      </TabList>
      <TabPanel sx={{ overflow: "auto" }} value={MediaDataRoomTab.data}>
        <MediaDataRoomData />
      </TabPanel>
      {isInsightsTabEnabled && (
        <TabPanel sx={{ overflow: "auto" }} value={MediaDataRoomTab.insights}>
          <MediaDataRoomInsightsWrapper
            executionContext={OverlapInsightsExecutionContext.insightsTab}
          >
            <MediaDataRoomInsights />
          </MediaDataRoomInsightsWrapper>
        </TabPanel>
      )}
      {isActivationTabEnabled && (
        <TabPanel sx={{ overflow: "auto" }} value={MediaDataRoomTab.activation}>
          <AdvertiserAudiencesContextWrapper>
            <Outlet />
          </AdvertiserAudiencesContextWrapper>
        </TabPanel>
      )}
      <TabPanel sx={{ overflow: "auto" }} value={MediaDataRoomTab.auditLog}>
        <DataRoomAuditLogs
          dcrHash={dataRoomId!}
          driverAttestationHash={driverAttestationHash!}
        />
      </TabPanel>
    </Tabs>
  );
};

MediaDataRoomTabs.displayName = "MediaDataRoomTabs";

export default memo(MediaDataRoomTabs);
