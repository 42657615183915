import { Chip, Grid, Typography } from "@mui/joy";
import { memo } from "react";
import { useDataPartnerPortal } from "features/dataPartnerPortal/contexts";
import { OrganizationTile } from "features/mediaPortalShared";

const DataPartnerDisplayConfigurationPresentation: React.FC = () => {
  const { displayConfiguration } = useDataPartnerPortal();
  if (!displayConfiguration) {
    return null;
  }
  const { name, collaborationRequestUsers, description, logo, marketIds } =
    displayConfiguration;
  return (
    <Grid
      columnSpacing={4}
      container={true}
      sx={{ flex: 1, minWidth: "1024px", overflowY: "scroll", pl: 2, pr: 2 }}
    >
      <OrganizationTile
        imageSx={{ maxHeight: "50px", p: 0, pb: 1 }}
        logo={logo as string | undefined}
        marketIds={marketIds}
        name={name}
        sx={{ height: "150px", ml: 2, width: "232px" }}
      />
      <Grid lg={4} xs={4}>
        <Typography color="textSecondary" level="body-sm" mb={1}>
          Description
        </Typography>
        <Typography level="body-md" sx={{ wordBreak: "break-all" }}>
          {description}
        </Typography>
      </Grid>
      <Grid lg={5} xl={6} xs={4}>
        <Typography color="textSecondary" level="body-sm" mb={1}>
          Contact request email
        </Typography>
        <Typography level="body-md">
          {collaborationRequestUsers.map((email) => (
            <Chip
              key={email}
              sx={{
                borderRadius: 0,
                height: "28px",
                mb: 1,
                mr: 1,
                mt: 0,
              }}
            >
              {
                <Typography fontWeight="medium" level="body-sm">
                  {email}
                </Typography>
              }
            </Chip>
          ))}
        </Typography>
      </Grid>
    </Grid>
  );
};

DataPartnerDisplayConfigurationPresentation.displayName =
  "DataPartnerDisplayConfigurationPresentation";

export default memo(DataPartnerDisplayConfigurationPresentation);
