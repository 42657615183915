// IMPORTANT! This node is not branched for interactivity modes

import { Box, Typography } from "@mui/joy";
import { memo } from "react";
import { ConnectionParamsForm, ObjectUploadForm } from "./components";

interface S3ComputeNodeEditorProps {
  readOnly?: boolean;
  computeNodeId: string;
}

const S3ComputeNodeEditor: React.FC<S3ComputeNodeEditorProps> = memo(
  ({ computeNodeId, readOnly = false }): JSX.Element => {
    return (
      <Box mt={0.5}>
        <Box sx={{ display: "flex", flexDirection: "column", mb: 4 }}>
          <Typography level="body-sm" mb={2}>
            Connection parameters
          </Typography>
          <ConnectionParamsForm
            computeNodeId={computeNodeId}
            readOnly={readOnly}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography level="body-sm" mb={2}>
            Object upload
          </Typography>
          <ObjectUploadForm computeNodeId={computeNodeId} readOnly={readOnly} />
        </Box>
        <Typography color="textSecondary" level="body-sm" maxWidth={600} mt={3}>
          This S3 integration functionality reads the specified source
          computation and uploads the contents to the specified S3 endpoint
        </Typography>
      </Box>
    );
  }
);

S3ComputeNodeEditor.displayName = "S3ComputeNodeEditor";

export default S3ComputeNodeEditor;
