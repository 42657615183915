import { testIds } from "@decentriq/utils";
import { faChartSimple } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/joy";
import { useDataLabContext } from "features/dataLabs";

const DataLabValidationButton: React.FC = () => {
  const {
    computeStatistics,
    statisticsLoading,
    dataLab: { data },
  } = useDataLabContext();
  const {
    usersDataset,
    embeddingsDataset,
    requireEmbeddingsDataset,
    demographicsDataset,
    requireDemographicsDataset,
    requireSegmentsDataset,
    segmentsDataset,
  } = data!;
  const isDataProvisioned: boolean =
    Boolean(usersDataset) &&
    (Boolean(embeddingsDataset) || !requireEmbeddingsDataset) &&
    (Boolean(demographicsDataset) || !requireDemographicsDataset) &&
    (Boolean(segmentsDataset) || !requireSegmentsDataset);
  const isValidationButtonEnabled: boolean =
    isDataProvisioned && !statisticsLoading;
  return (
    <Button
      data-testid={testIds.dataLabs.dataLabValidation.dataLabValidationButton}
      disabled={!isValidationButtonEnabled}
      loading={statisticsLoading}
      loadingPosition="start"
      onClick={computeStatistics}
      startDecorator={
        <FontAwesomeIcon fixedWidth={true} icon={faChartSimple} />
      }
      variant={isDataProvisioned ? "solid" : "plain"}
    >
      {isDataProvisioned ? "Validate datalab" : "Upload data to validate"}
    </Button>
  );
};

export default DataLabValidationButton;
