import { testIds } from "@decentriq/utils";
import { faPencil, faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  ModalDialog,
} from "@mui/joy";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDataPartnerPortal } from "features/dataPartnerPortal/contexts";
import {
  CommonSnackbarOrigin,
  mapErrorToGeneralSnackbar,
  useGeneralSnackbar,
} from "hooks";
import DataPartnerDisplayConfigurationForm from "../DataPartnerDisplayConfigurationForm/DataPartnerDisplayConfigurationForm";
import {
  defaultValues,
  schema,
  type SchemaType,
} from "../DataPartnerDisplayConfigurationForm/model";

const FORM_ID = "upsert-data-partner-display-configuration-form";

interface UpsertDataPartnerDisplayConfigurationDialogProps {
  updating?: boolean;
  open: boolean;
  onClose: () => void;
}

const UpsertDataPartnerDisplayConfigurationDialog: React.FC<
  UpsertDataPartnerDisplayConfigurationDialogProps
> = ({ open, onClose, updating }) => {
  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: CommonSnackbarOrigin.PUBLISHER_PORTAL,
  });
  const [disabled, setDisabled] = useState(false);
  const form = useForm<SchemaType>({
    defaultValues,
    disabled,
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: zodResolver(schema),
  });
  const { handleSubmit, reset, formState } = form;
  const { isSubmitting, isDirty } = formState;
  const {
    displayConfiguration,
    createDisplayConfiguration,
    updateDisplayConfiguration,
  } = useDataPartnerPortal();
  const displayConfigurationId = useMemo(
    () => displayConfiguration?.id,
    [displayConfiguration?.id]
  );
  const handleCreate = useCallback(
    async (values: SchemaType) =>
      await createDisplayConfiguration({
        onCompleted: () => {
          enqueueSnackbar(`Display configuration has been created`);
          onClose();
        },
        onError: (error) => {
          enqueueSnackbar(
            ...mapErrorToGeneralSnackbar(
              error,
              "Cannot create display configuration"
            )
          );
        },
        variables: {
          input: {
            ...values,
            collaborationRequestUsers: values.collaborationRequestUsers || [],
          },
        },
      }),
    [createDisplayConfiguration, enqueueSnackbar, onClose]
  );
  const handleUpdate = useCallback(
    (id: string, values: SchemaType) =>
      updateDisplayConfiguration({
        onCompleted: () => {
          onClose();
        },
        onError: (error) => {
          enqueueSnackbar(
            ...mapErrorToGeneralSnackbar(
              error,
              "Cannot update display configuration"
            )
          );
        },
        variables: {
          input: {
            collaborationRequestUsers: values.collaborationRequestUsers,
            description: {
              value: values.description,
            },
            id,
            logo: {
              value: values.logo,
            },
            marketIds: values.marketIds,
            name: {
              value: values.name,
            },
          },
        },
      }),
    [updateDisplayConfiguration, enqueueSnackbar, onClose]
  );
  const handleUpsert = useCallback(
    async (values: SchemaType) => {
      await (updating
        ? handleUpdate(displayConfigurationId!, values)
        : handleCreate(values));
    },
    [updating, displayConfigurationId, handleCreate, handleUpdate]
  );
  useEffect(() => {
    if (displayConfiguration && open) {
      const { name, description, collaborationRequestUsers, logo, marketIds } =
        displayConfiguration;
      reset({
        collaborationRequestUsers:
          collaborationRequestUsers || defaultValues.collaborationRequestUsers,
        description: description || defaultValues.description,
        logo: logo || defaultValues.logo,
        marketIds: marketIds || defaultValues.marketIds,
        name: name || defaultValues.name,
      });
    }
  }, [displayConfiguration, reset, open]);
  useEffect(() => {
    setDisabled(isSubmitting);
  }, [isSubmitting]);
  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open, reset]);
  return (
    <FormProvider {...form}>
      <form
        id={FORM_ID}
        onReset={() => reset()}
        onSubmit={handleSubmit(handleUpsert)}
      >
        <Modal
          disableEscapeKeyDown={isDirty}
          onClose={() => {
            if (!isSubmitting) onClose();
          }}
          open={open}
        >
          <ModalDialog>
            <DialogTitle>
              {updating ? "Edit" : "Create"} display configuration
            </DialogTitle>
            <Divider />
            <DialogContent>
              <DataPartnerDisplayConfigurationForm />
            </DialogContent>
            <Divider />
            <DialogActions>
              <Button
                disabled={disabled}
                form={FORM_ID}
                onClick={() => onClose()}
                type="reset"
              >
                Cancel
              </Button>
              <Button
                color="primary"
                data-testid={
                  testIds.dataPartnerPortal.upsertDisplayConfigurationDialog
                    .submitButton
                }
                disabled={disabled}
                form={FORM_ID}
                loading={isSubmitting}
                loadingPosition="start"
                startDecorator={
                  <FontAwesomeIcon icon={updating ? faPencil : faPlus} />
                }
                type="submit"
                variant="solid"
              >
                {updating ? "Update" : "Create"}
              </Button>
            </DialogActions>
          </ModalDialog>
        </Modal>
      </form>
    </FormProvider>
  );
};
UpsertDataPartnerDisplayConfigurationDialog.displayName =
  "UpsertDataPartnerDisplayConfigurationDialog";

export default memo(UpsertDataPartnerDisplayConfigurationDialog);
