import { testIds } from "@decentriq/utils";
import { Stack } from "@mui/joy";
import { memo } from "react";
import { useDataPartnerPortal } from "features/dataPartnerPortal/contexts";
import {
  ConfigurationFormDomainField,
  ConfigurationFormFeaturesField,
  ConfigurationFormMatchingIdField,
  ConfigurationFormNameField,
  ConfigurationFormParticipantsField,
} from "features/mediaPortalShared";
import { dataPartnerDcrCollaborationTypes, type SchemaType } from "./model";

const DataPartnerUsageConfigurationForm: React.FC = () => {
  const { organizationUsers } = useDataPartnerPortal();
  return (
    <Stack>
      <ConfigurationFormNameField<SchemaType>
        fieldName="name"
        placeholder={`e.g. "My configuration name"`}
        testId={testIds.dataPartnerPortal.createUsageConfigurationDialog.name}
        title="Name"
        tooltip="User defined name for the configuration. Not publicly visible, only within your organization."
      />
      <ConfigurationFormParticipantsField<SchemaType>
        fieldName="participants"
        optionTestId={
          testIds.dataPartnerPortal.createUsageConfigurationDialog
            .participantsSelectorOptionHelper
        }
        organizationUsers={organizationUsers}
        testId={
          testIds.dataPartnerPortal.createUsageConfigurationDialog
            .participantsSelector
        }
        title="Participants"
        tooltip="These are the users from your organization that will be invited to newly created clean rooms using this configuration. These users will have permission to upload your data into the DCR."
      />
      <ConfigurationFormFeaturesField<SchemaType>
        featuresList={dataPartnerDcrCollaborationTypes}
        fieldName="dataRoomTypes"
        optionTestId={
          testIds.dataPartnerPortal.createUsageConfigurationDialog
            .featuresSelectorOptionHelper
        }
        testId={
          testIds.dataPartnerPortal.createUsageConfigurationDialog
            .featuresSelector
        }
        tooltip="These are the DCR collaboration types that are available under this configuration. Other collaboration types will be greyed out in the data partner discovery menu and unavailable in the clean room creation dialog."
      />
      <ConfigurationFormMatchingIdField<SchemaType>
        fieldName="matchingId"
        optionTestId={
          testIds.dataPartnerPortal.createUsageConfigurationDialog
            .matchingIdSelectorOptionHelper
        }
        testId={
          testIds.dataPartnerPortal.createUsageConfigurationDialog
            .matchingIdSelector
        }
        tooltip="The type of matching ID. The data you will upload into DCRs created by advertisers under this configuration must include this type of identifier."
      />
      <ConfigurationFormDomainField<SchemaType>
        checkboxFieldName="matchAnyDomain"
        checkboxTestId={
          testIds.dataPartnerPortal.createUsageConfigurationDialog
            .matchAnyDomain
        }
        textFieldName="advertiserDomains"
        textFieldTestId={
          testIds.dataPartnerPortal.createUsageConfigurationDialog
            .advertiserDomain
        }
      />
    </Stack>
  );
};
DataPartnerUsageConfigurationForm.displayName =
  "DataPartnerUsageConfigurationForm";

export default memo(DataPartnerUsageConfigurationForm);
