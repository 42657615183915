import { memo } from "react";
import {
  MediaInsightsDcrCreationStep,
  useCreationWizardStepper,
} from "features/MediaInsightsDcr/contexts";
import {
  MediaInsightsDcrCreateCollaborationConfigurationStep,
  MediaInsightsDcrCreateCollaborationRequestStep,
  MediaInsightsDcrCreateSelectDataPartnerStep,
  MediaInsightsDcrCreateSelectDataSourceRoleStep,
  MediaInsightsDcrCreateSelectOrganizationRoleStep,
  MediaInsightsDcrCreateSelectPublisherStep,
} from "./components";

const MediaInsightsDcrCreateWizard: React.FC = () => {
  const { activeStep } = useCreationWizardStepper();
  return (
    <>
      {activeStep === MediaInsightsDcrCreationStep.SELECT_ORGANIZATION_ROLE && (
        <MediaInsightsDcrCreateSelectOrganizationRoleStep />
      )}
      {activeStep === MediaInsightsDcrCreationStep.SELECT_PUBLISHER && (
        <MediaInsightsDcrCreateSelectPublisherStep />
      )}
      {activeStep === MediaInsightsDcrCreationStep.SELECT_DATA_SOURCE && (
        <MediaInsightsDcrCreateSelectDataSourceRoleStep />
      )}
      {activeStep === MediaInsightsDcrCreationStep.SELECT_DATA_PARTNER && (
        <MediaInsightsDcrCreateSelectDataPartnerStep />
      )}
      {activeStep ===
        MediaInsightsDcrCreationStep.COLLABORATION_CONFIGURATION && (
        <MediaInsightsDcrCreateCollaborationConfigurationStep />
      )}
      {activeStep ===
        MediaInsightsDcrCreationStep.COLLABORATION_REQUEST_TO_DATA_PARTNER && (
        <MediaInsightsDcrCreateCollaborationRequestStep />
      )}
      {activeStep ===
        MediaInsightsDcrCreationStep.COLLABORATION_REQUEST_TO_PUBLISHER && (
        <MediaInsightsDcrCreateCollaborationRequestStep />
      )}
    </>
  );
};
MediaInsightsDcrCreateWizard.displayName = "MediaInsightsDcrCreateWizard";

export default memo(MediaInsightsDcrCreateWizard);
