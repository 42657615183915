import {
  faClock as faClockLight,
  faHashtag as faHashtagLight,
  faTable as faTableLight,
  faWarning as faWarningLight,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, List, ListItem, Tooltip, Typography } from "@mui/joy";
import { styled } from "@mui/material";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { memo } from "react";
import { DetailsGridValue } from "components";
import { useDataLabContext } from "features/dataLabs/contexts";
import {
  type DataLabDataset,
  matchingIdTypeAndHashingAlgorithmPresentation,
} from "features/dataLabs/models";
import { useCopyToClipboard } from "hooks";

const DataLabDetailsItemContainer = styled(Box)(
  ({ theme: { spacing, palette, shape } }) => ({
    border: `1px solid ${palette.grey[300]}`,
    borderRadius: shape.borderRadius,
    marginBottom: spacing(1),
    padding: spacing(1.5),
  })
);

interface DataLabDetailsProps {
  id: string;
}

const DataLabDetails: React.FC<DataLabDetailsProps> = () => {
  const {
    dataLab: { data, loading },
  } = useDataLabContext();
  const [, copyToClipboard] = useCopyToClipboard();
  const { enqueueSnackbar } = useSnackbar();

  if (!data || loading) return <></>;

  const dataLab = data!;
  const {
    id,
    updatedAt,
    createdAt,
    matchingIdFormat,
    requireDemographicsDataset,
    requireEmbeddingsDataset,
    matchingIdHashingAlgorithm,
    requireSegmentsDataset,
    numEmbeddings,
    segmentsDataset,
    demographicsDataset,
    embeddingsDataset,
    usersDataset,
  } = dataLab;
  const datasets: DataLabDataset[] = [
    segmentsDataset,
    usersDataset,
    demographicsDataset,
    embeddingsDataset,
  ].filter(Boolean) as DataLabDataset[];
  const dateFormat = "dd.MM.yyy, HH:mm";
  return (
    <Box>
      {!!createdAt && (
        <DataLabDetailsItemContainer>
          <Typography gutterBottom={true} level="body-sm" textColor="inherit">
            <strong>Created at</strong>
          </Typography>
          <DetailsGridValue
            icon={faClockLight}
            value={format(new Date(createdAt), dateFormat)}
          />
        </DataLabDetailsItemContainer>
      )}
      {!!updatedAt && (
        <DataLabDetailsItemContainer>
          <Typography gutterBottom={true} level="body-sm" textColor="inherit">
            <strong>Last modified</strong>
          </Typography>
          <DetailsGridValue
            icon={faClockLight}
            value={format(new Date(updatedAt), dateFormat)}
          />
        </DataLabDetailsItemContainer>
      )}
      <DataLabDetailsItemContainer>
        <Typography gutterBottom={true} level="body-sm" textColor="inherit">
          <strong>Datalab ID</strong>
        </Typography>
        <Tooltip
          onClick={(e) => {
            e.stopPropagation();
            copyToClipboard(id || "");
            enqueueSnackbar(`Datalab ID copied to clipboard`);
          }}
          placement="top"
          style={{ cursor: "pointer" }}
          title="Click to copy Datalab ID"
        >
          <span>
            <DetailsGridValue icon={faHashtagLight} value={id} />
          </span>
        </Tooltip>
      </DataLabDetailsItemContainer>
      <DataLabDetailsItemContainer>
        <Typography level="body-sm" textColor="inherit">
          <strong>Data Configuration</strong>
        </Typography>
        <List>
          <ListItem>
            <Typography level="body-sm" textColor="inherit">
              <strong>Matching ID:</strong>{" "}
              {matchingIdTypeAndHashingAlgorithmPresentation({
                matchingIdFormat,
                matchingIdHashingAlgorithm,
              })}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography level="body-sm" textColor="inherit">
              {/* Having Matching dataset is always required while creating a Datalab */}
              <strong>Matching data:</strong> Yes
            </Typography>
          </ListItem>
          <ListItem>
            <Typography level="body-sm" textColor="inherit">
              <strong>Segments data:</strong>{" "}
              {requireSegmentsDataset ? "Yes" : "No"}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography level="body-sm" textColor="inherit">
              <strong>Demographics data:</strong>{" "}
              {requireDemographicsDataset ? "Yes" : "No"}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography level="body-sm" textColor="inherit">
              <strong>Embeddings data:</strong>{" "}
              {requireEmbeddingsDataset ? "Yes" : "No"}
            </Typography>
          </ListItem>
          {requireEmbeddingsDataset && (
            <ListItem>
              <Typography level="body-sm" textColor="inherit">
                <strong>Number of embeddings:</strong> {numEmbeddings}
              </Typography>
            </ListItem>
          )}
        </List>
      </DataLabDetailsItemContainer>
      <DataLabDetailsItemContainer>
        <Typography gutterBottom={true} level="body-sm" textColor="inherit">
          <strong>Datasets</strong>
        </Typography>
        {datasets.length ? (
          <List>
            {datasets.map(({ id, name }) => (
              <ListItem key={id} sx={{ gap: 1 }}>
                <FontAwesomeIcon fixedWidth={true} icon={faTableLight} />
                <Typography level="body-sm" textColor="inherit">
                  {name}
                </Typography>
              </ListItem>
            ))}
          </List>
        ) : (
          <Box sx={{ alignItems: "center", display: "flex", gap: 1 }}>
            <FontAwesomeIcon fixedWidth={true} icon={faWarningLight} />
            <Typography fontStyle="italic" level="body-sm">
              No datasets provisioned
            </Typography>
          </Box>
        )}
      </DataLabDetailsItemContainer>
    </Box>
  );
};

DataLabDetails.displayName = "DataLabDetails";

export default memo(DataLabDetails);
