import {
  DqDatasetExportFormProvider,
  type DqDatasetExportFormProviderSchemaType,
  DqDatasetSyncDrawer,
} from "@decentriq/components";
import { useCreateDatasetExportMutation } from "@decentriq/graphql/dist/hooks";
import {
  type CreateDatasetExportMutation,
  type DataTargetType,
} from "@decentriq/graphql/dist/types";
import { type DrawerProps } from "@mui/joy";
import { memo, useCallback, useEffect, useState } from "react";
import { Wizard } from "react-use-wizard";
import { mapDraftDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import { CreateExportStep, SelectSyncStep, ShowStatusStep } from "./components";
import {
  DatasetExportProvider,
  type DatasetExportProviderProps,
  useDatasetExport,
} from "./contexts";

const DatasetExportDialog: React.FC<DrawerProps> = memo(({ open, onClose }) => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const {
    datasetManifestHash,
    setDatasetExportId,
    setIsExportCreated,
    setDatasetExportError,
  } = useDatasetExport();
  const [syncType, setSyncType] = useState<DataTargetType | null>(null);
  const [createDatasetExportMutation] = useCreateDatasetExportMutation();
  const onSubmit = useCallback(
    async (
      createDatasetExportMutationVariables: DqDatasetExportFormProviderSchemaType
    ) => {
      return await createDatasetExportMutation({
        onCompleted: ({ createDatasetExport }: CreateDatasetExportMutation) => {
          setDatasetExportId(createDatasetExport?.dataConnectorJob?.id);
          setIsExportCreated(true);
        },
        onError: (error) => {
          setDatasetExportError(error?.message);
          enqueueSnackbar(
            ...mapDraftDataRoomErrorToSnackbar(
              error,
              "The exporting process could not get started. Please try again by refreshing the page."
            )
          );
        },
        variables: {
          input: {
            ...createDatasetExportMutationVariables,
            manifestHash: datasetManifestHash,
          },
        },
      });
    },
    [
      createDatasetExportMutation,
      datasetManifestHash,
      enqueueSnackbar,
      setDatasetExportError,
      setDatasetExportId,
      setIsExportCreated,
    ]
  );
  useEffect(() => {
    if (!open) {
      setSyncType(null);
    }
  }, [open]);
  return (
    <DqDatasetExportFormProvider
      datasetManifestHash={datasetManifestHash}
      syncType={syncType || undefined}
    >
      <DqDatasetSyncDrawer open={open}>
        <Wizard key={open.toString()}>
          <SelectSyncStep onClose={onClose} onSelect={[setSyncType]} />
          <CreateExportStep
            onClose={onClose}
            onSubmit={onSubmit}
            syncType={syncType!}
          />
          <ShowStatusStep onClose={onClose} syncType={syncType!} />
        </Wizard>
      </DqDatasetSyncDrawer>
    </DqDatasetExportFormProvider>
  );
});
DatasetExportDialog.displayName = "DatasetExportDialog";

const WrappedDatasetExportDialog: React.FC<
  Pick<DatasetExportProviderProps, "datasetManifestHash"> & DrawerProps
> = memo(({ datasetManifestHash, ...props }) => {
  return (
    <DatasetExportProvider datasetManifestHash={datasetManifestHash}>
      <DatasetExportDialog {...props} />
    </DatasetExportProvider>
  );
});
WrappedDatasetExportDialog.displayName = "WrappedDatasetExportDialog";

export default WrappedDatasetExportDialog;
