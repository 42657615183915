import { Typography } from "@mui/joy";
import { memo } from "react";
import useAdFormConnectionConfigurationData from "./useAdFormConnectionConfigurationData";

type AdFormConnectionConfigurationProps = {
  connectionId: string;
};

const AdFormConnectionConfiguration: React.FC<
  AdFormConnectionConfigurationProps
> = ({ connectionId }) => {
  const { bucket, region, objectKey } =
    useAdFormConnectionConfigurationData(connectionId);
  return (
    <div>
      <Typography level="body-sm">
        <strong>Bucket:</strong> {bucket || "-"}
      </Typography>
      <Typography level="body-sm">
        <strong>Region:</strong> {region || "-"}
      </Typography>
      <Typography level="body-sm">
        <strong>Object:</strong> {objectKey || "-"}
      </Typography>
    </div>
  );
};

AdFormConnectionConfiguration.displayName = "AdFormConnectionConfiguration";

export default memo(AdFormConnectionConfiguration);
