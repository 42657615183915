import { DqParticipantsEditor, InfoTooltip } from "@decentriq/components";
import { testIds } from "@decentriq/utils";
import { type IconDefinition } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  FormControl,
  Grid,
  Option,
  Select,
  styled,
  Typography,
} from "@mui/joy";
import uniqBy from "lodash/uniqBy";
import {
  memo,
  type ReactNode,
  type SetStateAction,
  useEffect,
  useMemo,
} from "react";
import { Controller, useFormContext } from "react-hook-form";

const CollaborationConfigurationStepRoleContainer = styled(FormControl)(
  ({ theme: { radius, spacing } }) => ({
    backgroundColor: "transparent",
    borderColor: "rgba(0, 0, 0, 0.12)",
    borderRadius: radius["sm"],
    borderStyle: "solid",
    borderWidth: "1px",
    fontWeight: 500,
    letterSpacing: -0.5,
    marginLeft: 0,
    marginRight: 0,
    padding: spacing(0.5),
  })
);

type RoleEmailFieldName =
  | "advertiserEmails"
  | "publisherEmails"
  | "dataPartnerEmails"
  | "agencyEmails"
  | "observerEmails";

interface CollaborationConfigurationStepRoleProps {
  icon: IconDefinition;
  roleTagLabel: ReactNode;
  emailValues: string[];
  emailPlaceholder?: string;
  fieldName: RoleEmailFieldName;
  mainParticipantValue?: string;
  onMainParticipantChange?: (email: SetStateAction<string>) => void;
}

const CollaborationConfigurationStepRole: React.FC<CollaborationConfigurationStepRoleProps> =
  memo(
    ({
      icon,
      roleTagLabel,
      emailValues = [],
      emailPlaceholder = "Type in user email and press 'Enter'",
      fieldName,
      onMainParticipantChange = () => {},
      mainParticipantValue,
    }) => {
      const { control } =
        useFormContext<{ [k in RoleEmailFieldName]?: string[] }>();
      // Main Participant selector
      const domainsList: { label: string; value: string }[] = useMemo(() => {
        const participants = emailValues.map((email) => {
          const [, emailDomain] = email.split("@");
          return {
            label: emailDomain,
            value: email,
          };
        });
        return uniqBy(participants, "label");
      }, [emailValues]);
      // Main participant email is required only if more than 1 email and they are in different domains (uniqBy in map)
      const isMainParticipantSelectVisible = domainsList.length > 1;
      useEffect(() => {
        const isParticipantExist = domainsList.find(
          ({ value }) => value === mainParticipantValue
        );
        if (!isMainParticipantSelectVisible || !isParticipantExist) {
          onMainParticipantChange(domainsList[0]?.value || "");
        }
      }, [
        domainsList,
        isMainParticipantSelectVisible,
        mainParticipantValue,
        onMainParticipantChange,
      ]);
      const textFieldTestids: Record<RoleEmailFieldName, string> = {
        advertiserEmails:
          testIds.mediaInsightsDcr.collaborationConfigurationStepRole
            .advertiserEmail,
        agencyEmails:
          testIds.mediaInsightsDcr.collaborationConfigurationStepRole
            .agencyEmail,
        dataPartnerEmails:
          testIds.mediaInsightsDcr.collaborationConfigurationStepRole
            .dataPartnerEmail,
        observerEmails:
          testIds.mediaInsightsDcr.collaborationConfigurationStepRole
            .observerEmail,
        publisherEmails:
          testIds.mediaInsightsDcr.collaborationConfigurationStepRole
            .publisherEmail,
      };
      return (
        <CollaborationConfigurationStepRoleContainer
          sx={{ borderRadius: null, width: "100%" }}
        >
          <Grid columnSpacing={1} container={true}>
            <Grid container={true} xs={3}>
              <Box
                sx={(theme) => ({
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  justifyContent: "center",
                  padding: theme.spacing(1),
                  width: "100%",
                })}
              >
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <FontAwesomeIcon fontSize="1.5rem" icon={icon} />
                  <Typography fontWeight={500} level="body-sm" sx={{ ml: 1 }}>
                    {roleTagLabel}
                  </Typography>
                </Box>
                {isMainParticipantSelectVisible && (
                  <Box
                    sx={({ palette }) => ({
                      alignItems: "center",
                      color: palette.text.primary,
                      display: "flex",
                      mt: 1,
                      width: "100%",
                    })}
                  >
                    <FormControl>
                      <Select
                        onChange={(event, value) => {
                          onMainParticipantChange(value);
                        }}
                        renderValue={({ value }) => {
                          const participantEmail = domainsList.find(
                            (domain) => domain.value === value
                          )?.label;
                          return participantEmail || "Select organization";
                        }}
                        sx={{ maxWidth: "80%" }}
                        value={mainParticipantValue || ""}
                      >
                        {domainsList.map(({ value, label }) => (
                          <Option key={value} value={value}>
                            {label}
                          </Option>
                        ))}
                      </Select>
                      <InfoTooltip
                        tooltip="Please select the organization the represents the publisher/advertiser in this DCR"
                        top="1px"
                      />
                    </FormControl>
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid alignItems="center" display="flex" xs={9}>
              <Controller
                control={control}
                name={fieldName}
                render={({ field, fieldState }) => {
                  const error = fieldState.error;
                  const hasError = Boolean(error);
                  return (
                    <FormControl error={hasError}>
                      <DqParticipantsEditor
                        data-testId={textFieldTestids[fieldName]}
                        error={error}
                        onChange={(event, newValue) => field.onChange(newValue)}
                        options={[]}
                        placeholder={emailPlaceholder}
                        value={field.value}
                      />
                    </FormControl>
                  );
                }}
              />
            </Grid>
          </Grid>
        </CollaborationConfigurationStepRoleContainer>
      );
    }
  );

CollaborationConfigurationStepRole.displayName =
  "CollaborationConfigurationStepRole";

export default CollaborationConfigurationStepRole;
