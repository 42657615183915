import { DqDatasetSyncDrawer } from "@decentriq/components";
import {
  DqDatasetImportFormProvider,
  type DqDatasetImportFormProviderSchemaType,
  type LocalDataSourceType,
} from "@decentriq/components";
import { useCreateDatasetImportMutation } from "@decentriq/graphql/dist/hooks";
import {
  type CreateDatasetImportMutation,
  DataSourceType,
} from "@decentriq/graphql/dist/types";
import { type DrawerProps } from "@mui/joy";
import { Fragment, memo, useCallback, useEffect, useState } from "react";
import { Wizard } from "react-use-wizard";
import { mapDraftDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import {
  CreateImportStep,
  DatasetImportLocalDialog,
  SelectSyncStep,
  ShowStatusStep,
} from "./components";
import { DatasetImportProvider, useDatasetImport } from "./contexts";

const DatasetImportDialog: React.FC<DrawerProps> = memo(({ open, onClose }) => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { setDatasetImportId, setIsImportCreated, setDatasetImportError } =
    useDatasetImport();
  const [sync, setSync] = useState<
    [DataSourceType | null, LocalDataSourceType | null]
  >([null, null]);
  const [syncType, syncSubtype] = sync || [];
  const [createDatasetImportMutation] = useCreateDatasetImportMutation();
  const onSubmit = useCallback(
    async (
      createDatasetImportMutationVariables: DqDatasetImportFormProviderSchemaType
    ) => {
      return await createDatasetImportMutation({
        onCompleted: ({ createDatasetImport }: CreateDatasetImportMutation) => {
          setDatasetImportId(createDatasetImport?.dataConnectorJob.id);
          setIsImportCreated(true);
        },
        onError: (error) => {
          setDatasetImportError(error?.message);
          enqueueSnackbar(
            ...mapDraftDataRoomErrorToSnackbar(
              error,
              "The importing process could not get started. Please try again by refreshing the page."
            )
          );
        },
        variables: { input: createDatasetImportMutationVariables },
      });
    },
    [
      createDatasetImportMutation,
      setDatasetImportId,
      setIsImportCreated,
      setDatasetImportError,
      enqueueSnackbar,
    ]
  );
  const isLocalDataImport = syncType === DataSourceType.Local;
  const isExternalDataImport = syncType && !isLocalDataImport;
  const isDrawerOpen = Boolean(
    (open && syncType === null) || (open && isExternalDataImport)
  );
  const isModalOpen = Boolean(open && isLocalDataImport);
  useEffect(() => {
    if (!open) {
      setSync([null, null]);
    }
  }, [open]);
  return (
    <Fragment>
      <DqDatasetImportFormProvider
        syncType={isExternalDataImport ? syncType : undefined}
      >
        <DqDatasetSyncDrawer onClose={onClose} open={isDrawerOpen}>
          <Wizard key={open.toString()}>
            <SelectSyncStep onClose={onClose} onSelect={setSync} />
            <Fragment>
              {isExternalDataImport ? (
                <CreateImportStep
                  onClose={onClose}
                  onSubmit={onSubmit}
                  syncType={syncType}
                />
              ) : null}
            </Fragment>
            <Fragment>
              {isExternalDataImport ? (
                <ShowStatusStep onClose={onClose} syncType={syncType} />
              ) : null}
            </Fragment>
          </Wizard>
        </DqDatasetSyncDrawer>
      </DqDatasetImportFormProvider>
      <DatasetImportLocalDialog
        dataType={isLocalDataImport ? syncSubtype : null}
        onClose={onClose}
        open={isModalOpen}
      />
    </Fragment>
  );
});
DatasetImportDialog.displayName = "ImportExternalDataDialog";

const WrappedDatasetImportDialog: React.FC<DrawerProps> = memo((props) => {
  return (
    <DatasetImportProvider>
      <DatasetImportDialog {...props} />
    </DatasetImportProvider>
  );
});
WrappedDatasetImportDialog.displayName = "WrappedDatasetImportDialog";

export default WrappedDatasetImportDialog;
