// TODO: Fix joy migration
import {
  type IconProp,
  type SizeProp,
} from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@mui/joy";
import {
  type LinkProps,
  ListItem,
  ListItemButton,
  type ListItemProps,
  ListItemText,
  type ListItemTextProps,
  styled,
  type TooltipProps,
} from "@mui/material";
import { isEmpty } from "lodash";
import React, { memo } from "react";
import { isElement, isValidElementType } from "react-is";
import { NavLink, type NavLinkProps, useMatch } from "react-router-dom";

// Define a mapped type to filter allowed props based on component
type WrapperComponentPropsMap = {
  NavLink: NavLinkProps;
  ListItem: ListItemProps;
  Link: LinkProps;
};

type ListItemButtonOrientation = "vertical" | "horizontal";

type SidebarMenuItemProps<T extends keyof WrapperComponentPropsMap> = {
  label?: string;
  icon?: React.ReactNode | IconProp;
  iconSize?: SizeProp;
  // TODO: figure correct typings to have it one of WrapperComponentPropsMap
  wrapperComponent?: any;
  children?: React.ReactNode;
  labelProps?: ListItemTextProps;
  onClick?: (event: React.MouseEvent) => void;
  tooltipTitle?: string;
  tooltipProps?: Omit<TooltipProps, "title">;
  disabled?: boolean;
  orientation?: ListItemButtonOrientation;
  selected?: boolean;
  to?: string;
  dataTestId?: string;
} & WrapperComponentPropsMap[T];

const SidebarListItemButton = styled(ListItemButton)<{
  orientation?: ListItemButtonOrientation;
}>(({ theme: { spacing, palette }, selected, orientation }) => ({
  color: "initial",
  flexDirection: orientation === "horizontal" ? "row" : "column",
  justifyContent: "center",
  padding: orientation === "horizontal" ? spacing(1, 0.5) : spacing(1.5, 1),
}));

const SidebarMenuItemIcon: React.FC<{
  iconSize: SizeProp;
  icon: React.ReactNode | IconProp;
}> = ({ icon: Icon, iconSize }) => {
  return isElement(Icon) || typeof Icon === "string" ? (
    Icon
  ) : isValidElementType(Icon as React.ElementType) ? (
    <Icon />
  ) : (
    <FontAwesomeIcon
      fixedWidth={true}
      icon={Icon as IconProp}
      size={iconSize}
    />
  );
};

const SidebarMenuItem: React.FC<
  SidebarMenuItemProps<keyof WrapperComponentPropsMap>
> = ({
  icon,
  iconSize = "lg",
  label,
  labelProps = {},
  onClick = () => {},
  tooltipTitle,
  tooltipProps = {},
  disabled = false,
  children,
  wrapperComponent: WrapperComponent = NavLink,
  orientation = "vertical",
  dataTestId,
  ...restProps
}) => {
  const currentPageMatch = useMatch({
    path: `${restProps?.to || ""}/*`,
  });
  const selected = restProps?.to ? !isEmpty(currentPageMatch) : false;
  return (
    <Tooltip placement="top" title={tooltipTitle} {...tooltipProps}>
      <ListItem
        component={WrapperComponent as React.ElementType}
        disablePadding={true}
        sx={{ padding: 0 }}
        {...restProps}
      >
        <SidebarListItemButton
          data-testid={dataTestId}
          disabled={disabled}
          onClick={onClick}
          orientation={orientation}
          selected={selected}
        >
          {icon && <SidebarMenuItemIcon icon={icon} iconSize={iconSize!} />}
          {label && (
            <ListItemText
              primary={label}
              primaryTypographyProps={{
                ...(orientation === "vertical"
                  ? {
                      align: "center",
                    }
                  : {}),
                color: "inherit",
                component: "span",
                fontWeight: "inherit",
                textAlign: orientation === "horizontal" ? "left" : "center",
                variant: "body2",
              }}
              sx={{
                marginBottom: 0,
                ...(labelProps?.sx || {}),
                ...(orientation === "horizontal"
                  ? { marginLeft: 1, marginTop: 0 }
                  : {}),
              }}
              {...labelProps}
            />
          )}
          {children}
        </SidebarListItemButton>
      </ListItem>
    </Tooltip>
  );
};

SidebarMenuItem.displayName = "SidebarMenuItem";

export default memo(SidebarMenuItem);
