import { faCheckCircle, faWarning } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Chip } from "@mui/joy";
import { memo } from "react";

interface DataLabStatusLabelProps {
  isValidated: boolean;
}

const DataLabStatusLabel: React.FC<DataLabStatusLabelProps> = ({
  isValidated,
}) => {
  return (
    <Box sx={{ alignItems: "center", display: "flex", mt: "2px" }}>
      <Chip
        color={isValidated ? "success" : "warning"}
        startDecorator={
          <FontAwesomeIcon icon={isValidated ? faCheckCircle : faWarning} />
        }
      >
        {isValidated ? "Validated" : "Not validated"}
      </Chip>
    </Box>
  );
};

DataLabStatusLabel.displayName = "DataLabStatusLabel";

export default memo(DataLabStatusLabel);
