// TODO: Fix joy migration
import { Box, Typography } from "@mui/joy";
import { Card, CardContent } from "@mui/material";
import { format } from "date-fns";
import { useState } from "react";
import { makeStyles } from "tss-react/mui";
import { useData } from "contexts";
import { type DatasetWithKeychainItem } from "features/dataNodes/hooks/useDatasetsWithStoredKey";
import { DatasetPopup, formatRows, formatSize } from "features/datasets";

export interface DatasetCardProps {
  node: DatasetWithKeychainItem;
  onClick?: (node: DatasetWithKeychainItem) => void;
}

const useMultiLineEllipsisStyle = makeStyles()({
  multiLineEllipsis: {
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 3,
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});

const DatasetCard = (props: DatasetCardProps) => {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const { loading } = useData();
  const { node: dataset } = props;
  const { classes } = useMultiLineEllipsisStyle();
  if (loading) {
    return null;
  }
  const rows =
    dataset?.statistics?.numberOfRows &&
    `${formatRows(dataset?.statistics?.numberOfRows)} rows`;
  const size = dataset?.size && formatSize(dataset?.size);
  if (loading) {
    return null;
  }
  return (
    <>
      <Card
        onClick={() =>
          props.onClick ? props.onClick(dataset) : setPopupOpen(true)
        }
        sx={{
          border: "1px solid #bbb",
          cursor: "pointer",
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            height: "64px",
            justifyContent: "space-between",
            padding: 2,
          }}
        >
          <Typography
            color="text.secondary"
            level="body-sm"
            sx={{
              display: "block",
              overflow: "hidden",
              paddingRight: 2,
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {(dataset.publications?.nodes || [])
              .map(({ dataRoom }) => dataRoom?.title)
              .join(", ")}
          </Typography>

          <Typography
            color="text.secondary"
            level="body-sm"
            sx={{ flexShrink: 0 }}
          >
            {format(new Date(dataset.createdAt), "MMM d")}
          </Typography>
        </Box>

        <CardContent sx={{ marginTop: "auto" }}>
          <Typography
            className={classes.multiLineEllipsis}
            sx={{ lineHeight: "110%", marginY: 0.5 }}
          >
            {dataset?.name || dataset?.id?.slice(0, 8)}
          </Typography>

          <Typography
            color="text.secondary"
            level="body-sm"
            sx={{ marginTop: 0 }}
          >
            {[rows, size].filter((x) => x).join(", ")}
          </Typography>
        </CardContent>
      </Card>
      <DatasetPopup
        datasetHash={dataset?.manifestHash}
        onDismiss={() => setPopupOpen(false)}
        open={isPopupOpen}
      />
    </>
  );
};

export default DatasetCard;
