import { SANITIZE_IDENTIFIER_INPUT } from "constants/index";
import { DatasetSchemaExtractionDialog, DqTable } from "@decentriq/components";
import { type VersionedSchema } from "@decentriq/components/dist/components/DatasetUploader/types";
import {
  ColumnDataType,
  TableColumnFormatType,
  TableColumnHashingAlgorithm,
} from "@decentriq/graphql/dist/types";
import { testIds } from "@decentriq/utils";
import {
  closestCenter,
  DndContext,
  type DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  fa1,
  fa2,
  faAmpersand,
  faFileImport,
  faLink,
  faPlus,
  faTrashCan,
  faXmark,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Checkbox,
  Chip,
  ChipDelete,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  Option,
  Select,
  Tooltip,
  Typography,
} from "@mui/joy";
import capitalize from "lodash/capitalize";
import {
  type ChangeEventHandler,
  Fragment,
  type KeyboardEventHandler,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDataRoom } from "contexts";
import { useDataNodeActions } from "features/dataNodes/containers/DataNodes/DataNodesActionsWrapper";
import {
  castFormatTypeToPrimitiveType,
  castPrimitiveTypeToFormatType,
} from "models";
import { type DataRoomTableColumn } from "models";
import { chainPromises } from "utils";
import { isValidIdentifier, sanitizeIdentifier } from "utils/validation";
import {
  dataRoomTableFormatTypeOptions,
  dataRoomTablePrimitiveTypeOptions,
} from "./DataNodeConstructorModels";
import { useDataNodeConstructorParams } from "./DataNodeConstructorParamsWrapper";

interface TableNodeColumnConstructorProps {
  tableNodeId: string;
  columns: DataRoomTableColumn[];
  columnsOrder: string[];
  isLoading: boolean;
  onChangeOutcome?: (columnAdded?: boolean, columnId?: string) => void;
}

export const TableNodeColumnConstructor: React.FC<TableNodeColumnConstructorProps> =
  memo(({ tableNodeId, columns, columnsOrder, isLoading, onChangeOutcome }) => {
    const { isPublished } = useDataRoom();
    const {
      handleTableColumnDelete,
      handleTableColumnDataTypeUpdate,
      handleTableColumnHashWithUpdate,
      handleTableColumnNameUpdate,
      handleTableColumnNullableUpdate,
    } = useDataNodeActions();
    const { readOnly } = useDataNodeConstructorParams();
    const { handleTableColumnCreate, handleTableColumnsOrderUpdate } =
      useDataNodeActions();
    const [items, setItems] = useState(columnsOrder);
    useEffect(() => setItems(columnsOrder), [columnsOrder]);
    const sensors = useSensors(
      useSensor(PointerSensor),
      useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
      })
    );
    const onDragEnd = (event: DragEndEvent) => {
      const { active, over } = event;
      if (active.id !== over?.id) {
        setItems((items) => {
          const oldIndex = items.indexOf(active.id.toString());
          const newIndex = items.indexOf(over!.id.toString());
          const reorderedItems = arrayMove(items, oldIndex, newIndex);
          handleTableColumnsOrderUpdate({
            columnsOrder: reorderedItems,
            id: tableNodeId,
          }).then(() => onChangeOutcome?.());
          return reorderedItems;
        });
      }
    };
    const [value, setValue] = useState("");
    const error = useMemo(() => {
      return value.trim().length > 0
        ? !isValidIdentifier(value)
          ? "Identifiers should begin with a letter, not end in an underscore, and should contain only alphanumeric characters or spaces"
          : columns.some(({ name }) => name === value)
            ? "Column name must be unique"
            : undefined
        : undefined;
    }, [columns, value]);
    const onChange = useCallback<
      ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    >((event) => {
      setValue(event.target.value);
    }, []);
    const onKeyDown = useCallback<
      KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>
    >(
      (event) => {
        if (event.key === "Enter" && value.length > 0 && !error) {
          handleTableColumnCreate(tableNodeId, {
            formatType: TableColumnFormatType.String,
            name: value,
            nullable: false,
            primitiveType: ColumnDataType.Text,
          }).then((response) =>
            onChangeOutcome?.(
              true,
              response?.data?.draftTableLeafNode.addColumn.id
            )
          );
          setValue("");
        }
      },
      [error, handleTableColumnCreate, onChangeOutcome, tableNodeId, value]
    );
    const [
      datasetSchemaExtractionDialogOpen,
      setDatasetSchemaExtractionDialogOpen,
    ] = useState<boolean>(false);
    const dataNodeColumns = columns;
    const replaceWithColumns = useCallback(
      async (columns: VersionedSchema["columns"]) => {
        await Promise.all(
          dataNodeColumns.map(({ id }: { id: string }) =>
            handleTableColumnDelete(id)
          )
        );
        const creationResult = await chainPromises(
          columns,
          ({ name, nullable, formatType, hashWith }) =>
            handleTableColumnCreate(tableNodeId, {
              formatType,
              hashWith,
              name,
              nullable,
              primitiveType: castFormatTypeToPrimitiveType(formatType),
            })
        );
        const columnsOrder = creationResult.results
          .map(({ result }) => result?.data?.draftTableLeafNode?.addColumn?.id)
          .filter((id) => id !== undefined) as string[];
        await handleTableColumnsOrderUpdate({ columnsOrder, id: tableNodeId });
      },
      [
        dataNodeColumns,
        handleTableColumnsOrderUpdate,
        tableNodeId,
        handleTableColumnDelete,
        handleTableColumnCreate,
      ]
    );
    if (columns.length === 0 && readOnly) {
      return (
        <Typography level="body-sm" sx={{ mt: 1, pl: 0.5 }}>
          This table has no columns
        </Typography>
      );
    }
    const onOutcomeDialogOpen = () => onChangeOutcome?.();
    return (
      <>
        {readOnly ? (
          <DqTable
            columns={[
              { accessorKey: "name", header: "Column", id: "column" },
              {
                Cell: ({ cell }) => {
                  const value = cell.getValue<string>();
                  return capitalize(value);
                },
                accessorKey: "formatType",
                header: "Type",
                id: "formatType",
                muiTableBodyCellProps: { align: "center" },
                muiTableHeadCellProps: { align: "center" },
              },
              {
                Cell: ({ cell }) => {
                  const value = cell.getValue<boolean>();
                  return <Checkbox checked={value} disabled={true} />;
                },
                accessorKey: "nullable",
                header: "Allow empty values",
                id: "nullable",
                muiTableBodyCellProps: { align: "center" },
                muiTableHeadCellProps: { align: "center" },
              },
              {
                Cell: ({ cell }) => {
                  const value = cell.getValue<boolean>();
                  return <Checkbox checked={value} disabled={true} />;
                },
                accessorKey: "hashWith",
                header: "Hashed values",
                id: "hashWith",
                muiTableBodyCellProps: { align: "center" },
                muiTableHeadCellProps: { align: "center" },
              },
            ]}
            data={columns}
          />
        ) : (
          <Fragment>
            <DndContext
              collisionDetection={closestCenter}
              modifiers={[restrictToParentElement, restrictToVerticalAxis]}
              onDragEnd={onDragEnd}
              sensors={sensors}
            >
              <SortableContext
                items={items}
                strategy={verticalListSortingStrategy}
              >
                <Divider />
                <DqTable
                  columns={[
                    {
                      Cell: ({ cell, row }) => {
                        const { id } = row;
                        const name = cell.getValue<string>();
                        return (
                          <Input
                            onChange={(event) => {
                              handleTableColumnNameUpdate(
                                id,
                                SANITIZE_IDENTIFIER_INPUT
                                  ? sanitizeIdentifier(event.target.value)
                                  : event.target.value
                              );
                            }}
                            sx={{
                              "&:not(.Mui-focused)": {
                                backgroundColor: "transparent",
                              },
                            }}
                            value={name}
                            variant="plain"
                          />
                        );
                      },
                      Footer: readOnly ? null : (
                        <FormControl error={Boolean(error)} sx={{ flex: 1 }}>
                          <Input
                            autoFocus={true}
                            data-testid={
                              testIds.dataNode.tableColumnConstructor
                                .tableColumn
                            }
                            endDecorator={
                              value && !error ? (
                                <Tooltip placement="top" title="Add">
                                  <IconButton>
                                    <FontAwesomeIcon icon={faPlus} />
                                  </IconButton>
                                </Tooltip>
                              ) : undefined
                            }
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                            placeholder="Add column…"
                            sx={{
                              "&:not(.Mui-focused)": {
                                backgroundColor: "transparent",
                              },
                            }}
                            value={value}
                            variant="plain"
                          />
                          <FormHelperText>{error}</FormHelperText>
                        </FormControl>
                      ),
                      accessorKey: "name",
                      header: "Column name",
                      id: "name",
                      muiTableHeadCellProps: { style: { paddingLeft: "12px" } },
                    },
                    {
                      Cell: () => {
                        return (
                          <Checkbox
                            checkedIcon={<FontAwesomeIcon icon={faAmpersand} />}
                            defaultChecked={false}
                            onChange={undefined}
                            sx={{ zIndex: 5 }}
                          />
                        );
                      },
                      Header: () => {
                        return (
                          <Chip
                            endDecorator={
                              // eslint-disable-next-line no-alert
                              <ChipDelete onDelete={() => alert("Delete")} />
                            }
                          >
                            <Tooltip
                              placement="top"
                              sx={{ maxWidth: 300 }}
                              title="Unique constraint requires rows to have unique values in the selected columns in order for dataset provision to succeed."
                            >
                              <span
                                style={{
                                  display: "inline-block",
                                  height: "14px",
                                }}
                              >
                                <FontAwesomeIcon icon={faLink} />
                                <sub>
                                  <small>
                                    <FontAwesomeIcon icon={fa1} />
                                  </small>
                                </sub>
                              </span>
                            </Tooltip>
                          </Chip>
                        );
                      },
                      accessorKey: "unique_constraint_1",
                      enableResizing: false,
                      grow: false,
                      header: "",
                      muiTableBodyCellProps: {
                        align: "center",
                        sx: {
                          "&:hover::after": {
                            height: "10000px !important",
                            top: "-5000px !important",
                          },
                          overflow: "visible",
                        },
                      },
                      muiTableHeadCellProps: { align: "center" },
                      size: 70,
                    },
                    {
                      Cell: () => {
                        return (
                          <Checkbox
                            checkedIcon={<FontAwesomeIcon icon={faAmpersand} />}
                            defaultChecked={false}
                            onChange={undefined}
                            sx={{ zIndex: 5 }}
                          />
                        );
                      },
                      Header: () => {
                        return (
                          <Chip
                            endDecorator={
                              // eslint-disable-next-line no-alert
                              <ChipDelete onDelete={() => alert("Delete")} />
                            }
                          >
                            <Tooltip
                              placement="top"
                              sx={{ maxWidth: 300 }}
                              title="Unique constraint requires rows to have unique values in the selected columns in order for dataset provision to succeed."
                            >
                              <span
                                style={{
                                  display: "inline-block",
                                  height: "14px",
                                }}
                              >
                                <FontAwesomeIcon icon={faLink} />
                                <sub>
                                  <small>
                                    <FontAwesomeIcon icon={fa2} />
                                  </small>
                                </sub>
                              </span>
                            </Tooltip>
                          </Chip>
                        );
                      },
                      accessorKey: "unique_constraint_2",
                      enableResizing: false,
                      grow: false,
                      header: "",
                      muiTableBodyCellProps: {
                        align: "center",
                        sx: {
                          "&:hover::after": {
                            height: "10000px !important",
                            top: "-5000px !important",
                          },
                          overflow: "visible",
                        },
                      },
                      muiTableHeadCellProps: { align: "center" },
                      size: 70,
                    },
                    {
                      Header: () => {
                        return (
                          <Tooltip
                            placement="top"
                            title="Add unique constraint"
                          >
                            <IconButton>
                              <span
                                style={{
                                  display: "inline-block",
                                  fontSize: "14px",
                                  height: "14px",
                                }}
                              >
                                <FontAwesomeIcon icon={faLink} />
                                <sub style={{ marginLeft: "-2px" }}>
                                  <small>
                                    <FontAwesomeIcon icon={faPlus} />
                                  </small>
                                </sub>
                              </span>
                            </IconButton>
                          </Tooltip>
                        );
                      },
                      accessorKey: "add_unique_constraint",
                      enableResizing: false,
                      grow: false,
                      header: "",
                      muiTableHeadCellProps: {
                        align: "center",
                        sx: { alignItems: "center", p: 0, pb: 0, pt: 0 },
                      },
                      size: 40,
                    },
                    {
                      Cell: ({ row }) => {
                        const { id } = row;
                        const { formatType, primitiveType } = row.original;
                        return (
                          <Select
                            // disabled={disabled}
                            onChange={(event, value) => {
                              if (!isPublished) {
                                const formatType =
                                  value as TableColumnFormatType;
                                const primitiveType =
                                  castFormatTypeToPrimitiveType(formatType);
                                handleTableColumnDataTypeUpdate(
                                  id,
                                  primitiveType,
                                  formatType
                                ).then(() => onOutcomeDialogOpen?.());
                              }
                            }}
                            slotProps={{ listbox: { variant: "outlined" } }}
                            sx={{ backgroundColor: "transparent" }}
                            value={
                              !isPublished
                                ? formatType ||
                                  castPrimitiveTypeToFormatType(primitiveType)
                                : formatType || primitiveType
                            }
                            variant="plain"
                          >
                            {!isPublished || (isPublished && formatType)
                              ? dataRoomTableFormatTypeOptions?.map(
                                  ({ label, value }, index) => (
                                    <Option key={index} value={value}>
                                      {label}
                                    </Option>
                                  )
                                )
                              : dataRoomTablePrimitiveTypeOptions?.map(
                                  ({ label, value }, index) => (
                                    <Option key={index} value={value}>
                                      {label}
                                    </Option>
                                  )
                                )}
                          </Select>
                        );
                      },
                      accessorKey: "primitiveType",
                      header: "Data type",
                      id: "primitiveType",
                      muiTableHeadCellProps: { style: { paddingLeft: "12px" } },
                    },
                    {
                      Cell: ({ cell, row }) => {
                        const { id } = row;
                        const hashWith = cell.getValue<string>();
                        return (
                          <Checkbox
                            checked={
                              hashWith === TableColumnHashingAlgorithm.Sha256Hex
                            }
                            // disabled={isHashWithDisabled}
                            onChange={(event) => {
                              handleTableColumnHashWithUpdate(
                                id,
                                event.target.checked
                              ).then(onOutcomeDialogOpen);
                            }}
                          />
                        );
                      },
                      Header: ({ column }) => {
                        return (
                          <Tooltip
                            placement="top"
                            sx={{ maxWidth: 300 }}
                            title="Check this box to require values in this column to be a valid SHA256 hash. When provisioning a dataset, there will be an option to hash values automatically if they are not yet hashed."
                          >
                            <span>{column.columnDef.header}</span>
                          </Tooltip>
                        );
                      },
                      accessorKey: "hashWith",
                      header: "Hashed values",
                      id: "hashWith",
                      muiTableBodyCellProps: { align: "center" },
                      muiTableHeadCellProps: { align: "center" },
                    },
                    {
                      Cell: ({ cell, row }) => {
                        const { id } = row;
                        const nullable = cell.getValue<boolean>();
                        return (
                          <Checkbox
                            checked={nullable}
                            // disabled={disabled}
                            onChange={(event) => {
                              handleTableColumnNullableUpdate(
                                id,
                                event.target.checked
                              ).then(onOutcomeDialogOpen);
                            }}
                          />
                        );
                      },
                      accessorKey: "nullable",
                      header: "Allow empty values",
                      id: "nullable",
                      muiTableBodyCellProps: { align: "center" },
                      muiTableHeadCellProps: { align: "center" },
                    },
                  ]}
                  data={items.map((item) => {
                    const index = columns.findIndex(
                      (column) => column.id === item
                    );
                    const {
                      id,
                      name = "",
                      primitiveType = ColumnDataType.Text,
                      nullable = true,
                      formatType,
                      hashWith,
                    } = columns[index] || {};
                    return {
                      formatType,
                      hashWith,
                      id,
                      name,
                      nullable,
                      primitiveType,
                    };
                  })}
                  enableRowActions={true}
                  enableRowOrdering={true}
                  enableStickyFooter={true}
                  enableTableFooter={!readOnly}
                  enableTopToolbar={true}
                  getRowId={(row) => row.id}
                  muiTableBodyCellProps={{ sx: { p: 0 } }}
                  muiTableBodyRowProps={{
                    sx: { "&:not(:hover)": { zIndex: 1 } },
                  }}
                  muiTableFooterCellProps={{ sx: { p: 0 } }}
                  muiTableFooterProps={{
                    sx: { "& .MuiTableCell-footer": { fontWeight: "inherit" } },
                  }}
                  muiTablePaperProps={{ sx: { borderRadius: 0 } }}
                  muiTableProps={{
                    style: {
                      "--col-mrt_row_actions-size": 36,
                      "--col-mrt_row_drag-size": 36,
                      "--header-mrt_row_actions-size": 36,
                      "--header-mrt_row_drag-size": 36,
                    } as React.CSSProperties,
                  }}
                  muiTopToolbarProps={{
                    sx: { "& > *": { padding: "0 !important" }, p: 0 },
                  }}
                  renderRowActions={({ row }) => {
                    const { id } = row;
                    return [
                      <Tooltip placement="top" title="Delete">
                        <IconButton
                          key="delete"
                          onClick={() =>
                            handleTableColumnDelete(id).then(
                              onOutcomeDialogOpen
                            )
                          }
                          sx={{ "--focus-outline-offset": "-2px" }}
                        >
                          <FontAwesomeIcon icon={faTrashCan} />
                        </IconButton>
                      </Tooltip>,
                    ];
                  }}
                  renderTopToolbarCustomActions={() => (
                    <Button
                      onClick={() => setDatasetSchemaExtractionDialogOpen(true)}
                      slotProps={{
                        startDecorator: { sx: { fontSize: "1rem" } },
                      }}
                      startDecorator={
                        <FontAwesomeIcon
                          fixedWidth={true}
                          icon={faFileImport}
                        />
                      }
                      sx={{ borderRadius: 0, height: 40 }}
                    >
                      Import schema from dataset
                    </Button>
                  )}
                />
              </SortableContext>
            </DndContext>
            <DatasetSchemaExtractionDialog
              DatasetUploaderProps={{
                OkayButtonProps: {
                  onClick: (_, schema) => {
                    replaceWithColumns?.(schema?.columns || []);
                    setDatasetSchemaExtractionDialogOpen(false);
                  },
                },
                schema: undefined,
              }}
              DialogTitleChildren={
                <>
                  <span>Import schema</span>
                  <IconButton
                    onClick={() => setDatasetSchemaExtractionDialogOpen(false)}
                    sx={{ fontSize: "1.25rem", p: 0.5, width: "2rem" }}
                  >
                    <FontAwesomeIcon fixedWidth={true} icon={faXmark} />
                  </IconButton>
                </>
              }
              DialogTitleProps={{
                sx: {
                  display: "flex",
                  justifyContent: "space-between",
                },
              }}
              onClose={() => setDatasetSchemaExtractionDialogOpen(false)}
              open={datasetSchemaExtractionDialogOpen}
            />
          </Fragment>
        )}
      </>
    );
  });
