import { InfoTooltip } from "@decentriq/components";
import {
  useUpdatePreviewComputeNodeDependencyMutation,
  useUpdatePreviewComputeNodeQuotaMutation,
} from "@decentriq/graphql/dist/hooks";
import { testIds } from "@decentriq/utils";
import {
  Box,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Option,
  Select,
} from "@mui/joy";
import { Fragment, memo, useCallback, useMemo } from "react";
import { useComputeNodesVars, useDataRoom } from "contexts";
import {
  mapDraftDataRoomErrorToSnackbar,
  useDataRoomSnackbar,
  useNodes,
} from "hooks";
import { ComputeNodeTypeNames } from "models";
import usePreviewComputeNode from "./usePreviewComputeNode";

interface PreviewComputeNodeEditorProps {
  computeNodeId: string;
  readOnly?: boolean;
}

type Unit = "B" | "KB" | "MB" | "GB" | "TB";

const isUnit = (unit: string): unit is Unit =>
  ["B", "KB", "MB", "GB", "TB"].includes(unit);

const sizeUnitMap: Record<Unit, number> = {
  B: 1,
  GB: 1024 * 1024 * 1024,
  KB: 1024,
  MB: 1024 * 1024,
  TB: 1024 * 1024 * 1024 * 1024,
};

const PreviewComputeNodeEditor = memo(
  ({ computeNodeId, readOnly }: PreviewComputeNodeEditorProps) => {
    const { enqueueSnackbar } = useDataRoomSnackbar();
    const { dataRoomId } = useDataRoom();
    const { executionContext } = useComputeNodesVars();
    const isInteractivityContext =
      executionContext === "development" || executionContext === "requests";
    const { dependency, isLoading, node } =
      usePreviewComputeNode(computeNodeId);
    const totalQuotaBytes = useMemo(
      () =>
        node === undefined
          ? 0
          : "quotaBytes" in node
            ? node.quotaBytes
            : "totalQuotaBytes" in node
              ? node.totalQuotaBytes!
              : 0,
      [node]
    );

    const { totalQuotaValue, totalQuotaUnit } = useMemo((): {
      totalQuotaValue: number;
      totalQuotaUnit: Unit;
    } => {
      if (!totalQuotaBytes) {
        return {
          totalQuotaUnit: "KB",
          totalQuotaValue: 0,
        };
      }
      // If not 0 and divisible by MB -> treat it as MB
      if (totalQuotaBytes % sizeUnitMap["MB"] === 0) {
        return {
          totalQuotaUnit: "MB",
          totalQuotaValue: totalQuotaBytes / sizeUnitMap["MB"],
        };
      }
      return {
        totalQuotaUnit: "KB",
        totalQuotaValue: totalQuotaBytes / sizeUnitMap["KB"],
      };
    }, [totalQuotaBytes]);

    const previewInput = useMemo(() => {
      return (
        dependency &&
        ("computeNodeId" in dependency
          ? dependency.computeNodeId
          : "id" in dependency
            ? dependency.id
            : undefined)
      );
    }, [dependency]);
    const { nodes } = useNodes();

    // Prevent selecting any preview nodes as a dependency
    const validInputNodes = nodes.filter(
      (node) =>
        ![
          ComputeNodeTypeNames.PublishedPreviewNode,
          ComputeNodeTypeNames.DraftPreviewNode,
          ComputeNodeTypeNames.DraftPreviewNode,
          ComputeNodeTypeNames.PublishedPostNode,
        ].includes(node.__typename)
    );

    const inputList =
      validInputNodes.map(
        (computeNode): { id: string; label: string; value: string } => ({
          id: computeNode?.id,
          label: computeNode?.name,
          value: computeNode?.id,
        })
      ) || [];

    const [updatePreviewComputeNodeDependencyMutation] =
      useUpdatePreviewComputeNodeDependencyMutation();

    const [updatePreviewComputeNodeQuotaMutation] =
      useUpdatePreviewComputeNodeQuotaMutation();

    const updatePreviewComputeNodeQuota = useCallback(
      async (quotaValue: number, unit: Unit) => {
        try {
          const quotaBytes = Math.round(quotaValue * sizeUnitMap[unit]);
          await updatePreviewComputeNodeQuotaMutation({
            variables: {
              computeNodeId,
              quotaBytes: {
                value: quotaBytes,
              },
            },
          });
        } catch (error) {
          enqueueSnackbar(
            ...mapDraftDataRoomErrorToSnackbar(
              error,
              "Airlock settings could not be updated."
            )
          );
          throw error;
        }
      },
      [updatePreviewComputeNodeQuotaMutation, computeNodeId, enqueueSnackbar]
    );

    const handleUnitChange = useCallback(
      (event, value) => {
        const unit = value;
        if (isUnit(unit)) {
          void updatePreviewComputeNodeQuota(totalQuotaValue, unit);
        }
      },
      [totalQuotaValue, updatePreviewComputeNodeQuota]
    );

    const handleTotalQuotaChange: React.ChangeEventHandler<HTMLInputElement> =
      useCallback(
        (event) => {
          const quotaValue = parseInt(event.target.value);
          void updatePreviewComputeNodeQuota(quotaValue, totalQuotaUnit);
        },
        [totalQuotaUnit, updatePreviewComputeNodeQuota]
      );

    const updatePreviewComputeNodeDependency = useCallback(
      async (dependencyId: string) => {
        try {
          return await updatePreviewComputeNodeDependencyMutation({
            variables: {
              computeNodeId,
              dependencyId: isInteractivityContext
                ? {
                    published: {
                      computeNodeId: dependencyId,
                      publishedDataRoomId: dataRoomId,
                    },
                  }
                : { draft: dependencyId },
            },
          });
        } catch (error) {
          enqueueSnackbar(
            ...mapDraftDataRoomErrorToSnackbar(
              error,
              "Airlock settings could not be updated."
            )
          );
          throw error;
        }
      },
      [
        updatePreviewComputeNodeDependencyMutation,
        computeNodeId,
        isInteractivityContext,
        dataRoomId,
        enqueueSnackbar,
      ]
    );
    return !isLoading ? (
      <Box paddingTop={0.5} position="relative" style={{ height: "100%" }}>
        <Grid container={true} spacing={3}>
          <Grid>
            <FormControl>
              <FormLabel>Preview data from</FormLabel>
              <Select
                data-testid={
                  testIds.computeNode.computeNodeEditor.previewDataFrom
                }
                disabled={readOnly}
                onChange={(event, value) => {
                  updatePreviewComputeNodeDependency(value || "");
                }}
                renderValue={({ value }) =>
                  inputList.find(
                    (i: { id: string; label: string }) => i.id === value
                  )?.label || "Data source"
                }
                sx={{ maxWidth: "250px", minWidth: 144, textAlign: "center" }}
                value={previewInput}
              >
                {inputList.map(({ id, label }) => (
                  <Option
                    data-testid={`${testIds.computeNode.computeNodeEditor.dataHelper}${label}`}
                    key={id}
                    value={id}
                  >
                    {label}
                  </Option>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid>
            <FormControl>
              <FormLabel>
                Limit total result preview
                <InfoTooltip tooltip="The Analyst will only be able to preview results of the selected input up to the maximum size defined on this field. Even if the result may be previewed in smaller pieces, it will count against this limit. Once the limit is reached, the Analyst will not be able to retrieve any result from the input data." />
              </FormLabel>
              <Input
                data-testid={testIds.computeNode.computeNodeEditor.dataSize}
                disabled={readOnly}
                endDecorator={
                  <Fragment>
                    <Divider orientation="vertical" />
                    <Select
                      disabled={readOnly}
                      onChange={handleUnitChange}
                      slotProps={{
                        listbox: {
                          variant: "outlined",
                        },
                      }}
                      sx={{ "&:hover": { bgcolor: "transparent" }, mr: -1.5 }}
                      value={totalQuotaUnit}
                    >
                      <Option value="KB">KB</Option>
                      <Option value="MB">MB</Option>
                    </Select>
                  </Fragment>
                }
                onChange={handleTotalQuotaChange}
                sx={{ width: 250 }}
                type="number"
                value={totalQuotaValue}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    ) : null;
  }
);

PreviewComputeNodeEditor.displayName = "PreviewComputeNodeEditor";

export default PreviewComputeNodeEditor;
