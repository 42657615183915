// TODO: Fix joy migration
import {
  usePublishedDataRoomPasswordRequirementsQuery,
  usePublishedDataRoomQuery,
  useUnlockPublishedDataRoomMutation,
} from "@decentriq/graphql/dist/hooks";
import { PublishedDataRoomSource } from "@decentriq/graphql/dist/types";
import {
  faEye,
  faEyeSlash,
  faInfoCircle,
  faLockKeyhole,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Typography,
} from "@mui/joy";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Navigate,
  useNavigate,
  useResolvedPath,
  useRoutes,
} from "react-router-dom";
import { Loading, Mainbar, PublishedDataRoomErrorReport } from "components";
import PublishedDataRoomTabs from "containers/PublishedDataRoomTabs/PublishedDataRoomTabs";
import {
  type PublishedDataRoomContextValue,
  PublishedDataRoomProvider,
} from "contexts";
import { mapErrorToGeneralSnackbar, useDataRoomSnackbar } from "hooks";
import { PublishedDataRoomTab, publishedDataRoomTabs } from "models";
import DataRoomExpertView from "../DataRoomExpertView/DataRoomExpertView";

interface PublishedDataRoomProps {
  dataRoomId: string;
  setAllowTestMode: (allowTestMode: boolean) => void;
}

const defaultTab = PublishedDataRoomTab.overview;

const PublishedDataRoom: React.FC<PublishedDataRoomProps> = ({
  dataRoomId,
  setAllowTestMode,
}) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const [permittedOnly, setPermittedOnly] = useState<boolean>(true);
  const handlePermittedOnlyChange = useCallback(
    () => setPermittedOnly((state) => !state),
    [setPermittedOnly]
  );
  const resolvedPath = useResolvedPath("");
  const url = resolvedPath.pathname;
  const switchToRequests = useCallback(
    () => navigate(`${url}/${PublishedDataRoomTab.requests}`),
    [navigate, url]
  );
  const switchToOverview = useCallback(
    () => navigate(`${url}/${PublishedDataRoomTab.overview}`),
    [navigate, url]
  );
  const switchToDev = useCallback(
    () => navigate(`${url}/${PublishedDataRoomTab.development}`),
    [navigate, url]
  );
  const { data, loading, error } = usePublishedDataRoomQuery({
    onError: (error) => {
      if (
        !error.message.includes(
          "DcrSecretPolicy is set, but no corresponding DcrSecretClaim was found"
        )
      ) {
        enqueueSnackbar(
          ...mapErrorToGeneralSnackbar(
            error,
            "Published data clean room could not be retrieved. Please try again by refreshing the page."
          )
        );
      }
    },
    variables: {
      dataRoomId: dataRoomId,
    },
  });
  const enableInteractivity = Boolean(
    data?.publishedDataRoom?.enableInteractivity
  );
  const ownerEmail = data?.publishedDataRoom?.owner?.email;
  const isStopped = data?.publishedDataRoom?.isStopped || false;
  const isDeactivated = !!data?.publishedDataRoom?.deactivatedAt || false;
  const testing = data?.publishedDataRoom?.testing || false;
  const driverAttestationHash = data?.publishedDataRoom?.driverAttestationHash;
  const requestsTotalCount =
    data?.publishedDataRoom?.submittedRequests?.nodes?.length || 0;
  const enableAutomergeFeature =
    data?.publishedDataRoom?.enableAutomergeFeature || false;
  const enableAirlock = data?.publishedDataRoom?.enableAirlock || false;
  const createdAt = data?.publishedDataRoom?.createdAt;
  const enableTestDatasets = data?.publishedDataRoom?.enableTestDatasets;
  useEffect(() => {
    if (enableTestDatasets !== undefined) {
      setAllowTestMode(enableTestDatasets);
    }
  }, [setAllowTestMode, enableTestDatasets]);
  useEffect(() => {
    if (testing || isStopped || isDeactivated) {
      setPermittedOnly(false);
    }
  }, [testing, isStopped, isDeactivated, setPermittedOnly]);
  const tabs = useMemo(
    () => [
      {
        element: <Navigate to={`${url}/${defaultTab}`} />,
        path: "/",
      },
      ...publishedDataRoomTabs.map((tab) => ({
        element: (
          <PublishedDataRoomTabs
            activeTab={tab}
            baseUrl={url}
            createdAt={createdAt}
            handlePermittedOnlyChange={handlePermittedOnlyChange}
            ownerEmail={ownerEmail}
            permittedOnly={permittedOnly}
            requestsTotalCount={requestsTotalCount}
          />
        ),
        path: tab,
      })),
      {
        element: <Navigate to={`${url}/${defaultTab}`} />,
        path: "*",
      },
    ],
    [
      url,
      requestsTotalCount,
      ownerEmail,
      createdAt,
      permittedOnly,
      handlePermittedOnlyChange,
    ]
  );
  const activeTab = useRoutes(tabs);
  const publishedDataRoomContextValue = useMemo<PublishedDataRoomContextValue>(
    () => ({
      dataRoomId: dataRoomId || "",
      dcrHash: dataRoomId || "",
      driverAttestationHash: driverAttestationHash || "",
      enableAirlock,
      enableAutomergeFeature,
      enableRequests: enableInteractivity || false,
      isDeactivated,
      isStopped,
      switchToDev,
      switchToOverview,
      switchToRequests,
      testing,
    }),
    [
      dataRoomId,
      driverAttestationHash,
      enableAirlock,
      enableAutomergeFeature,
      enableInteractivity,
      isDeactivated,
      isStopped,
      switchToDev,
      switchToOverview,
      switchToRequests,
      testing,
    ]
  );
  if (loading) {
    return (
      <CircularProgress
        sx={{ "--CircularProgress-size": "2.5rem", margin: "auto" }}
      />
    );
  } else if (error) {
    return <Navigate replace={true} to="/datarooms" />;
  } else if (
    data &&
    data!.publishedDataRoom.source === PublishedDataRoomSource.Sdk
  ) {
    return <DataRoomExpertView dataRoomId={dataRoomId} />;
  } else if (data) {
    return (
      <PublishedDataRoomProvider value={publishedDataRoomContextValue}>
        <Mainbar />
        {activeTab}
      </PublishedDataRoomProvider>
    );
  } else {
    return <Navigate replace={true} to="/datarooms" />;
  }
};

interface PublishedDataRoomUnlockFormProps {
  dcrHash: string;
  driverAttestationHash: string;
  id: string;
}

const PublishedDataRoomUnlockForm: React.FC<
  PublishedDataRoomUnlockFormProps
> = ({ dcrHash, driverAttestationHash, id }) => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const [password, setPassword] = useState<string | undefined>();
  const [showPassword, setShowPassword] = useState(false);
  const [unlockDataRoomMutation, { loading: isDataRoomUnlockLoading }] =
    useUnlockPublishedDataRoomMutation();
  const unlockDataRoom = useCallback(async () => {
    if (!password?.trim()) {
      return;
    }
    try {
      await unlockDataRoomMutation({
        variables: { dcrHash, driverAttestationHash, id, password },
      });
      enqueueSnackbar("Data clean room was unlocked");
    } catch (error) {
      enqueueSnackbar(
        ...mapErrorToGeneralSnackbar(error, "Can't unlock data clean room")
      );
    }
  }, [
    unlockDataRoomMutation,
    enqueueSnackbar,
    dcrHash,
    driverAttestationHash,
    id,
    password,
  ]);
  const toggleShowPassword = () => setShowPassword(!showPassword);
  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        height: "100%",
        justifyContent: "center",
      }}
    >
      <Box sx={{ p: 2, width: 400 }}>
        <Box sx={{ mb: 1, textAlign: "center" }}>
          <FontAwesomeIcon fixedWidth={true} icon={faInfoCircle} size="3x" />
        </Box>
        <Typography align="center" variant="title-md">
          <strong>
            Please enter the password to interact with this data clean room
          </strong>
        </Typography>
        <Box>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              unlockDataRoom();
            }}
          >
            <FormControl>
              <FormLabel>Password</FormLabel>
              <Input
                endDecorator={
                  <IconButton onClick={toggleShowPassword}>
                    {showPassword ? (
                      <FontAwesomeIcon fixedWidth={true} icon={faEyeSlash} />
                    ) : (
                      <FontAwesomeIcon fixedWidth={true} icon={faEye} />
                    )}
                  </IconButton>
                }
                onChange={(event) => setPassword(event.target.value)}
                type={showPassword ? "text" : "password"}
                value={password || ""}
              />
            </FormControl>
          </form>
        </Box>
        <Box sx={{ mt: 1, textAlign: "center" }}>
          <Button
            color="primary"
            disabled={!password}
            loading={isDataRoomUnlockLoading}
            loadingPosition="start"
            onClick={() => unlockDataRoom()}
            startDecorator={<FontAwesomeIcon icon={faLockKeyhole} />}
            variant="solid"
          >
            Unlock
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

// NOTE:
// Instead of this workflow the "Unlocker" should be rendered
// when `publishedDataRoom` query yields an error, perhaps even with `ErrorBoundary`
const PublishedDataRoomPasswordChecker: React.FC<PublishedDataRoomProps> = ({
  dataRoomId,
  setAllowTestMode,
}) => {
  const { data, loading } = usePublishedDataRoomPasswordRequirementsQuery({
    variables: { id: dataRoomId },
  });
  const { publishedDataRoom } = data || {};
  const {
    driverAttestationHash = "",
    requirePassword,
    password,
  } = publishedDataRoom || {};
  return loading ? (
    <Loading />
  ) : (
    <PublishedDataRoomErrorReport
      createdAt={data!.publishedDataRoom.createdAt}
      dataRoomId={dataRoomId}
      driverAttestationHash={driverAttestationHash}
    >
      {requirePassword && !password ? (
        <PublishedDataRoomUnlockForm
          dcrHash={dataRoomId}
          driverAttestationHash={driverAttestationHash}
          id={dataRoomId}
        />
      ) : (
        <PublishedDataRoom
          dataRoomId={dataRoomId}
          setAllowTestMode={setAllowTestMode}
        />
      )}
    </PublishedDataRoomErrorReport>
  );
};

export default PublishedDataRoomPasswordChecker;
