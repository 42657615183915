import { testIds } from "@decentriq/utils";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Tooltip,
} from "@mui/joy";
import { memo } from "react";
import {
  useCreationWizardConfiguration,
  useCreationWizardStepper,
} from "features/MediaInsightsDcr/contexts";
import {
  CollaborationTypesEditor,
  DataRoomName,
  MatchingIdSelector,
  RolesAndParticipants,
} from "./components";

const MediaInsightsDcrCreateCollaborationConfigurationStep: React.FC = () => {
  const { contactButtonTooltip, handleSubmit } =
    useCreationWizardConfiguration();
  const { handleBackStep } = useCreationWizardStepper();
  return (
    <>
      <DialogTitle>Create new media clean room</DialogTitle>
      <Divider />
      <DialogContent>
        <Box mb={0}>
          <DataRoomName />
        </Box>
        <Box mb={2}>
          <RolesAndParticipants />
        </Box>
        <Grid columnSpacing={1} container={true}>
          <Grid xs={4}>
            <MatchingIdSelector />
          </Grid>
          <Grid xs={8}>
            <CollaborationTypesEditor />
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleBackStep}>Back</Button>
        <Tooltip title={contactButtonTooltip}>
          <div>
            <Button
              color="primary"
              data-testid={
                testIds.mediaInsightsDcr.mediaInsightsDcrCreateWizard
                  .submitButton
              }
              onClick={handleSubmit}
              variant="solid"
            >
              Publish
            </Button>
          </div>
        </Tooltip>
      </DialogActions>
    </>
  );
};

MediaInsightsDcrCreateCollaborationConfigurationStep.displayName =
  "MediaInsightsDcrCreateCollaborationConfigurationStep";

export default memo(MediaInsightsDcrCreateCollaborationConfigurationStep);
