import {
  faAngleDown,
  faCheck,
  faFlask,
  faLinkSlash,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  ButtonGroup,
  CircularProgress,
  IconButton,
  ListItemDecorator,
  Menu,
  MenuItem,
} from "@mui/joy";
import { ClickAwayListener } from "@mui/material";
import { Fragment, memo, useCallback } from "react";
import { usePopupState } from "hooks";
import { bindToggle } from "hooks/usePopupState/usePopupState";
import {
  useMediaInsightsDcrData,
  usePublishedMediaInsightsDcr,
} from "../../../../contexts/MediaInsightsDcrContext/MediaInsightsDcrContext";
import usePublisherDataNodeActions from "../../usePublisherDataNodeActions";

type PublisherDataNodeDeprovisionButtonProps = {
  retrieveDatasets: () => Promise<void>;
};

const PublisherDataNodeDeprovisionButton: React.FC<
  PublisherDataNodeDeprovisionButtonProps
> = ({ retrieveDatasets }) => {
  const popupId = "publisher-data-node-deprovision-menu";
  const popupState = usePopupState({ popupId });
  const { anchorEl, isOpen, close } = popupState;
  const { isPublisher } = usePublishedMediaInsightsDcr();
  const { publishedDataLab } = useMediaInsightsDcrData();
  const { deprovisionDataLab, deprovisioning } = usePublisherDataNodeActions({
    retrieveDatasets,
  });
  const handleDataLabDeprovision = useCallback(() => {
    deprovisionDataLab(publishedDataLab!);
  }, [deprovisionDataLab, publishedDataLab]);
  if (deprovisioning) {
    return <CircularProgress sx={{ "--CircularProgress-size": "16px" }} />;
  }
  return (
    <Fragment>
      <ButtonGroup color="primary" role="group">
        <Button
          startDecorator={
            <FontAwesomeIcon
              fixedWidth={true}
              icon={isPublisher ? faFlask : faCheck}
            />
          }
          style={{
            justifyContent: "flex-start",
            maxWidth: "221px",
            minWidth: "221px",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {publishedDataLab?.name || "Datalab provisioned"}
        </Button>
        <IconButton {...bindToggle(popupState)}>
          <FontAwesomeIcon icon={faAngleDown} />
        </IconButton>
      </ButtonGroup>
      <ClickAwayListener
        mouseEvent="onMouseDown"
        onClickAway={(event) => {
          if ((event.target as HTMLElement).closest("button") !== anchorEl) {
            close();
          }
        }}
      >
        <Menu
          anchorEl={anchorEl}
          id={popupId}
          onClose={close}
          open={isOpen}
          placement="bottom-end"
          sx={{ "--ListItemDecorator-size": "1.625rem" }}
        >
          <MenuItem onClick={handleDataLabDeprovision}>
            <ListItemDecorator>
              <FontAwesomeIcon fixedWidth={true} icon={faLinkSlash} />
            </ListItemDecorator>
            Deprovision datalab
          </MenuItem>
        </Menu>
      </ClickAwayListener>
    </Fragment>
  );
};

PublisherDataNodeDeprovisionButton.displayName =
  "PublisherDataNodeDeprovisionButton";

export default memo(PublisherDataNodeDeprovisionButton);
