import { InfoTooltip } from "@decentriq/components";
import { Box, Divider, Typography } from "@mui/joy";
import { memo } from "react";
import CreatePublisherDisplayConfigurationButton from "features/publisherPortal/components/PublisherConfigurations/components/CreatePublisherDisplayConfigurationButton/CreatePublisherDisplayConfigurationButton";
import { usePublisherPortal } from "features/publisherPortal/contexts";
import {
  ActivationConfigurationsTable,
  CreateActivationConfigurationButton,
  PublisherDisplayConfiguration,
} from "./components";

const PublisherConfigurations: React.FC = () => {
  const { publisherDisplayConfiguration } = usePublisherPortal();
  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flex: 2,
          flexDirection: "column",
          marginBottom: 4,
          minHeight: "300px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: 2,
          }}
        >
          <Typography level="body-sm">
            <strong>Display configuration</strong>
            <InfoTooltip tooltip="Setting up the display configuration lets you be discovered by advertisers in the Decentriq platform. They can contact you in their platform view via email." />
          </Typography>
          {publisherDisplayConfiguration === null && (
            <CreatePublisherDisplayConfigurationButton />
          )}
        </Box>
        <PublisherDisplayConfiguration />
      </Box>
      <Divider />
      <Box
        sx={{
          flex: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography level="body-sm" sx={{ padding: 2 }}>
            <strong>DCR configurations</strong>
            <InfoTooltip tooltip="Setting up a DCR configuration lets you define the details of a data clean room for specific advertisers. This allows these advertiser to create preconfigured data clean rooms with you." />
          </Typography>
          <Box sx={{ marginLeft: "auto", padding: 2, width: "fit-content" }}>
            <CreateActivationConfigurationButton />
          </Box>
        </Box>
        <ActivationConfigurationsTable />
      </Box>
    </Box>
  );
};

PublisherConfigurations.displayName = "PublisherConfigurations";

export default memo(PublisherConfigurations);
