import { Stack } from "@mui/joy";
import { memo } from "react";
import AttributionSelector from "./components/AttributionSelector";
import DataRoomName from "./components/DataRoomName";
import MatchingId from "./components/MatchingId";
import RolesAndParticipants from "./components/RolesAndParticipants";

const CreateMeasurementDcrFormControls = memo(() => {
  return (
    <Stack>
      <DataRoomName />
      <RolesAndParticipants />
      <AttributionSelector />
      <MatchingId />
    </Stack>
  );
});
CreateMeasurementDcrFormControls.displayName =
  "CreateMeasurementDcrFormControls";

export default CreateMeasurementDcrFormControls;
