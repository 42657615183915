// TODO: Fix joy migration
import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  ModalDialog,
  Typography,
} from "@mui/joy";
import { memo } from "react";
import { type DataType } from "models";

interface DatasetDeletionDialogProps {
  open: boolean;
  mode: DatasetDeletionMode;
  dataType: DataType;
  loading?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export enum DatasetDeletionMode {
  delete,
  deprovision,
  replace,
}

const DatasetDeletionDialog: React.FC<DatasetDeletionDialogProps> = ({
  open,
  loading,
  onCancel,
  onConfirm,
  dataType = "table",
  mode = DatasetDeletionMode.delete,
}) => {
  const dataIngestionLabel = dataType === "table" ? "dataset" : "file";
  const title =
    mode === DatasetDeletionMode.delete ||
    mode === DatasetDeletionMode.replace ? (
      <strong>
        Are you sure you want to{" "}
        {mode === DatasetDeletionMode.replace ? "replace" : "delete"} this{" "}
        {dataIngestionLabel}?
      </strong>
    ) : (
      <strong>
        Are you sure you want to deprovision this {dataIngestionLabel}?
      </strong>
    );
  const content =
    mode === DatasetDeletionMode.delete || mode === DatasetDeletionMode.replace
      ? `This is irreversible: all traces of your data, including all derived
        ${dataIngestionLabel}s and results will be deleted from the
        platform.`
      : `This will deprovision this {dataIngestionLabel} from this data clean
        room. The data is not deleted yet. To delete it, go to the Datasets
        page.`;
  const action =
    mode === DatasetDeletionMode.delete
      ? "Delete"
      : mode === DatasetDeletionMode.replace
        ? "Replace"
        : "Deprovision";
  return (
    <Modal onClose={loading ? undefined : onCancel} open={open}>
      <ModalDialog role="alertdialog">
        <DialogTitle>
          <Box sx={{ color: "error.main", mb: 1, textAlign: "center" }}>
            <FontAwesomeIcon
              fixedWidth={true}
              icon={faExclamationTriangle}
              size="4x"
            />
          </Box>
          <Typography
            align="center"
            level="title-md"
            sx={{ textWrap: "balance" }}
          >
            <strong>{title}</strong>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography
            align="center"
            level="body-sm"
            sx={{ textWrap: "balance" }}
          >
            {content}
          </Typography>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            disabled={loading}
            onClick={(e) => {
              e.stopPropagation();
              onCancel();
            }}
          >
            Cancel
          </Button>
          <Button
            color="danger"
            data-testid="delete-dataset-button"
            loading={loading}
            loadingPosition="start"
            onClick={(e) => {
              e.stopPropagation();
              onConfirm();
            }}
            startDecorator={<FontAwesomeIcon icon={faExclamationTriangle} />}
            variant="solid"
          >
            {action}
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

DatasetDeletionDialog.displayName = "DatasetDeletionDialog";

export default memo(DatasetDeletionDialog);
