// TODO: Fix joy migration
import { InfoTooltip } from "@decentriq/components";
import { Box, Chip, Typography } from "@mui/joy";
import { memo } from "react";
import { matchingIdTypeAndHashingAlgorithmPresentation } from "features/dataLabs/models";
import {
  MediaInsightsDcrCreationStep,
  useCreationWizardDataPartner,
  useCreationWizardPublisher,
  useCreationWizardStepper,
} from "features/MediaInsightsDcr/contexts";

const MediaInsightsDcrCreateMatchingIdChip: React.FC = () => {
  const { activeStep } = useCreationWizardStepper();
  const {
    selectedPublisher,
    hasSkippedSelection: hasSkippedPublisherSelection,
  } = useCreationWizardPublisher();
  const { selectedDataPartner } = useCreationWizardDataPartner();
  const matchingIdFormat =
    activeStep === MediaInsightsDcrCreationStep.SELECT_DATA_PARTNER
      ? selectedPublisher?.matchingIdFormat ===
          selectedDataPartner?.matchingIdFormat || hasSkippedPublisherSelection
        ? selectedDataPartner?.matchingIdFormat
        : null
      : selectedPublisher?.matchingIdFormat;
  const matchingIdHashingAlgorithm =
    activeStep === MediaInsightsDcrCreationStep.SELECT_DATA_PARTNER
      ? selectedPublisher?.matchingIdHashingAlgorithm ===
          selectedDataPartner?.matchingIdHashingAlgorithm ||
        hasSkippedPublisherSelection
        ? selectedDataPartner?.matchingIdHashingAlgorithm
        : null
      : selectedPublisher?.matchingIdHashingAlgorithm;
  return (
    <Box mb={2}>
      <Typography fontWeight="fontWeightSemiBold" level="body-md" mb={0.5}>
        Matching ID
        <InfoTooltip
          tooltip={`Your data needs to have this matching ID. If you need to use a different matching ID, contact your ${activeStep === MediaInsightsDcrCreationStep.SELECT_DATA_PARTNER ? "data partner" : "publisher"}.`}
        />
      </Typography>
      {!matchingIdFormat && !matchingIdHashingAlgorithm ? (
        <Typography color="error" level="body-sm">
          The Matching ID is incompatible with the publisher's
        </Typography>
      ) : (
        <Chip>
          {matchingIdTypeAndHashingAlgorithmPresentation({
            matchingIdFormat: matchingIdFormat!,
            matchingIdHashingAlgorithm,
          }).toUpperCase()}
        </Chip>
      )}
    </Box>
  );
};

MediaInsightsDcrCreateMatchingIdChip.displayName =
  "MediaInsightsDcrCreateMatchingIdChip";

export default memo(MediaInsightsDcrCreateMatchingIdChip);
