import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  ModalDialog,
  type ModalProps,
} from "@mui/joy";
import { memo } from "react";
import CreateMeasurementDcrActions from "../CreateMeasurementDcrActions/CreateMeasurementDcrActions";
import CreateMeasurementDcrForm from "../CreateMeasurementDcrForm/CreateMeasurementDcrForm";
import CreateMeasurementDcrFormControls from "../CreateMeasurementDcrFormControls/CreateMeasurementDcrFormControls";
import CreateMeasurementDcrFormProvider from "../CreateMeasurementDcrFormProvider/CreateMeasurementDcrFormProvider";
import CreateMeasurementDcrModal from "../CreateMeasurementDcrModal/CreateMeasurementDcrModal";

const FORM_ID = "create-measurement-dcr-form";

const CreateMeasurementDcrDialog: React.FC<Omit<ModalProps, "children">> = memo(
  ({ open, onClose, ...props }) => {
    return open ? (
      <CreateMeasurementDcrFormProvider>
        <CreateMeasurementDcrModal onClose={onClose} open={open} {...props}>
          <CreateMeasurementDcrForm id={FORM_ID}>
            <ModalDialog>
              <DialogTitle>Create new data clean room</DialogTitle>
              <Divider />
              <DialogContent>
                <CreateMeasurementDcrFormControls />
              </DialogContent>
              <Divider />
              <DialogActions>
                <CreateMeasurementDcrActions
                  form={FORM_ID}
                  resetButtonProps={{
                    onClick: (event) => onClose?.(event, "closeClick"),
                  }}
                />
              </DialogActions>
            </ModalDialog>
          </CreateMeasurementDcrForm>
        </CreateMeasurementDcrModal>
      </CreateMeasurementDcrFormProvider>
    ) : null;
  }
);
CreateMeasurementDcrDialog.displayName = "CreateMeasurementDcrDialog";

export default CreateMeasurementDcrDialog;
