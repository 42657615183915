import { Box, Typography } from "@mui/joy";
import { styled } from "@mui/material";
import { AccessTokensConstructor } from "components";
import { useDocsLink } from "hooks";

const DocsLink = styled("a")({
  alignItems: "baseline",
  color: "inherit",
});

const AccessTokens: React.FC = () => {
  const docsBaseLink = useDocsLink();
  return (
    <Box
      sx={({ spacing }) => ({ margin: spacing(2, 2.5), overflow: "hidden" })}
    >
      <Typography level="title-md" marginBottom={1}>
        Access tokens
      </Typography>
      <Typography level="body-md" marginBottom={0.5}>
        The access tokens created on this page can be used in our SDKs to
        authenticate with the Decentriq Platform.
      </Typography>
      <Typography level="body-md" marginBottom={2}>
        For more details, please refer to the{" "}
        <DocsLink
          href={`${docsBaseLink}/sdk/getting-started`}
          rel="noreferrer"
          target="_blank"
        >
          Python SDK documentation
        </DocsLink>
        {". "}
      </Typography>
      <AccessTokensConstructor />
    </Box>
  );
};

export default AccessTokens;
