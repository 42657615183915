// TODO: Fix joy migration
import { Box, Tab, TabList, TabPanel, Tabs } from "@mui/joy";
import { memo, type SyntheticEvent, useCallback, useState } from "react";
import { useDatasetWithSchema } from "features/datasets/hooks";
import {
  DatasetPopupInfoTabPanel,
  DatasetPopupSchemaTabPanel,
  DatasetPopupStatisticsTabPanel,
} from "./components";

type DatasetTab = "info" | "schema" | "statistics";

interface DatasetTabsProps {
  datasetHash: string;
  skipFetching: boolean;
}

const DatasetTabs: React.FC<DatasetTabsProps> = ({
  datasetHash,
  skipFetching,
}) => {
  const { dataset, schema } = useDatasetWithSchema({
    datasetHash,
    skipFetching,
  });
  const [selectedTab, setSelectedTab] = useState<DatasetTab>("info");
  const handleChange = useCallback(
    (_: SyntheticEvent<Element, Event>, newTab: DatasetTab) => {
      setSelectedTab(newTab);
    },
    [setSelectedTab]
  );
  return (
    <Tabs onChange={handleChange} value={selectedTab}>
      <Box>
        <TabList>
          <Tab value="info">Details</Tab>
          {schema && <Tab value="schema">Schema</Tab>}
          {dataset?.statistics && <Tab value="statistics">Statistics</Tab>}
        </TabList>
        <Box display="flex" flex={1} flexDirection="column" overflow="auto">
          <TabPanel
            sx={{
              "&:not([hidden])": {
                display: "flex",
                flex: 1,
                flexDirection: "column",
                overflow: "auto",
                p: 0,
              },
            }}
            value="info"
          >
            <Box sx={{ mt: 2 }}>
              <DatasetPopupInfoTabPanel
                datasetHash={datasetHash!}
                visible={true}
              />
            </Box>
          </TabPanel>
          {schema && (
            <TabPanel
              sx={{
                "&:not([hidden])": {
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  overflow: "auto",
                  p: 0,
                },
              }}
              value="schema"
            >
              <Box sx={{ mt: 2 }}>
                <DatasetPopupSchemaTabPanel schema={schema} />
              </Box>
            </TabPanel>
          )}
          {dataset?.statistics && (
            <TabPanel
              sx={{
                "&:not([hidden])": {
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  overflow: "auto",
                  p: 0,
                },
              }}
              value="statistics"
            >
              <DatasetPopupStatisticsTabPanel
                statistics={dataset!.statistics}
              />
            </TabPanel>
          )}
        </Box>
      </Box>
    </Tabs>
  );
};
DatasetTabs.displayName = "DatasetTabs";

export default memo(DatasetTabs);
