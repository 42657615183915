import { Box } from "@mui/joy";
import { memo } from "react";
import { CollaboratingOrganizationsList } from "components";

type CollaboratingOrganizationsProps = {
  organizationId: string;
};

const CollaboratingOrganizations: React.FC<CollaboratingOrganizationsProps> = ({
  organizationId,
}) => {
  return (
    <Box
      sx={{
        alignItems: "flex-start",
        backgroundColor: "common.white",
        display: "flex",
        flex: 1,
        flexDirection: "column",
        height: "100%",
      }}
    >
      <CollaboratingOrganizationsList organizationId={organizationId} />
    </Box>
  );
};

CollaboratingOrganizations.displayName = "CollaboratingOrganizations";

export default memo(CollaboratingOrganizations);
