import { testIds } from "@decentriq/utils";
import { Box, Checkbox, FormControl, FormLabel, Tooltip } from "@mui/joy";
import { Fragment, memo } from "react";
import { RawSupportedFeatures } from "features/MediaInsightsDcr";
import { useCreationWizardConfiguration } from "features/MediaInsightsDcr/contexts";
import { CollaborationTypes } from "models";

const mediaDataRoomFeaturesTooltipMap: Record<
  CollaborationTypes,
  string | null
> = {
  [CollaborationTypes.Insights]:
    "If enabled, advertiser, publisher, agency and observer users will have access to the insights dashboards.",
  [CollaborationTypes.Retargeting]:
    "If enabled, advertiser and agency users will have access to the activation tab where they can see the automatically created remarketing audiences and make them available to the publisher.",
  [CollaborationTypes.Lookalike]:
    "If enabled, advertiser and agency users will have access to the activation tab where they can configure and generate lookalike audiences and make them available to the publisher.",
  [CollaborationTypes.Measurements]:
    "If enabled, advertiser users will be able to request measurement DCRs. This is a separate DCR that does rules-based attribution on brand conversions.",
  [CollaborationTypes.ExclusionTargeting]:
    "If enabled, advertiser and agency users will have access to the activation tab where they can see the automatically created exclusion targeting audiences and make them available to the publisher.",
};

const enabledFeaturesTooltipMap: Record<RawSupportedFeatures, string | null> = {
  [RawSupportedFeatures.ENABLE_ADVERTISER_AUDIENCE_DOWNLOAD]:
    "If enabled, advertiser and agency users will be able to export audiences they created.",
  [RawSupportedFeatures.HIDE_ABSOLUTE_VALUES_FROM_INSIGHTS]:
    "If enabled, absolute audience sizes will be shown in the overlap statistics in the data tab and (if Insights enabled) in the insights statistics in the insights tab.",
  [RawSupportedFeatures.ENABLE_MODEL_PERFORMANCE_EVALUATION]: null,
  [RawSupportedFeatures.ENABLE_EXTENDED_LAL_QUALITY_STATS]: null,
};

const CollaborationTypesEditor: React.FC = () => {
  const {
    allowedCollaborationTypes: allowedFeatures,
    enabledFeatures,
    setEnabledFeatures,
    showAbsoluteAudienceSizes,
    setShowAbsoluteAudienceSizes,
    showAdvertiserAudienceDownload,
    setEnableAdvertiserAudienceDownload,
    enableAdvertiserAudienceDownload,
  } = useCreationWizardConfiguration();
  return (
    <Fragment>
      <FormControl>
        <FormLabel>4. Collaboration types</FormLabel>
        {allowedFeatures.map((feature) => (
          <Tooltip title={mediaDataRoomFeaturesTooltipMap[feature]}>
            <Checkbox
              checked={enabledFeatures.includes(feature)}
              data-testid={`${testIds.mediaInsightsDcr.collaborationConfigurationStepRole.allowedFeaturesHelper}${feature}`}
              key={feature}
              label={feature}
              onChange={() => setEnabledFeatures(feature)}
            />
          </Tooltip>
        ))}
      </FormControl>
      <Box ml={4}>
        <FormControl>
          <Tooltip
            title={
              enabledFeaturesTooltipMap[
                RawSupportedFeatures.HIDE_ABSOLUTE_VALUES_FROM_INSIGHTS
              ]
            }
          >
            <Checkbox
              checked={showAbsoluteAudienceSizes}
              label="Show absolute audience sizes"
              onChange={(event) =>
                setShowAbsoluteAudienceSizes(event.target.checked)
              }
            />
          </Tooltip>
        </FormControl>
        {showAdvertiserAudienceDownload && (
          <FormControl>
            <Tooltip
              title={
                enabledFeaturesTooltipMap[
                  RawSupportedFeatures.ENABLE_ADVERTISER_AUDIENCE_DOWNLOAD
                ]
              }
            >
              <Checkbox
                checked={enableAdvertiserAudienceDownload}
                label="Allow advertiser and agency to export audiences"
                onChange={(event) =>
                  setEnableAdvertiserAudienceDownload(event.target.checked)
                }
              />
            </Tooltip>
          </FormControl>
        )}
      </Box>
    </Fragment>
  );
};

CollaborationTypesEditor.displayName = "CollaborationTypesEditor";

export default memo(CollaborationTypesEditor);
