import { testIds } from "@decentriq/utils";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  ModalDialog,
} from "@mui/joy";
import { useSnackbar } from "notistack";
import { memo, useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { ActivationConfigurationForm } from "features/publisherPortal/components";
import { usePublisherPortal } from "features/publisherPortal/contexts";
import { CollaborationTypes } from "models";
import {
  matchingIdTypeToGqlValues,
  type RawMatchingID,
} from "models/dataRoom/matchingId";
import {
  defaultValues,
  schema,
  type SchemaType,
} from "../CreateActivationConfigurationForm/model";

const FORM_ID = "create-activation-configuration-form";

type CreateActivationConfigurationDialogProps = {
  open: boolean;
  onClose: () => void;
};

const CreateActivationConfigurationDialog: React.FC<
  CreateActivationConfigurationDialogProps
> = ({ open, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { addActivationConfiguration, publisherDisplayConfiguration } =
    usePublisherPortal();
  const [disabled, setDisabled] = useState(false);
  const form = useForm<SchemaType>({
    defaultValues,
    disabled,
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: zodResolver(schema),
  });
  const { handleSubmit, reset, formState } = form;
  const { isSubmitting, isDirty } = formState;
  const publisherDisplayConfigurationId = publisherDisplayConfiguration?.id;
  const handleCreate = useCallback(
    async (values: SchemaType) => {
      const {
        matchingId,
        name,
        advertiserDomains = [],
        publisherParticipants = [],
        dataRoomTypes = [],
        matchAnyDomain = false,
      } = values;
      const [matchingIdFormat, matchingIdHashingAlgorithm] =
        matchingIdTypeToGqlValues[matchingId! as RawMatchingID];
      return await addActivationConfiguration({
        onCompleted: () => {
          enqueueSnackbar(`DCR configuration has been created`);
          onClose();
        },
        onError: (error) => {
          enqueueSnackbar(`Cannot create DCR configuration.`, {
            context: error?.message,
            persist: true,
            variant: "error",
          });
        },
        variables: {
          input: {
            advertiserDomains: matchAnyDomain ? [] : advertiserDomains,
            allowExclusionTargeting: dataRoomTypes.includes(
              CollaborationTypes.ExclusionTargeting
            ),
            allowInsights: dataRoomTypes.includes(CollaborationTypes.Insights),
            allowLookalike: dataRoomTypes.includes(
              CollaborationTypes.Lookalike
            ),
            allowMeasurements: dataRoomTypes.includes(
              CollaborationTypes.Measurements
            ),
            allowRetargeting: dataRoomTypes.includes(
              CollaborationTypes.Retargeting
            ),
            matchAnyDomain,
            matchingIdFormat,
            matchingIdHashingAlgorithm,
            name,
            publisherDisplayConfigurationId: publisherDisplayConfigurationId!,
            publisherParticipants,
          },
        },
      });
    },
    [
      addActivationConfiguration,
      publisherDisplayConfigurationId,
      enqueueSnackbar,
      onClose,
    ]
  );
  useEffect(() => {
    setDisabled(isSubmitting);
  }, [isSubmitting]);
  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open, reset]);
  return (
    <FormProvider {...form}>
      <form
        id={FORM_ID}
        onReset={() => reset()}
        onSubmit={handleSubmit(handleCreate)}
      >
        <Modal
          disableEscapeKeyDown={isDirty}
          onClose={() => {
            if (!isSubmitting) onClose();
          }}
          open={open}
        >
          <ModalDialog>
            <DialogTitle>New DCR configuration</DialogTitle>
            <Divider />
            <DialogContent>
              <ActivationConfigurationForm />
            </DialogContent>
            <Divider />
            <DialogActions>
              <Button
                disabled={disabled}
                form={FORM_ID}
                onClick={() => onClose()}
                type="reset"
              >
                Cancel
              </Button>
              <Button
                color="primary"
                data-testid={
                  testIds.publisherPortal.createActivationConfigurationDialog
                    .submitButton
                }
                disabled={disabled}
                form={FORM_ID}
                loading={isSubmitting}
                loadingPosition="start"
                startDecorator={<FontAwesomeIcon icon={faPlus} />}
                type="submit"
                variant="solid"
              >
                Create
              </Button>
            </DialogActions>
          </ModalDialog>
        </Modal>
      </form>
    </FormProvider>
  );
};

CreateActivationConfigurationDialog.displayName =
  "CreateActivationConfigurationDialog";

export default memo(CreateActivationConfigurationDialog);
