// TODO: Fix joy migration
import { testIds } from "@decentriq/utils";
import { FormControl, FormLabel, Option, Select } from "@mui/joy";
import { memo, useCallback } from "react";
import {
  MediaInsightsDcrCreateOrganizationRole,
  MediaInsightsDcrCreationStep,
  useCreationWizardConfiguration,
  useCreationWizardStepper,
} from "features/MediaInsightsDcr";
import { MediaInsightsDcrCreateMatchingIdChip } from "features/MediaInsightsDcr/components/MediaInsightsDcrCreateWizard/components";
import { matchingIdTypes } from "models/dataRoom/matchingId";
import {
  matchingIdTypeToGqlValues,
  type RawMatchingID,
} from "models/dataRoom/matchingId";

const MatchingIdSelector: React.FC = () => {
  const {
    setMatchingIdFormat,
    setMatchingIdHashingAlgorithm,
    matchingIdFormat,
    matchingIdHashingAlgorithm,
  } = useCreationWizardConfiguration();
  const { organizationRole, activeStep } = useCreationWizardStepper();
  const handleSelectMatchingId = useCallback(
    (event, value) => {
      if (value) {
        const [matchingIdFormat, matchingIdHashingAlgorithm] =
          matchingIdTypeToGqlValues[value as RawMatchingID];
        setMatchingIdFormat(matchingIdFormat);
        setMatchingIdHashingAlgorithm(matchingIdHashingAlgorithm);
      }
    },
    [setMatchingIdFormat, setMatchingIdHashingAlgorithm]
  );
  if (
    organizationRole === MediaInsightsDcrCreateOrganizationRole.ADVERTISER &&
    [
      MediaInsightsDcrCreationStep.SELECT_DATA_PARTNER,
      MediaInsightsDcrCreationStep.SELECT_PUBLISHER,
    ].includes(activeStep)
  ) {
    return <MediaInsightsDcrCreateMatchingIdChip />;
  }
  /// TODO: refactor context state to hold only one value for [RawMatchingID] instead of 2
  const selectedMatchingId =
    Object.entries(matchingIdTypeToGqlValues).find(
      ([_, [format, algorithm]]) =>
        format === matchingIdFormat && algorithm === matchingIdHashingAlgorithm
    )?.[0] ?? " "; // https://github.com/mui/material-ui/issues/32301#issuecomment-1259652206
  return (
    <FormControl sx={{ mb: 2, width: "250px" }}>
      <FormLabel>3. Matching ID</FormLabel>
      <Select
        data-testid={testIds.mediaInsightsDcr.matchingIdSelector.matchingId}
        onChange={handleSelectMatchingId}
        placeholder="None"
        value={selectedMatchingId}
      >
        {matchingIdTypes.map(({ name, value }) => {
          return (
            <Option
              data-testid={`${testIds.mediaInsightsDcr.matchingIdSelector.helper}${value}`}
              key={value}
              value={value}
            >
              {name}
            </Option>
          );
        })}
      </Select>
    </FormControl>
  );
};

MatchingIdSelector.displayName = "MatchingIdSelector";

export default memo(MatchingIdSelector);
