import { testIds } from "@decentriq/utils";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/joy";
import { useBoolean } from "ahooks";
import { memo } from "react";
import UpsertDataPartnerDisplayConfigurationDialog from "../UpsertDataPartnerDisplayConfigurationDialog/UpsertDataPartnerDisplayConfigurationDialog";

const CreateDataPartnerDisplayConfigurationButton: React.FC = () => {
  const [isDialogOpen, { setTrue: openDialog, setFalse: closeDialog }] =
    useBoolean(false);
  return (
    <>
      <Button
        color="primary"
        data-testid={
          testIds.dataPartnerPortal.displayConfiguration.createButton
        }
        onClick={openDialog}
        startDecorator={<FontAwesomeIcon fixedWidth={true} icon={faPlus} />}
        variant="solid"
      >
        Display configuration
      </Button>
      <UpsertDataPartnerDisplayConfigurationDialog
        onClose={closeDialog}
        open={isDialogOpen}
      />
    </>
  );
};

CreateDataPartnerDisplayConfigurationButton.displayName =
  "CreateDataPartnerDisplayConfigurationButton";

export default memo(CreateDataPartnerDisplayConfigurationButton);
