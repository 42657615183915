import { Key } from "@decentriq/utils";
import { Button, Typography } from "@mui/joy";
import { memo, useCallback, useMemo } from "react";
import { KeychainItemKind } from "services/keychain";
import { DatasetsList } from "features/datasets";
import { useGetKeychainItems } from "hooks/keychain/useGetKeychainItems";
import { hashedIdAsArray } from "utils/apicore";

export interface DatasetSelectorProps {
  onSelect: (dataset: { key: Key; manifestHash: string }) => void;
}

const DatasetSelector: React.FC<DatasetSelectorProps> = memo(({ onSelect }) => {
  const { items: keychainItems } = useGetKeychainItems({
    kinds: useMemo(() => [KeychainItemKind.Dataset], []),
  });
  const handleDatasetSelect = useCallback(
    (datasetHash: string) => {
      const keychainItem = keychainItems.find(({ id }) => id === datasetHash);
      if (!keychainItem) return;
      const key = new Key(hashedIdAsArray(keychainItem.value));
      const manifestHash = keychainItem.id;
      onSelect({ key, manifestHash });
    },
    [keychainItems, onSelect]
  );
  const customDatasetActions = useCallback(
    (datasetHash: string) => {
      const isSelectButtonDisabled = !keychainItems.some(
        ({ id }) => id === datasetHash
      );
      return {
        buttons: [
          {
            color: "primary",
            component: Button,
            disabled: isSelectButtonDisabled,
            name: "Select dataset",
            onClick: () => handleDatasetSelect(datasetHash),
            tooltipTitle: isSelectButtonDisabled
              ? "Cannot select the dataset as it does not exist in the keychain"
              : "",
            type: "Select",
            variant: "contained",
          },
        ],
      };
    },
    [handleDatasetSelect, keychainItems]
  );
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
        maxHeight: "100%",
        overflowX: "auto",
      }}
    >
      <Typography
        sx={{
          color: "inherit",
          marginBottom: 0.5,
        }}
      >
        Select from existing datasets
      </Typography>
      <DatasetsList customDatasetActions={customDatasetActions} />
    </div>
  );
});
DatasetSelector.displayName = "DatasetSelector";

export default DatasetSelector;
