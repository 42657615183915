import { useAuth0 } from "@auth0/auth0-react";
import { useDevComputeNodesQuery } from "@decentriq/graphql/dist/hooks";
import { Box, Typography, type TypographyProps } from "@mui/joy";
import { CircularProgress } from "@mui/joy";
import { List, styled } from "@mui/material";
import { usePublishedDataRoom } from "contexts";
import { ComputeNode } from "features/computeNode";
import { ComputeNodesListItem } from "features/computeNodes";
import { DevComputeNodeProvider } from "features/devComputeNodes/contexts/devComputeNode";
import PlaygroundCreator from "../PlaygroundCreator/PlaygroundCreator";

const StyledList = styled(List)(({ theme }) => ({
  padding: theme.spacing(0),
}));

const DevelopmentTypography = styled(Typography)<TypographyProps>(
  ({ theme: { spacing } }) => ({
    marginBottom: spacing(2),
  })
);

interface DevComputeNodesProps {
  dataRoomId: string;
  switchToRequests: () => void;
}

const DevComputeNodes: React.FC<DevComputeNodesProps> = ({ dataRoomId }) => {
  const { testing } = usePublishedDataRoom();
  const { data, loading } = useDevComputeNodesQuery({
    variables: { id: dataRoomId },
  });
  const { user } = useAuth0();
  const computeNodesIds =
    data?.publishedDataRoom?.playgrounds?.nodes
      ?.filter((n) => n.owner.email === user?.email)
      ?.map((n) => ({
        computeNodeId: n.draftNode.id,
        playgroundId: n.id,
      })) || [];
  if (loading) {
    return (
      <Box
        alignItems="center"
        display="flex"
        height="100%"
        justifyContent="center"
        padding="1rem"
      >
        <CircularProgress sx={{ "--CircularProgress-size": "1.5rem" }} />
      </Box>
    );
  }
  return (
    <>
      {testing ? (
        <>
          <Typography level="title-md">Development in Test mode</Typography>
          <DevelopmentTypography level="body-md">
            Use this environment to create and run arbitrary computations based
            on test datasets provisioned to any element of this data clean room,
            regardless of the permissions.
            <br />
            These computations are only visible to you and are not integrated
            into the data clean room unless you leave the Test mode and create a
            new request.
          </DevelopmentTypography>
        </>
      ) : (
        <>
          <Typography level="title-md">Development environment</Typography>
          <DevelopmentTypography level="body-md">
            Use this environment to create and run arbitrary computations based
            on data and results you already have permission for.
            <br />
            These computations are only visible to you and are not integrated
            into the data clean room unless you create a request.
          </DevelopmentTypography>
        </>
      )}
      <PlaygroundCreator dataRoomId={dataRoomId} />
      {computeNodesIds.length > 0 && (
        <Box sx={{ overflow: "auto" }}>
          <StyledList>
            {computeNodesIds.map(({ computeNodeId, playgroundId }) => {
              return (
                <ComputeNodesListItem draggable={false} key={computeNodeId}>
                  <DevComputeNodeProvider value={{ playgroundId }}>
                    <ComputeNode computeNodeId={computeNodeId} />
                  </DevComputeNodeProvider>
                </ComputeNodesListItem>
              );
            })}
          </StyledList>
        </Box>
      )}
    </>
  );
};

export default DevComputeNodes;
