// TODO: Fix joy migration
import { useCreateOrganizationMutation } from "@decentriq/graphql/dist/hooks";
import {
  OrganizationFragment,
  OrganizationState,
} from "@decentriq/graphql/dist/types";
import { FormControl, FormLabel, Input, Option, Select, Stack } from "@mui/joy";
import capitalize from "lodash/capitalize";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AdminDialog } from "components";
import { CommonSnackbarOrigin, useGeneralSnackbar } from "hooks";

interface OrganizationsAddDialogProps {
  open: boolean;
  onCancel: () => void;
}

const OrganizationsAddDialog: React.FC<OrganizationsAddDialogProps> = ({
  open,
  onCancel = () => {},
}) => {
  const [organizationName, setOrganizationName] = useState<string>("");
  const [organizationLicense, setOrganizationLicense] = useState<
    OrganizationState | undefined
  >();
  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: CommonSnackbarOrigin.ADMIN,
  });
  const navigate = useNavigate();
  const [createOrganization, { loading }] = useCreateOrganizationMutation({
    onCompleted: ({ organization = {} }) => {
      enqueueSnackbar(`Organization has been successfully created.`);
      onCancel();
      navigate(`/admin/organizations/${organization?.create?.id}`);
    },
    onError: (error) =>
      enqueueSnackbar(`Organization could not be created.`, {
        context: error?.message,
        persist: true,
        variant: "error",
      }),
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          organizations: (existing = {}) => {
            const organizationRef = cache.writeFragment({
              data: data?.organization?.create,
              fragment: OrganizationFragment,
            });
            return {
              ...existing,
              nodes: [organizationRef, ...(existing?.nodes || [])],
            };
          },
        },
      });
    },
    variables: {
      input: {
        name: organizationName,
        state: organizationLicense!,
      },
    },
  });
  useEffect(() => {
    if (!open) {
      setOrganizationName("");
      setOrganizationLicense(undefined);
    }
  }, [open, setOrganizationName, setOrganizationLicense]);
  return (
    <AdminDialog
      disabled={!organizationName || !organizationLicense}
      loading={loading}
      onClose={onCancel}
      onConfirm={createOrganization}
      open={open}
      title="Create organization"
    >
      <Stack>
        <FormControl>
          <FormLabel>Name</FormLabel>
          <Input
            onChange={({ target }) => setOrganizationName(target?.value || "")}
            placeholder={`e.g. "Acme Inc."`}
            value={organizationName}
          />
        </FormControl>
        <FormControl>
          <FormLabel>License status</FormLabel>
          <Select
            onChange={(event, value) =>
              setOrganizationLicense(value as OrganizationState)
            }
            placeholder="None"
            renderValue={(option) =>
              capitalize(option?.value) || "License status"
            }
          >
            {Object.values(OrganizationState).map((state) => (
              <Option key={state} value={state}>
                {capitalize(state)}
              </Option>
            ))}
          </Select>
        </FormControl>
      </Stack>
    </AdminDialog>
  );
};

export default OrganizationsAddDialog;
