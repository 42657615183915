import { DqTable, InfoTooltip } from "@decentriq/components";
import { useOrganizationDataRoomsQuery } from "@decentriq/graphql/dist/hooks";
import {
  type OrganizationDataRoomFilter,
  type OrganizationDataRoomsQuery,
} from "@decentriq/graphql/dist/types";
import { faCheck } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/joy";
import { format } from "date-fns";
import { isNil, omitBy } from "lodash";
import { type MRT_ColumnDef } from "material-react-table";
import { memo, useCallback, useMemo, useState } from "react";
import { AdminMonthPicker } from "components";
import OrganizationDataRoomDetailsDialog from "../OrganizationDataRoomDetailsDialog/OrganizationDataRoomDetailsDialog";
import CollaboratingOrganizationSelect from "./CollaboratingOrganizationSelect/CollaboratingOrganizationSelect";

type OrganizationDataRoomsListProps = {
  organizationId: string;
};

type OrganizationDataRoomsListItem = Omit<
  OrganizationDataRoomsQuery["organization"]["participatingDataRooms"]["nodes"][number],
  "__typename"
>;

const OrganizationDataRoomsList: React.FC<OrganizationDataRoomsListProps> = ({
  organizationId,
}) => {
  const [filter, setQueryFilter] = useState<OrganizationDataRoomFilter>({});
  const handleSetQueryFilter = useCallback(
    (filter: OrganizationDataRoomFilter) => {
      setQueryFilter((existingFilter) =>
        omitBy({ ...existingFilter, ...filter }, isNil)
      );
    },
    []
  );
  const [selectedDataRoomId, selectDataRoomId] = useState<string | null>(null);
  const { data } = useOrganizationDataRoomsQuery({
    variables: { filter, organizationId },
  });
  const organizationDataRooms = useMemo(
    () => data?.organization?.participatingDataRooms?.nodes || [],
    [data]
  );
  const organizationDataRoomsColumns = useMemo<
    MRT_ColumnDef<OrganizationDataRoomsListItem>[]
  >(
    () => [
      {
        accessorKey: "title",
        header: "Name",
        id: "title",
        maxSize: 200,
        minSize: 100,
      },
      {
        Cell: ({ cell }) => {
          const createdAt = cell.getValue();
          return createdAt
            ? format(new Date(createdAt as string), "dd.MM.yyy, HH:mm")
            : "—";
        },
        accessorKey: "createdAt",
        header: "Published at",
        id: "createdAt",
        maxSize: 130,
      },
      {
        accessorFn: (row) => row?.owner?.email || "—",
        accessorKey: "owner.email",
        header: "DCR Creator",
        id: "owner",
        minSize: 250,
      },
      {
        accessorFn: (row) => row?.owner?.organization?.name || "—",
        header: "DCR Creator organization",
        id: "createdByOrganization",
      },
      {
        Cell: ({ cell }) =>
          cell.getValue<boolean>() ? (
            <FontAwesomeIcon fixedWidth={true} icon={faCheck} />
          ) : null,
        Header: ({ column }) => (
          <div title="">
            {column.columnDef.header}
            <InfoTooltip tooltip="A data clean room is activated, once either data has been provisioned or a computation has been run by an external user (i.e. from an organisation that is not the DCR Creator’s)." />
          </div>
        ),
        accessorKey: "isActive",
        header: "Is Active",
        id: "isActive",
        maxSize: 110,
      },
      {
        Cell: ({ cell }) => {
          const deactivatedAt = cell.getValue();
          return deactivatedAt
            ? format(new Date(deactivatedAt as string), "dd.MM.yyy, HH:mm")
            : "—";
        },
        accessorKey: "deactivatedAt",
        header: "Deactivated at",
        id: "deactivatedAt",
      },
    ],
    []
  );
  return (
    <>
      <DqTable
        columns={organizationDataRoomsColumns}
        data={organizationDataRooms}
        enableGlobalFilter={true}
        enableSorting={true}
        enableTopToolbar={true}
        initialState={{
          showGlobalFilter: true,
          sorting: [{ desc: true, id: "createdAt" }],
        }}
        localization={{
          noRecordsToDisplay: "No data clean rooms found",
        }}
        muiSearchTextFieldProps={{
          placeholder: "Search data clean rooms",
        }}
        muiTableBodyRowProps={({ row }) => ({
          onClick: () => selectDataRoomId(row?.original?.id),
          sx: {
            "&:hover td:after": {
              backgroundColor: "primary.light",
              content: '""',
              height: "100%",
              left: 0,
              opacity: 0.125,
              position: "absolute",
              top: 0,
              width: "100%",
              zIndex: -1,
            },
            cursor: "pointer",
          },
        })}
        muiTablePaperProps={{
          sx: {
            display: "flex",
            flex: 1,
            flexDirection: "column",
            height: "100%",
            overflow: "hidden",
            width: "100%",
          },
        }}
        muiTopToolbarProps={{
          sx: (theme) => ({
            flex: "0 0 auto",
            minHeight: theme.spacing(8),
            // eslint-disable-next-line sort-keys-fix/sort-keys-fix
            borderBottom: "1px solid",
            borderColor: "divider",
          }),
        }}
        renderTopToolbarCustomActions={() => {
          return (
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
              }}
            >
              <AdminMonthPicker setQueryFilter={handleSetQueryFilter} />
              <CollaboratingOrganizationSelect
                organizationId={organizationId}
                setQueryFilter={handleSetQueryFilter}
              />
            </Box>
          );
        }}
      />
      {selectedDataRoomId && (
        <OrganizationDataRoomDetailsDialog
          dataRoomId={selectedDataRoomId}
          onClose={() => selectDataRoomId(null)}
          open={!!selectedDataRoomId}
        />
      )}
    </>
  );
};

OrganizationDataRoomsList.displayName = "OrganizationDataRoomsList";

export default memo(OrganizationDataRoomsList);
