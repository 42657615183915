import { type IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@mui/joy";
import { memo } from "react";

export const DetailsGridIcon = memo(({ icon }: { icon: IconProp }) => (
  <FontAwesomeIcon fixedWidth={true} icon={icon} />
));

export const DetailsGridLabel = memo(({ label }: { label: string }) => (
  <Typography component="div" level="body-sm">
    <strong>{label}</strong>
  </Typography>
));

export const DetailsGridValue = memo(
  ({ value, icon }: { value: string; icon?: IconProp }) => (
    <Box sx={{ alignItems: "center", display: "flex", gap: 1 }}>
      {icon ? <DetailsGridIcon icon={icon} /> : null}
      <Typography level="body-sm">{value}</Typography>
    </Box>
  )
);
