import { FormControl, FormLabel, Option, Select } from "@mui/joy";
import { memo } from "react";
import { Controller, useFormContext } from "react-hook-form";

interface AttributionItemProps {
  options: { label: string; value: string | number; disabled?: boolean }[];
  attributionLabel: string;
  fieldName: string;
  customOptions?: JSX.Element[];
}

const AttributionItem: React.FC<AttributionItemProps> = memo(
  ({ options, attributionLabel, fieldName }) => {
    const { control } = useFormContext();
    return (
      <Controller
        control={control}
        name={fieldName}
        render={({ field, fieldState }) => {
          const { error } = fieldState;
          return (
            <FormControl
              disabled={field.disabled}
              error={Boolean(error)}
              required={true}
            >
              <FormLabel>{attributionLabel}</FormLabel>
              <Select
                {...field}
                onChange={(event, value) => field.onChange(value)}
              >
                {options.map(({ label, value, disabled = false }) => {
                  return (
                    <Option
                      disabled={disabled}
                      key={`${value}-${label}`}
                      value={value}
                    >
                      {label}
                    </Option>
                  );
                })}
              </Select>
            </FormControl>
          );
        }}
      />
    );
  }
);
AttributionItem.displayName = "AttributionItem";

export default AttributionItem;
