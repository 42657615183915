// TODO: Fix joy migration
import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  ModalDialog,
  Typography,
} from "@mui/joy";
import { memo, useCallback, useState } from "react";
import { useDataNodeActions } from "features/dataNodes/containers/DataNodes/DataNodesActionsWrapper";
import { useDeleteDataset } from "features/datasets";

export type DataNodeChangeOutcomeType = "deleted" | "changed" | "columnAdded";

interface DataNodeWithTestDataChangedDialogProps {
  dataNodeId: string;
  manifestHash: string;
  dataNodeName: string;
  actionType: DataNodeChangeOutcomeType;
  onCancel: () => void;
  open: boolean;
  columnToDeleteId?: string;
}

const DataNodeWithTestDataChangedDialog: React.FC<
  DataNodeWithTestDataChangedDialogProps
> = ({
  dataNodeId,
  manifestHash,
  dataNodeName,
  open,
  onCancel,
  actionType,
  columnToDeleteId,
}) => {
  const { handleTableColumnDelete } = useDataNodeActions();
  const [columnDeleting, setColumnDeleting] = useState<boolean>(false);
  const [deleteDataset, deleting] = useDeleteDataset({
    manifestHash,
    testing: { dataNodeId },
  });
  const handleDeleteColumn = useCallback(async () => {
    if (!columnToDeleteId) {
      return;
    }
    try {
      setColumnDeleting(true);
      await handleTableColumnDelete(columnToDeleteId);
      onCancel();
    } finally {
      setColumnDeleting(false);
    }
  }, [setColumnDeleting, handleTableColumnDelete, columnToDeleteId, onCancel]);
  return (
    <Modal open={open}>
      <ModalDialog role="alertdialog">
        <DialogTitle>
          <Box sx={{ color: "error.main", mb: 1, textAlign: "center" }}>
            <FontAwesomeIcon
              fixedWidth={true}
              icon={faExclamationTriangle}
              size="4x"
            />
          </Box>
          <Typography
            align="center"
            level="title-md"
            sx={{ textWrap: "balance" }}
          >
            <strong>
              {actionType === "changed" && (
                <Typography textAlign="center">
                  The table schema for <strong>{dataNodeName}</strong> has
                  changed.
                  <br />
                  Would you like to delete the provisioned test data?
                </Typography>
              )}
              {actionType === "deleted" && (
                <Typography textAlign="center">
                  The table <strong>{dataNodeName}</strong> was deleted.
                  <br />
                  Would you like to delete the provisioned test data too?
                </Typography>
              )}
            </strong>
          </Typography>
        </DialogTitle>
        {actionType === "columnAdded" && (
          <DialogContent>
            <Typography
              align="center"
              level="body-sm"
              sx={{ textWrap: "balance" }}
            >
              A new column has been added to the <strong>{dataNodeName}</strong>
              , and since a test dataset is provisioned, it will have fewer
              columns than the table schema.
            </Typography>
          </DialogContent>
        )}
        <Divider />
        <DialogActions>
          {actionType === "columnAdded" ? (
            <Button loading={columnDeleting} onClick={handleDeleteColumn}>
              Undo column creation
            </Button>
          ) : (
            <Button disabled={deleting} onClick={onCancel}>
              No
            </Button>
          )}
          <Button
            color="danger"
            loading={deleting}
            onClick={() =>
              deleteDataset().then((deleted) => {
                if (deleted) {
                  onCancel();
                }
              })
            }
            startDecorator={<FontAwesomeIcon icon={faExclamationTriangle} />}
            variant="solid"
          >
            Delete
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

DataNodeWithTestDataChangedDialog.displayName =
  "DataNodeWithTestDataChangedDialog";

export default memo(DataNodeWithTestDataChangedDialog);
