import { createDefaultTheme as createDecentriqTheme } from "@decentriq/theme";
import { faPlus, faTrashAlt, faXmark } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, IconButton, Tooltip } from "@mui/joy";
import { createTheme } from "@mui/material";
import type {
  BuilderProps,
  ButtonProps,
  Config,
  ImmutableTree,
  JsonGroup,
} from "@react-awesome-query-builder/mui";
import {
  Builder,
  MuiConfig,
  Query,
  Utils,
} from "@react-awesome-query-builder/mui";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { mapErrorToGeneralSnackbar, useDataRoomSnackbar } from "hooks";
import "@react-awesome-query-builder/mui/css/styles.css";
import "./QueryBuilder.css";

const defaultRenderButton = MuiConfig.settings.renderButton;

const renderButton = (props: ButtonProps) => {
  const { type, label, onClick, readonly: readOnly } = props;
  if (type === "delRule") {
    return (
      <Tooltip placement="top" title="Delete column condition">
        <IconButton color="danger" disabled={readOnly} onClick={onClick}>
          <FontAwesomeIcon fixedWidth={true} icon={faXmark} />
        </IconButton>
      </Tooltip>
    );
  }
  if (type === "delGroup") {
    return (
      <Tooltip placement="top" title="Delete group">
        <IconButton color="danger" disabled={readOnly} onClick={onClick}>
          <FontAwesomeIcon fixedWidth={true} icon={faTrashAlt} />
        </IconButton>
      </Tooltip>
    );
  }
  if (type === "addRule" || type === "addGroup") {
    return (
      <Button
        disabled={readOnly}
        onClick={onClick}
        startDecorator={<FontAwesomeIcon fixedWidth={true} icon={faPlus} />}
      >
        {label}
      </Button>
    );
  }
  // @ts-ignore
  return defaultRenderButton(props);
};

export type QueryBuilderProps = {
  query: Object;
  dataSource1NodeId: string;
  dataSource1Columns: any[];
  dataSource2NodeId: string;
  dataSource2Columns: any[];
  onChange?: (query: Object) => void;
  readOnly?: boolean;
};

const QueryBuilder: React.FC<QueryBuilderProps> = ({
  query,
  dataSource1NodeId,
  dataSource1Columns,
  dataSource2NodeId,
  dataSource2Columns,
  onChange: onChangeProp,
  readOnly = false,
}) => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const config: Config = useMemo(() => {
    const config: Config = { ...MuiConfig, fields: {} };
    if (dataSource1NodeId) {
      config.fields[dataSource1NodeId] = {
        hideForCompare: true,
        label: dataSource1NodeId,
        subfields: {
          ...dataSource1Columns.reduce((acc, { name, dataType }) => {
            acc[name] = {
              label: name,
              operators: ["select_equals"],
              preferWidgets: ["select"],
              type: "select",
              valueSources: ["field"],
            };
            return acc;
          }, {}),
        },
        type: "!struct",
      };
    }
    if (dataSource2NodeId) {
      config.fields[dataSource2NodeId] = {
        hideForSelect: true,
        label: dataSource2NodeId,
        subfields: {
          ...dataSource2Columns.reduce((acc, { name, dataType }) => {
            acc[name] = {
              label: name,
              operators: ["select_equals"],
              preferWidgets: ["select"],
              type: "select",
              valueSources: ["field"],
            };
            return acc;
          }, {}),
        },
        type: "!struct",
      };
    }
    config.operators.select_equals.label = "equals";
    config.settings.removeEmptyGroupsOnLoad = false;
    config.settings.removeIncompleteRulesOnLoad = false;
    config.settings.showNot = false;
    config.settings.maxNesting = 2;
    config.settings.forceShowConj = true;
    // @ts-ignore
    config.settings.customFieldSelectProps = {
      // @ts-ignore
      ...config.settings.customFieldSelectProps,
      showSearch: false,
    };
    // @ts-ignore
    config.settings.defaultGroupConjunction = "AND";
    // @ts-ignore
    config.settings.defaultConjunction = "AND";
    // @ts-ignore
    config.settings.groupActionsPosition = "bottomLeft";
    config.settings.canReorder = false;
    config.settings.canRegroup = false;
    config.settings.addRuleLabel = "Column condition";
    config.settings.addGroupLabel = "Group";
    // @ts-ignore
    config.settings.renderButton = renderButton;
    if (readOnly) {
      config.settings.immutableGroupsMode = true;
      config.settings.immutableFieldsMode = true;
      config.settings.immutableOpsMode = true;
      config.settings.immutableValuesMode = true;
    }
    const theme = createTheme(createDecentriqTheme({ mode: "light" }), {
      components: {
        MuiListSubheader: {
          styleOverrides: {
            root: {
              display: "none",
            },
          },
        },
      },
    });
    config.settings.theme = {
      mui: theme,
    };
    config.widgets.select = {
      ...config.widgets.select,
      customProps: {
        ...config.widgets.select.customProps,
        showSearch: false,
      },
    };
    config.widgets.field = {
      ...config.widgets.field,
      customProps: {
        ...config.widgets.field.customProps,
        showSearch: false,
      },
    };
    return config;
  }, [
    dataSource1Columns,
    dataSource1NodeId,
    dataSource2Columns,
    dataSource2NodeId,
    readOnly,
  ]);
  const tree: ImmutableTree = useMemo(() => {
    const defaultTree: JsonGroup = {
      /* eslint-disable sort-keys-fix/sort-keys-fix */
      id: Utils.uuid(),
      type: "group",
      properties: { conjunction: "OR" },
      children1: [
        {
          id: Utils.uuid(),
          type: "group",
          properties: { conjunction: "AND" },
          children1: [
            {
              id: Utils.uuid(),
              properties: {
                field: undefined,
                operator: undefined,
                operatorOptions: undefined,
                value: [],
                valueSrc: [],
              },
              type: "rule",
            },
          ],
        },
      ],
    };
    const tree = Utils.sanitizeTree(
      Utils.loadFromJsonLogic(query, config) || Utils.loadTree(defaultTree),
      config
    ).fixedTree;
    return tree;
  }, [config, query]);
  const [state, setState] = useState({ config, tree });
  useEffect(() => {
    setState((prevState) => ({ ...prevState, config }));
  }, [config]);
  useEffect(() => {
    setState((prevState) => ({ ...prevState, tree }));
  }, [tree]);
  useEffect(() => {
    if (query) {
      try {
        const tree = Utils.loadFromJsonLogic(query, state.config);
        if (tree) {
          setState((prevState) => ({
            ...prevState,
            tree: Utils.sanitizeTree(tree, state.config).fixedTree,
          }));
        }
      } catch (error) {
        enqueueSnackbar(
          ...mapErrorToGeneralSnackbar(error, "Failed to get result")
        );
      }
    }
  }, [state.config, query, enqueueSnackbar]);
  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      config: {
        ...prevState.config,
        settings: {
          ...prevState.config.settings,
          immutableFieldsMode: readOnly,
          immutableGroupsMode: readOnly,
          immutableOpsMode: readOnly,
          immutableValuesMode: readOnly,
        },
      },
    }));
  }, [readOnly]);
  const onChange = useCallback(
    (tree: ImmutableTree, config: Config) => {
      setState((prevState) => ({ ...prevState, config, tree }));
      const query = Utils.jsonLogicFormat(tree, config);
      if (query.errors && query.errors.length === 0 && query.logic) {
        onChangeProp?.(query.logic);
      }
    },
    [onChangeProp]
  );
  const renderBuilder = useCallback(
    (props: BuilderProps) => (
      <div className="query-builder-container">
        <div className="query-builder">
          <Builder {...props} />
        </div>
      </div>
    ),
    []
  );
  return (
    <Query
      {...state.config}
      onChange={onChange}
      renderBuilder={renderBuilder}
      value={state.tree}
    />
  );
};

export default QueryBuilder;
