import { useBoolean } from "@decentriq/hooks";
import { faFileExport } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  ModalDialog,
} from "@mui/joy";
import { Fragment } from "react";
import {
  DeleteDatasetButton,
  useDatasetPopupConfig,
  useDatasetWithSchema,
} from "features/datasets";
import DatasetExportDialog from "../DatasetExportDialog/DatasetExportDialog";
import DatasetTabs from "../DatasetTabs/DatasetTabs";

interface DatasetPopupProps {
  open: boolean;
  isDatasetOwner?: boolean;
  onDismiss: () => void;
  datasetHash?: string;
  testing?: {
    dataNodeId: string;
  };
  withActions?: boolean;
}

const DatasetPopup = ({
  datasetHash,
  open,
  onDismiss,
  testing,
  withActions = true,
}: DatasetPopupProps) => {
  const [
    isDatasetExportDialogOpen,
    { setTrue: openDatasetExportDialog, setFalse: closeDatasetExportDialog },
  ] = useBoolean(false);
  const { disableDelete, disableExport } = useDatasetPopupConfig();
  const { dataset } = useDatasetWithSchema({
    datasetHash,
    skipFetching: !open,
  });
  if (!dataset || !datasetHash) return <></>;
  return (
    <Modal onClose={onDismiss} open={open}>
      <ModalDialog>
        <DialogTitle>{dataset.name || dataset.id?.slice(0, 8)}</DialogTitle>
        <Divider />
        <DialogContent>
          <DatasetTabs datasetHash={datasetHash} skipFetching={!open} />
        </DialogContent>
        {withActions && (
          <Fragment>
            <Divider />
            <DialogActions>
              <Box sx={{ alignItems: "center", display: "flex" }}>
                {dataset?.isOwner && !disableDelete ? (
                  <DeleteDatasetButton
                    closeDatasetPopup={onDismiss}
                    datasetManifestHash={dataset.manifestHash || ""}
                    testing={testing}
                  />
                ) : null}
                {dataset?.isOwner && !disableExport && (
                  <Fragment>
                    <Button
                      color="primary"
                      onClick={openDatasetExportDialog}
                      startDecorator={
                        <FontAwesomeIcon
                          fixedWidth={true}
                          icon={faFileExport}
                        />
                      }
                      variant="solid"
                    >
                      Export dataset
                    </Button>
                    <DatasetExportDialog
                      datasetManifestHash={datasetHash}
                      onClose={closeDatasetExportDialog}
                      open={isDatasetExportDialogOpen}
                    />
                  </Fragment>
                )}
              </Box>
              <Button onClick={onDismiss}>Close</Button>
            </DialogActions>
          </Fragment>
        )}
      </ModalDialog>
    </Modal>
  );
};

export default DatasetPopup;
