import { faCaretDown, faCaretRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@mui/joy";
import { Collapse } from "@mui/material";

interface DataNodeItemProps {
  title: string;
  toggleCollapse?: () => void;
  isCollapsed: boolean;
  actions: React.ReactNode;
  content: React.ReactNode;
  description?: React.ReactNode;
}

const DataNodeItem: React.FC<DataNodeItemProps> = ({
  description,
  title,
  toggleCollapse,
  actions,
  content,
  ...restProps
}) => {
  const collapsable: boolean = Boolean(toggleCollapse);
  const isCollapsed = collapsable && restProps.isCollapsed;
  return (
    <Box
      sx={{
        border: "1px solid",
        borderColor:
          "var(--variant-outlinedBorder, var(--joy-palette-neutral-outlinedBorder, var(--joy-palette-neutral-300, #CDD7E1)))",
        borderRadius: "var(--joy-radius-sm, 6px)",
        boxShadow:
          "var(--joy-shadowRing, 0 0 #000),0px 1px 2px 0px rgba(var(--joy-shadowChannel, 21 21 21) / var(--joy-shadowOpacity, 0.08))",
        padding: 1,
      }}
    >
      <Box sx={{ alignItems: "center", display: "flex", gap: 1 }}>
        <Box
          onClick={toggleCollapse}
          sx={{
            alignItems: "center",
            cursor: "pointer",
            display: "flex",
            flex: 1,
            gap: 1,
          }}
        >
          {collapsable && (
            <FontAwesomeIcon
              fixedWidth={true}
              icon={isCollapsed ? faCaretRight : faCaretDown}
            />
          )}
          <Typography level="title-md" sx={{ flex: 1 }}>
            {title}
            {description && (
              <Typography
                component="span"
                level="body-sm"
                sx={{ marginLeft: "0.75rem" }}
              >
                {description}
              </Typography>
            )}
          </Typography>
        </Box>
        {actions}
      </Box>
      {collapsable ? (
        <Collapse
          in={!isCollapsed}
          style={{
            marginLeft: 6,
            marginRight: 6,
            marginTop: 10,
          }}
          timeout="auto"
          unmountOnExit={true}
        >
          {content}
        </Collapse>
      ) : (
        <Box>{content}</Box>
      )}
    </Box>
  );
};

export default DataNodeItem;
