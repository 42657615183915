// TODO: Fix joy migration
import { type SizeProp } from "@fortawesome/fontawesome-svg-core";
import {
  faChartLine,
  faRadar,
  faText,
  type IconDefinition,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  FormLabel,
  ListItemDecorator,
  Tab,
  TabList,
  Tabs,
} from "@mui/joy";
import { memo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  type OverlapInsightsChartTypes,
  useMediaDataRoomOverlapInsights,
} from "features/MediaInsightsDcr";

const WITH_TAG_CLOUD = false;

type ChartTypeButton = {
  icon: IconDefinition | null;
  isVisible: boolean;
  label: string;
  size: SizeProp;
  value: OverlapInsightsChartTypes;
};

const CHART_TYPES_BUTTONS: ChartTypeButton[] = [
  {
    icon: faRadar,
    isVisible: true,
    label: "Radar",
    size: "sm",
    value: "RADAR",
  },
  {
    icon: faChartLine,
    isVisible: true,
    label: "Line",
    size: "sm",
    value: "LINE",
  },
  {
    icon: faText,
    isVisible: WITH_TAG_CLOUD,
    label: "Tag Cloud",
    size: "sm",
    value: "TAG_CLOUD",
  },
];

const MediaDataRoomInsightsChartTypeSelector: React.FC = () => {
  const { isEmptyAggregation } = useMediaDataRoomOverlapInsights();
  const { control: formControl } = useFormContext();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <FormLabel sx={{ fontSize: "12px", marginBottom: 0.5 }}>
        Chart Type
      </FormLabel>
      <Controller
        control={formControl}
        name="chartType"
        render={({ field: { onChange = () => {}, value } = {} }) => {
          return (
            <Tabs
              onChange={(_, newValue: OverlapInsightsChartTypes) =>
                onChange(newValue)
              }
              value={value}
            >
              <TabList>
                {CHART_TYPES_BUTTONS.map(
                  (
                    {
                      label,
                      value,
                      icon = null,
                      size = "sm",
                      isVisible = true,
                    },
                    index
                  ) =>
                    isVisible && (
                      <Tab
                        disabled={isEmptyAggregation}
                        key={index}
                        value={value}
                      >
                        <ListItemDecorator>
                          <FontAwesomeIcon icon={icon!} size={size} />
                        </ListItemDecorator>
                        {label}
                      </Tab>
                    )
                )}
              </TabList>
            </Tabs>
          );
        }}
      />
    </Box>
  );
};

MediaDataRoomInsightsChartTypeSelector.displayName =
  "MediaDataRoomInsightsChartTypeSelector";

export default memo(MediaDataRoomInsightsChartTypeSelector);
