import { useDatasetByHashQuery } from "@decentriq/graphql/dist/hooks";
import { testIds } from "@decentriq/utils";
import { faUpload } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, CircularProgress, Typography } from "@mui/joy";
import { useState } from "react";
import { usePublishedDataRoom } from "contexts";
import { useDeleteDataset } from "features/datasets";
import { type DataIngestionDestination, type DataType } from "models";
import DatasetDeletionDialog, {
  DatasetDeletionMode,
} from "../DatasetDeletionDialog/DatasetDeletionDialog";
import { useDataNodeConstructorParams } from "./DataNodeConstructorParamsWrapper";
import DataNodeDeprovisionButton from "./DataNodeDeprovisionButton";
import DataNodeReprovisionButton from "./DataNodeReprovisionButton";

interface DataNodeActionsContainerProps {
  centered?: boolean;
}

export const DataNodeActionsContainer: React.FC<
  React.PropsWithChildren<DataNodeActionsContainerProps>
> = ({ children, centered = true }) => (
  <div
    onClick={(e) => e.stopPropagation()}
    style={
      !centered
        ? {}
        : { alignItems: "center", alignSelf: "center", display: "flex" }
    }
  >
    <Box
      sx={(theme) => ({
        alignItems: "center",
        display: "flex",
        gap: 1,
        margin: theme.spacing(0, 1),
      })}
    >
      {children}
    </Box>
  </div>
);

interface DataNodeActionsProps {
  id: string;
  dataType: DataType;
  datasetHash?: string;
  hasValidationError?: boolean;
  isLoading: boolean;
  isStatusCheckingFailed?: boolean;
  onUpload: () => void;
  onDeprovision: () => Promise<void>;
  openValidationReport?: () => void;
  testing: boolean;
  ingestionDestination?: DataIngestionDestination;
}

const DataNodeActions: React.FC<DataNodeActionsProps> = ({
  id,
  dataType,
  isLoading,
  hasValidationError,
  isStatusCheckingFailed,
  onDeprovision,
  onUpload,
  openValidationReport,
  datasetHash,
  testing,
  ingestionDestination = "dataRoom",
}) => {
  const { isStopped, isDeactivated } = usePublishedDataRoom();
  const [handleDeleteDataset] = useDeleteDataset({
    manifestHash: datasetHash || "",
    testing: testing ? { dataNodeId: id } : undefined,
  });
  const { withDataDeletion, withUploading } = useDataNodeConstructorParams();
  const [datasetDeletionModalFor, setDatasetDeletionModalFor] = useState<
    DatasetDeletionMode | undefined
  >(undefined);
  const [deleting, setDeleting] = useState(false);
  const { data } = useDatasetByHashQuery({
    skip: !datasetHash,
    variables: {
      manifestHash: datasetHash,
    },
  });
  const lastDatasetData = data?.datasetByManifestHash;
  if (isLoading) {
    return (
      <Box
        style={{ alignItems: "center", display: "flex", marginRight: "16px" }}
      >
        <CircularProgress
          sx={{ "--CircularProgress-size": "16px", margin: "8px" }}
        />
      </Box>
    );
  }
  const dataIngestionLabel: string = `${testing ? "test " : ""}${
    dataType === "table" ? "dataset" : "file"
  }`;
  if (isStatusCheckingFailed) {
    return (
      <Box
        style={{
          alignItems: "center",
          display: "flex",
          height: "100%",
          marginRight: "16px",
        }}
      >
        <Typography level="body-sm">
          Unable to determine {dataIngestionLabel}
        </Typography>
      </Box>
    );
  }
  const hasUploadedData = Boolean(lastDatasetData?.createdAt);
  const isDatasetOwner = hasUploadedData && lastDatasetData?.isOwner;
  return (
    <>
      <DataNodeActionsContainer centered={!hasUploadedData}>
        <>
          {hasUploadedData && isDatasetOwner && withDataDeletion && (
            <DataNodeDeprovisionButton
              dataType={dataType}
              datasetHash={lastDatasetData?.manifestHash}
              hasValidationError={hasValidationError}
              id={id}
              ingestionDestination={ingestionDestination}
              label={dataIngestionLabel}
              onDelete={(replace) =>
                setDatasetDeletionModalFor(
                  replace
                    ? DatasetDeletionMode.replace
                    : DatasetDeletionMode.delete
                )
              }
              onDeprovision={onDeprovision}
              openValidationReport={openValidationReport}
              testing={testing}
            />
          )}
          {hasUploadedData &&
            !isDatasetOwner &&
            !isStopped &&
            !isDeactivated && (
              <DataNodeReprovisionButton
                dataType={dataType}
                datasetHash={lastDatasetData?.manifestHash}
                id={id}
                label={dataIngestionLabel}
                onUpload={onUpload}
                testing={testing}
              />
            )}
          {!hasUploadedData && withUploading && (
            <Button
              color={testing ? "secondary" : "primary"}
              data-testid={`${testIds.dataLabs.dataNodeActions.helper}${dataIngestionLabel}${id}`}
              onClick={onUpload}
              startDecorator={
                <FontAwesomeIcon fixedWidth={true} icon={faUpload} />
              }
              variant="solid"
            >
              Provision {dataIngestionLabel}
            </Button>
          )}
        </>
      </DataNodeActionsContainer>
      <DatasetDeletionDialog
        dataType={dataType}
        loading={deleting}
        mode={datasetDeletionModalFor!}
        onCancel={() => setDatasetDeletionModalFor(undefined)}
        onConfirm={async () => {
          try {
            setDeleting(true);
            if (datasetDeletionModalFor === DatasetDeletionMode.deprovision) {
              await onDeprovision();
            } else {
              const deleted = await handleDeleteDataset();
              if (
                deleted &&
                datasetDeletionModalFor === DatasetDeletionMode.replace
              ) {
                onUpload();
              }
            }
            setDatasetDeletionModalFor(undefined);
          } finally {
            setDeleting(false);
          }
        }}
        open={datasetDeletionModalFor !== undefined}
      />
    </>
  );
};

export default DataNodeActions;

interface DataNodeActionsPlaceholderProps {
  dataType: DataType;
  testing: boolean;
}

export const DataNodeActionsPlaceholder: React.FC<
  DataNodeActionsPlaceholderProps
> = ({ dataType, testing }) => (
  <Typography level="body-sm">
    No {testing ? "test " : ""}
    {dataType === "table" ? "dataset" : "file"} provisioned
  </Typography>
);
