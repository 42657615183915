import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  ModalDialog,
  Stack,
  Typography,
} from "@mui/joy";
import { memo, useState } from "react";
import { useKeychain } from "wrappers";

interface ResetKeychainPasswordProps {
  onCancel: () => void;
  open: boolean;
}

const ResetKeychainPassword: React.FC<ResetKeychainPasswordProps> = ({
  open,
  onCancel,
}) => {
  const [reseting, setReseting] = useState(false);
  const { resetKeychain } = useKeychain();
  return (
    <Modal open={open}>
      <ModalDialog
        color="danger"
        invertedColors={true}
        role="alertdialog"
        variant="solid"
      >
        <DialogTitle
          sx={{ alignItems: "center", flexDirection: "column", gap: 1, pb: 2 }}
        >
          <FontAwesomeIcon
            fixedWidth={true}
            icon={faExclamationTriangle}
            size="4x"
          />
          <Typography
            level="h4"
            sx={{ textWrap: "balance" }}
            textAlign="center"
          >
            <strong>Are you sure you want to empty your Keychain?</strong>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={0.5}>
            <Typography sx={{ textWrap: "balance" }} textAlign="center">
              If you reset your keychain password, you will no longer be able to
              use the keys you currently have in the Keychain.
            </Typography>
            <Typography sx={{ textWrap: "balance" }} textAlign="center">
              Note: Datasets will remain provisioned to all data clean rooms. No
              data will be lost.
            </Typography>
          </Stack>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button disabled={reseting} onClick={onCancel}>
            Cancel
          </Button>
          <Button
            color="primary"
            loading={reseting}
            loadingPosition="start"
            onClick={async () => {
              setReseting(true);
              await resetKeychain();
              setReseting(false);
            }}
            startDecorator={<FontAwesomeIcon icon={faExclamationTriangle} />}
            variant="solid"
          >
            Empty Keychain and reset password
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default memo(ResetKeychainPassword);
