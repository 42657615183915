import { testIds } from "@decentriq/utils";
import { FormControl, FormLabel, Input } from "@mui/joy";
import { memo } from "react";
import { useCreationWizardConfiguration } from "features/MediaInsightsDcr/contexts";

const DataRoomName: React.FC = () => {
  const { setDataRoomName, dataRoomName } = useCreationWizardConfiguration();
  return (
    <FormControl>
      <FormLabel>1. Data clean room details</FormLabel>
      <Input
        autoComplete="off"
        autoFocus={true}
        data-testid={
          testIds.mediaInsightsDcr.mediaInsightsDcrCreateCollaborationStep
            .insightDataRoomName
        }
        onChange={(event) => setDataRoomName(event.target.value)}
        placeholder="Data clean room name"
        required={true}
        value={dataRoomName}
      />
    </FormControl>
  );
};
DataRoomName.displayName = "DataRoomName";

export default memo(DataRoomName);
