import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Option,
  Select,
  Tooltip,
} from "@mui/joy";
import { memo } from "react";
import {
  Controller,
  type FieldValues,
  type Path,
  useFormContext,
} from "react-hook-form";
import { matchingIdTypes } from "models/dataRoom/matchingId";

interface ConfigurationFormMatchingIdFieldProps<F extends FieldValues> {
  fieldName: Path<F>;
  tooltip?: string;
  testId?: string;
  optionTestId?: string;
}

type ConfigurationFormMatchingIdFieldComponent = <F extends FieldValues>(
  props: ConfigurationFormMatchingIdFieldProps<F>
) => React.ReactNode;

const ConfigurationFormMatchingIdField = memo(
  <F extends FieldValues>({
    fieldName,
    tooltip,
    testId,
    optionTestId,
  }: ConfigurationFormMatchingIdFieldProps<F>) => {
    const { control } = useFormContext<F>();
    return (
      <Controller
        control={control}
        name={fieldName}
        render={({ field, fieldState }) => {
          const { error } = fieldState;
          return (
            <FormControl
              disabled={field.disabled}
              error={Boolean(error)}
              required={true}
            >
              <FormLabel>
                Matching ID
                {tooltip ? (
                  <Box ml={0.5}>
                    <Tooltip
                      placement="top"
                      sx={{ maxWidth: 300 }}
                      title={tooltip}
                    >
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </Tooltip>
                  </Box>
                ) : null}
              </FormLabel>
              <Select
                placeholder="No matching id selected"
                renderValue={(selectedOption) => {
                  const option = matchingIdTypes.find(
                    (o) => o.value === selectedOption?.value
                  );
                  if (!option) return "No matching id selected";
                  return option.name;
                }}
                {...field}
                data-testid={testId}
                onChange={(event, value) => field.onChange(value)}
              >
                {matchingIdTypes.map(({ value, name }, index) => (
                  <Option
                    data-testid={
                      optionTestId
                        ? `${optionTestId}${value.toLowerCase()}`
                        : undefined
                    }
                    key={index}
                    value={value}
                  >
                    {name}
                  </Option>
                ))}
              </Select>
              <FormHelperText>{error?.message}</FormHelperText>
            </FormControl>
          );
        }}
      />
    );
  }
);
ConfigurationFormMatchingIdField.displayName =
  "ConfigurationFormMatchingIdField";

export default ConfigurationFormMatchingIdField as ConfigurationFormMatchingIdFieldComponent;
