import { DqTable } from "@decentriq/components";
import { useDatasetsQuery } from "@decentriq/graphql/dist/hooks";
import { type DatasetsQuery } from "@decentriq/graphql/dist/types";
import {
  faEmptySet,
  faFile,
  faKey,
  faTable,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, CircularProgress, Grid, Tooltip, Typography } from "@mui/joy";
import { type Theme } from "@mui/material";
import {
  type MRT_ColumnDef,
  type MRT_RowSelectionState,
} from "material-react-table";
import { useCallback, useMemo, useState } from "react";
import { KeychainItemKind } from "services/keychain";
import { makeStyles } from "tss-react/mui";
import { type ActionsItemsDefinition, TimeAgoFormatted } from "components";
import { DatasetsViewSelect } from "features/datasets/components";
import DatasetTabs from "features/datasets/components/DatasetTabs/DatasetTabs";
import DatasetToolbar from "features/datasets/components/DatasetToolbar/DatasetToolbar";
import { DatasetsView } from "features/datasets/models";
import { useGetKeychainItems } from "hooks/keychain/useGetKeychainItems";
import { type DataType } from "models";
import { ellipsisify } from "utils";

const useEmptyPlaceholderStyle = makeStyles()((theme: Theme) => ({
  containerCenter: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  contentText: {
    display: "block",
    marginBlockEnd: "0.7em",
    marginBlockStart: "0.3em",
    marginInlineEnd: "0px",
    marginInlineStart: "0px",
  },
  icon: {
    height: "5em",
    marginBottom: "0.5em",
    width: "5em !important",
  },
}));

type DatasetColumnDef = Omit<Dataset, "__typename">;
type Dataset = DatasetsQuery["datasets"]["nodes"][number] & { type: DataType };
type DatasetsListProps = {
  customDatasetActions?: (datasetHash: string) => ActionsItemsDefinition;
};

const datasetKeychainItemKinds = [
  KeychainItemKind.Dataset,
  KeychainItemKind.DatasetMetadata,
];

const DatasetsList: React.FC<DatasetsListProps> = ({
  customDatasetActions,
}) => {
  const { classes: emptyPlaceholderClasses } = useEmptyPlaceholderStyle();
  const [datasetsView, setDatasetsView] = useState(DatasetsView.KeychainOnly);
  const { data: datasetsData, loading } = useDatasetsQuery({
    fetchPolicy: "network-only",
  });
  const { items: keychainItems } = useGetKeychainItems({
    kinds: datasetKeychainItemKinds,
  });
  const hasKeychainItem = useCallback(
    (datasetHash: string): boolean =>
      keychainItems.some(
        ({ id, kind }) =>
          datasetHash === id && kind === KeychainItemKind.Dataset
      ),
    [keychainItems]
  );
  const hasTabularSchema = useCallback(
    (datasetHash: string): boolean =>
      keychainItems.some(
        ({ id, kind }) =>
          datasetHash === id && kind === KeychainItemKind.DatasetMetadata
      ),
    [keychainItems]
  );
  const { datasets, totalCount } = useMemo(() => {
    if (!datasetsData?.datasets?.nodes?.length) {
      return {
        datasets: [],
        totalCount: 0,
      };
    }
    const datasets: Dataset[] = datasetsData?.datasets?.nodes
      .slice()
      .map((dataset) => ({
        ...dataset,
        type: (hasTabularSchema(dataset.manifestHash)
          ? "table"
          : "unstructured") as DataType,
      }))
      .filter(
        ({ manifestHash }) =>
          datasetsView === DatasetsView.All ||
          (datasetsView === DatasetsView.KeychainOnly &&
            hasKeychainItem(manifestHash))
      );
    return {
      datasets,
      totalCount: datasets.length,
    };
  }, [
    datasetsData?.datasets?.nodes,
    datasetsView,
    hasKeychainItem,
    hasTabularSchema,
  ]);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const selectedManifestHashes = Object.keys(rowSelection)
    .filter((key) => rowSelection[key])
    .map((key) => datasets.find(({ manifestHash }) => manifestHash === key));
  const datasetsColumnDef: MRT_ColumnDef<DatasetColumnDef>[] = useMemo(
    () => [
      {
        Cell: ({ cell, row }) => {
          const isTabular = row.original.type === "table";
          const name = ellipsisify(cell.getValue<string>(), 35);
          return (
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                fontSize: "1rem",
                gap: 1,
              }}
            >
              <FontAwesomeIcon
                fixedWidth={true}
                icon={isTabular ? faTable : faFile}
              />
              <Typography
                component="span"
                fontWeight="fontWeightSemiBold"
                level="body-sm"
                noWrap={true}
                sx={{ flex: 1 }}
              >
                {name}
              </Typography>
              {hasKeychainItem(row.original.manifestHash) && (
                <Tooltip
                  placement="top"
                  title="This dataset encryption key is stored in the Keychain."
                >
                  <FontAwesomeIcon fixedWidth={true} icon={faKey} />
                </Tooltip>
              )}
            </Box>
          );
        },
        accessorKey: "name",
        enableSorting: false,
        header: "Name",
        id: "name",
        size: 200,
      },
      {
        Cell: ({ cell }) => {
          const createdAt = cell.getValue<string>();
          return (
            <Box>
              {createdAt ? (
                <TimeAgoFormatted
                  date={createdAt}
                  style={{ whiteSpace: "nowrap" }}
                />
              ) : (
                "—"
              )}
            </Box>
          );
        },
        accessorKey: "createdAt",
        header: "Upload date",
        id: "createdAt",
        size: 100,
      },
    ],
    [hasKeychainItem]
  );
  if (loading) {
    return (
      <Box
        alignItems="center"
        display="flex"
        height="100%"
        justifyContent="center"
        left="0"
        position="absolute"
        top="0"
        width="100%"
      >
        <CircularProgress sx={{ "--CircularProgress-size": "2.5rem" }} />
      </Box>
    );
  }
  if (!loading && !totalCount) {
    return (
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
        width="100%"
      >
        <FontAwesomeIcon
          className={emptyPlaceholderClasses.icon}
          fixedWidth={true}
          height="5em !important"
          icon={faEmptySet}
          width={24}
        />
        <Typography level="title-md">
          <strong>Datasets</strong>
        </Typography>
        <Typography>
          <p className={emptyPlaceholderClasses.contentText}>
            You haven't provisioned any dataset to data clean rooms yet.
          </p>
        </Typography>
      </Box>
    );
  }
  return (
    <Box
      sx={{
        alignItems: "stretch",
        backgroundColor: "common.white",
        display: "flex",
        flex: 1,
        flexDirection: "column",
        justifyContent: "stretch",
        overflow: "hidden",
      }}
    >
      <Grid
        columnSpacing={0}
        container={true}
        sx={{ flex: 1, overflow: "hidden" }}
      >
        <Grid
          sx={{
            borderColor: "divider",
            borderRightStyle: "solid",
            borderRightWidth: "1px",
            height: "100%",
            overflow: "hidden",
          }}
          xs={6}
        >
          <DqTable
            autoSelectFirstRow={true}
            columns={datasetsColumnDef}
            data={datasets}
            enableBatchRowSelection={false}
            enableGlobalFilter={true}
            enableMultiRowSelection={false}
            enableRowSelection={true}
            enableSelectAll={false}
            enableSorting={true}
            enableTopToolbar={true}
            getRowId={(row) => row.manifestHash}
            initialState={{
              showGlobalFilter: true,
              sorting: [{ desc: true, id: "createdAt" }],
            }}
            localization={{
              noRecordsToDisplay: "No datasets found",
            }}
            muiSearchTextFieldProps={{
              placeholder: "Search datasets",
            }}
            muiTableBodyRowProps={({
              row: { getToggleSelectedHandler, getIsSelected },
            }) => {
              return {
                onClick: getIsSelected()
                  ? undefined
                  : getToggleSelectedHandler(),
                sx: {
                  "&:hover td:after": {
                    backgroundColor: "primary.light",
                    content: '""',
                    height: "100%",
                    left: 0,
                    opacity: 0.125,
                    position: "absolute",
                    top: 0,
                    width: "100%",
                    zIndex: -1,
                  },
                  cursor: "pointer",
                },
              };
            }}
            muiTablePaperProps={{
              sx: {
                display: "flex",
                flex: 1,
                flexDirection: "column",
                height: "100%",
                overflow: "hidden",
                width: "100%",
              },
            }}
            muiTopToolbarProps={{
              sx: {
                flex: "0 0 auto",
                minHeight: "auto",
                // eslint-disable-next-line sort-keys-fix/sort-keys-fix
                borderBottom: "1px solid",
                borderColor: "divider",
              },
            }}
            onRowSelectionChange={setRowSelection}
            renderTopToolbarCustomActions={() => (
              <DatasetsViewSelect
                selectedDatasetsView={datasetsView}
                setDatasetsView={setDatasetsView}
              />
            )}
            state={{
              columnVisibility: { "mrt-row-select": false },
              rowSelection,
            }}
          />
        </Grid>
        {!loading ? (
          <Grid sx={{ height: "100%", overflow: "hidden" }} xs={6}>
            {selectedManifestHashes.map((selectedManifestHash) =>
              selectedManifestHash ? (
                <Box
                  key={selectedManifestHash.manifestHash}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    height: "100%",
                    overflow: "hidden",
                    p: 2,
                  }}
                >
                  <DatasetToolbar
                    customDatasetActions={customDatasetActions}
                    datasetHash={selectedManifestHash.manifestHash}
                    hasKeychainItem={hasKeychainItem(
                      selectedManifestHash.manifestHash
                    )}
                  />
                  <DatasetTabs
                    datasetHash={selectedManifestHash.manifestHash}
                    skipFetching={false}
                  />
                </Box>
              ) : null
            )}
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
};

export default DatasetsList;
