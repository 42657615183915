import { WorkerTypeShortName } from "@decentriq/graphql/dist/types";
import { testIds } from "@decentriq/utils";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Chip, Dropdown, Menu, MenuButton, MenuItem } from "@mui/joy";
import { Alert } from "@mui/material";
import { useCallback, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import { useComputeNodesVars } from "contexts";
import {
  mapDraftDataRoomErrorToSnackbar,
  useDataRoomSnackbar,
  useIsComputeEnabled,
  useNodes,
} from "hooks";
import { type HooksNode } from "hooks/useNodes/useNodes";
import {
  WORKER_TYPE_COLOR,
  workerTypeShortNameToDraftComputeNodeTypeName,
} from "models";
import { WORKER_TYPE_LABEL } from "../ComputeNodeType/useComputeNodeType";
import useComputeNodeCreateMutation from "./useComputeNodeCreate/useComputeNodeCreate";

export const PLACEHOLDER: Record<WorkerTypeShortName, string> = {
  [WorkerTypeShortName.Match]: "new Matching computation",
  [WorkerTypeShortName.Post]: "new Post computation",
  [WorkerTypeShortName.Preview]: "new Airlock computation",
  [WorkerTypeShortName.S3]: "new S3 integration",
  [WorkerTypeShortName.Python]: "new Python computation",
  [WorkerTypeShortName.R]: "new R computation",
  [WorkerTypeShortName.Sql]: "new NATIVE-SQL computation",
  [WorkerTypeShortName.Sqlite]: "new SQL computation",
  [WorkerTypeShortName.Synthetic]: "new Synthetic Data computation",
};

interface ComputeNodeCreatorProps {
  dataRoomId: string;
  onComputationCreate?: (
    computationName: string,
    computationType: WorkerTypeShortName
  ) => void;
  excludeComputationTypes?: WorkerTypeShortName[];
}

const ComputeNodeCreator = ({
  dataRoomId,
  onComputationCreate,
  excludeComputationTypes = [],
}: ComputeNodeCreatorProps) => {
  const { expand } = useComputeNodesVars();
  const { nodes } = useNodes();
  const generateName = useCallback(
    (computationType: WorkerTypeShortName) => {
      const filteredNodes = nodes.filter(
        ({ __typename, name }) =>
          __typename ===
            workerTypeShortNameToDraftComputeNodeTypeName.get(
              computationType
            ) && name.includes(WORKER_TYPE_LABEL[computationType])
      );

      let helper = 1;
      const isNameTaken = (elements: HooksNode) =>
        elements.name.includes(
          `${WORKER_TYPE_LABEL[computationType]} ${helper}`
        );

      while (filteredNodes.some(isNameTaken)) {
        helper++;
      }

      return `${WORKER_TYPE_LABEL[computationType]} ${helper}`;
    },
    [nodes]
  );
  const popupId = "compute-node-add-menu";
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const {
    isMatchComputeEnabled,
    isPostComputeEnabled,
    isPreviewComputeEnabled,
    isS3ComputeEnabled,
    isSqlComputeEnabled,
    isSqliteComputeEnabled,
    isPythonComputeEnabled,
    isRComputeEnabled,
    isSyntheticComputeEnabled,
  } = useIsComputeEnabled();
  const isMatchEnabled =
    isMatchComputeEnabled &&
    !excludeComputationTypes.includes(WorkerTypeShortName.Match);
  const isS3Enabled =
    isS3ComputeEnabled &&
    !excludeComputationTypes.includes(WorkerTypeShortName.S3);
  const isPostEnabled =
    isPostComputeEnabled &&
    !excludeComputationTypes.includes(WorkerTypeShortName.Post);
  const isPreviewEnabled =
    isPreviewComputeEnabled &&
    !excludeComputationTypes.includes(WorkerTypeShortName.Preview);
  const isSqlEnabled =
    isSqlComputeEnabled &&
    !excludeComputationTypes.includes(WorkerTypeShortName.Sql);
  const isSqliteEnabled =
    isSqliteComputeEnabled &&
    !excludeComputationTypes.includes(WorkerTypeShortName.Sqlite);
  const isPythonEnabled =
    isPythonComputeEnabled &&
    !excludeComputationTypes.includes(WorkerTypeShortName.Python);
  const isREnabled =
    isRComputeEnabled &&
    !excludeComputationTypes.includes(WorkerTypeShortName.R);
  const isSyntheticEnabled =
    isSyntheticComputeEnabled &&
    !excludeComputationTypes.includes(WorkerTypeShortName.Synthetic);
  const createComputeNodeMutation = useComputeNodeCreateMutation({
    onError: (error) => {
      enqueueSnackbar(
        ...mapDraftDataRoomErrorToSnackbar(
          error,
          "The computation could not be created."
        )
      );
    },
    // TODO: write to cache using a fragment depending on typename
    refetchQueries: ["DraftDataRoomNodes"],
  });
  const createComputation = useCallback(
    (newComputationType: WorkerTypeShortName) => {
      const computationName = generateName(newComputationType);
      if (onComputationCreate) {
        onComputationCreate(computationName, newComputationType);
      } else {
        const computeNodeId = uuidv4();
        createComputeNodeMutation(newComputationType, {
          onCompleted: () => {
            expand(computeNodeId);
          },
          variables: {
            input: {
              draftDataRoomId: dataRoomId,
              id: computeNodeId,
              name: computationName,
            },
          },
        });
      }
    },
    [
      createComputeNodeMutation,
      dataRoomId,
      expand,
      generateName,
      onComputationCreate,
    ]
  );
  const COMPUTATION_TYPE_BUTTONS = useMemo(
    () => [
      {
        isEnabled: isSqlEnabled,
        label: WORKER_TYPE_LABEL[WorkerTypeShortName.Sql],
        value: WorkerTypeShortName.Sql,
      },
      {
        isEnabled: isSqliteEnabled,
        label: WORKER_TYPE_LABEL[WorkerTypeShortName.Sqlite],
        value: WorkerTypeShortName.Sqlite,
      },
      {
        isEnabled: isMatchEnabled,
        label: WORKER_TYPE_LABEL[WorkerTypeShortName.Match],
        value: WorkerTypeShortName.Match,
      },
      {
        isEnabled: isS3Enabled,
        label: WORKER_TYPE_LABEL[WorkerTypeShortName.S3],
        value: WorkerTypeShortName.S3,
      },
      {
        isEnabled: isPostEnabled,
        label: WORKER_TYPE_LABEL[WorkerTypeShortName.Post],
        value: WorkerTypeShortName.Post,
      },
      {
        isEnabled: isPreviewEnabled,
        label: WORKER_TYPE_LABEL[WorkerTypeShortName.Preview],
        value: WorkerTypeShortName.Preview,
      },
      {
        isEnabled: isPythonEnabled,
        label: WORKER_TYPE_LABEL[WorkerTypeShortName.Python],
        value: WorkerTypeShortName.Python,
      },
      {
        isEnabled: isREnabled,
        label: WORKER_TYPE_LABEL[WorkerTypeShortName.R],
        value: WorkerTypeShortName.R,
      },
      {
        isEnabled: isSyntheticEnabled,
        label: WORKER_TYPE_LABEL[WorkerTypeShortName.Synthetic],
        value: WorkerTypeShortName.Synthetic,
      },
    ],
    [
      isSqlEnabled,
      isSqliteEnabled,
      isMatchEnabled,
      isS3Enabled,
      isPostEnabled,
      isPreviewEnabled,
      isPythonEnabled,
      isREnabled,
      isSyntheticEnabled,
    ]
  );
  const isCreateComputationEnabled = COMPUTATION_TYPE_BUTTONS.some(
    ({ isEnabled }) => isEnabled
  );
  return isCreateComputationEnabled ? (
    <Dropdown>
      <MenuButton
        data-testid={testIds.computeNode.computeNodeCreator.newComputation}
        id="add_computation_dropdown"
        startDecorator={<FontAwesomeIcon fixedWidth={true} icon={faPlus} />}
      >
        Add computation
      </MenuButton>
      <Menu
        id={popupId}
        placement="bottom-end"
        sx={{ "--ListItemDecorator-size": "1.625rem" }}
      >
        {COMPUTATION_TYPE_BUTTONS.map(
          ({ isEnabled, label, value }) =>
            isEnabled && (
              <MenuItem onClick={() => createComputation(value)}>
                <Chip
                  data-testid={`${
                    testIds.computeNode.computeNodeToggle.helper
                  }${label.toLowerCase()}`}
                  key={value}
                  sx={{ backgroundColor: WORKER_TYPE_COLOR[value] }}
                >
                  {label}
                </Chip>
              </MenuItem>
            )
        )}
      </Menu>
    </Dropdown>
  ) : (
    <Alert severity="warning" sx={{ mb: 1 }}>
      You have no permissions to create new computations
    </Alert>
  );
};
ComputeNodeCreator.displayName = "ComputeNodeCreator";

export default ComputeNodeCreator;
