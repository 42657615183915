// TODO: Fix joy migration
import { useCreateDatasetImportMutation } from "@decentriq/graphql/dist/hooks";
import { type ComputeJob } from "@decentriq/graphql/dist/types";
import {
  faDownload,
  faFileImport,
  faShareFromSquare,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, CircularProgress } from "@mui/joy";
import { useTheme } from "@mui/material";
import { useSnackbar } from "notistack";
import { forwardRef, memo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Actions } from "components";
import { useComputeNodeDownload } from "features/computeNode/components/ComputeNodeActions/hooks";
import { mapErrorToGeneralSnackbar, useDataRoomSnackbar } from "hooks";
import { ComputeNodeTypeNames } from "models";

type SaveComputationResultButtonProps = {
  computeNodeId: string;
  job: ComputeJob;
  computeNodeName: string;
  computationType: ComputeNodeTypeNames;
  disabled?: boolean;
};

const SaveComputationResultButton: React.FC<SaveComputationResultButtonProps> =
  forwardRef(
    (
      {
        computeNodeId,
        computeNodeName: name,
        computationType,
        job = {},
        disabled = false,
      },
      ref
    ) => {
      const { enqueueSnackbar } = useSnackbar();
      const { enqueueSnackbar: enqueueDataRoomSnackbar } =
        useDataRoomSnackbar();
      const navigate = useNavigate();
      const theme = useTheme();
      const { downloadResult, loading: downloadResultLoading } =
        useComputeNodeDownload({
          computationType,
          computeNodeId,
          dataRoomHash: job?.dataRoomHash,
          driverAttestationHash: job?.driverAttestationHash || "",
          jobId: job?.id || "",
          name,
        });
      const [
        createDatasetImportMutation,
        { loading: isCreateDatasetImportLoading },
      ] = useCreateDatasetImportMutation({
        onCompleted: () => {
          enqueueSnackbar({
            action: (
              <Button onClick={() => navigate("/datasets")}>
                Go to Datasets
              </Button>
            ),
            message: `${name} result is being stored. Please check the status in the 'Datasets' page.`,
          });
        },
        onError: (error) => {
          enqueueDataRoomSnackbar(
            ...mapErrorToGeneralSnackbar(
              error,
              `${name} result could not be stored.`
            )
          );
        },
      });

      const saveAsDataset = useCallback(() => {
        createDatasetImportMutation({
          variables: {
            input: {
              compute: {
                computeNodeId,
                dataRoomId: job?.dataRoomHash,
                driverAttestationHash: job?.driverAttestationHash,
                ...(computationType === ComputeNodeTypeNames.PublishedSqlNode
                  ? { importFileWithName: "dataset.csv" }
                  : { shouldImportAllFiles: true }),
              },
              datasetName: `${name?.replace(/ /g, "_")}_result`,
            },
          },
        });
      }, [
        computationType,
        computeNodeId,
        createDatasetImportMutation,
        job?.dataRoomHash,
        job?.driverAttestationHash,
        name,
      ]);
      const isDownloadLoading =
        downloadResultLoading || isCreateDatasetImportLoading;
      return (
        <Actions
          actions={{
            menuLists: [
              [
                {
                  disabled: disabled || isDownloadLoading,
                  hidden: false,
                  icon: faFileImport,
                  loading: false,
                  name: "Store as dataset",
                  onClick: saveAsDataset,
                  sx: {
                    cursor: "pointer",
                    padding: "8px 16px",
                  },
                },
              ],
              [
                {
                  disabled: disabled || isDownloadLoading,
                  hidden: false,
                  icon: faDownload,
                  loading: false,
                  name: "Download",
                  onClick: downloadResult,
                  sx: {
                    cursor: "pointer",
                    padding: "8px 16px",
                  },
                },
              ],
            ],
          }}
          menuListStyle={{ padding: "0" }}
          moreIcon={
            isDownloadLoading ? (
              <CircularProgress sx={{ "--CircularProgress-size": "16px" }} />
            ) : (
              <FontAwesomeIcon fixedWidth={true} icon={faShareFromSquare} />
            )
          }
          moreTooltipPlacement="top"
          moreTooltipTitle="Retrieve result"
          popoverStyle={{
            "& .MuiPopover-paper": {
              border: "none !important",
              boxShadow: `${theme.shadows[1]} !important`,
              padding: "4px 0",
            },
          }}
          ref={ref}
          withActionsSeparator={false}
        />
      );
    }
  );

SaveComputationResultButton.displayName = "SaveComputationResultButton";

export default memo(SaveComputationResultButton);
