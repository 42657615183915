import { Box } from "@mui/joy";
import { memo } from "react";
import {
  ParticipantsConstructor,
  ParticipantsInteractivitySettings,
} from "components";
import { useDataRoom, usePermissionsVars } from "contexts";

const DataRoomParticipants: React.FC = memo(() => {
  const { dataRoomId } = useDataRoom();
  const { canEditPermissions } = usePermissionsVars();
  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", mb: "0.5rem" }}>
        <ParticipantsInteractivitySettings id={dataRoomId} />
      </Box>
      <div style={!canEditPermissions ? {} : { flex: 1, overflow: "auto" }}>
        <ParticipantsConstructor id={dataRoomId} />
      </div>
    </>
  );
});

export default DataRoomParticipants;
