import { type Reference } from "@apollo/client";
import {
  useDraftParticipantCreateDataOwnerPermissionMutation,
  useDraftParticipantDeleteDataOwnerPermissionMutation,
} from "@decentriq/graphql/dist/hooks";
import { DraftLeafNodeNameFragment } from "@decentriq/graphql/dist/types";
import { Box, CircularProgress } from "@mui/joy";
import { memo, useCallback } from "react";
import { DataRoomPermissionsSelect } from "components";
import { useDataRoom, usePermissionsVars } from "contexts";
import {
  mapDraftDataRoomErrorToSnackbar,
  useDataRoomSnackbar,
  useNodes,
} from "hooks";
import { DataNodeTypeNames } from "models";

interface DataNodesPermissionsSelectProps {
  participant?: {
    id: string;
    permissions: [
      {
        id: string;
        name: string;
        __typename: DataNodeTypeNames | { __typename: DataNodeTypeNames };
      },
    ];
  };
}

const DataNodesPermissionsSelect: React.FC<DataNodesPermissionsSelectProps> =
  memo(({ participant }): JSX.Element => {
    const { enqueueSnackbar } = useDataRoomSnackbar();
    const { isPublished } = useDataRoom();
    const { canEditPermissions } = usePermissionsVars();
    const { nodes, loading } = useNodes();
    const dataNodes = nodes.filter(({ __typename }) =>
      (Object.values(DataNodeTypeNames) as string[]).includes(__typename!)
    );
    const permissions =
      participant?.permissions
        ?.filter((node) => Object.keys(node).length)
        ?.filter((node) => {
          return (Object.values(DataNodeTypeNames) as string[]).includes(
            typeof node.__typename === "string"
              ? node.__typename
              : (node.__typename as { __typename: DataNodeTypeNames })
                  .__typename
          );
        })
        ?.map(({ id }) => id) || [];
    const dataNodeOptions = dataNodes.map(({ id, name }) => ({
      title: name,
      value: id,
    }));
    const [draftParticipantCreateDataOwnerPermissionMutation] =
      useDraftParticipantCreateDataOwnerPermissionMutation({
        onError: (error) => {
          enqueueSnackbar(
            ...mapDraftDataRoomErrorToSnackbar(
              error,
              "Data Owner permission could not be added to the participant."
            )
          );
        },
      });
    const [draftParticipantDeleteDataOwnerPermissionMutation] =
      useDraftParticipantDeleteDataOwnerPermissionMutation({
        onError: (error) => {
          enqueueSnackbar(
            ...mapDraftDataRoomErrorToSnackbar(
              error,
              "Data Owner permission could not be removed from the participant."
            )
          );
        },
      });
    const handleAddDataNodePermission = useCallback(
      (dataNodeId: string) => {
        draftParticipantCreateDataOwnerPermissionMutation({
          update: (cache, { data }) => {
            cache.modify({
              fields: {
                permissions: (existing = {}) => {
                  const permissionRef = cache.writeFragment({
                    data: data?.draftParticipant?.createDataOwnerPermission
                      ?.node,
                    fragment: DraftLeafNodeNameFragment,
                  });
                  return {
                    ...existing,
                    nodes: [permissionRef, ...(existing?.nodes || [])],
                  };
                },
              },
              id: cache.identify({
                __typename: "DraftParticipant",
                id: participant?.id!,
              }),
            });
          },
          variables: {
            draftNodeId: dataNodeId,
            draftParticipantId: participant?.id!,
          },
        });
      },
      [draftParticipantCreateDataOwnerPermissionMutation, participant?.id]
    );
    const handleRemoveDataNodePermission = useCallback(
      (dataNodeId: string) => {
        draftParticipantDeleteDataOwnerPermissionMutation({
          update: (cache, data) => {
            cache.modify({
              fields: {
                permissions: (existing = {}, { readField }) => {
                  const incoming = {
                    ...existing,
                    nodes:
                      existing?.nodes?.filter(
                        ({ node: nodesDataRoomRef }: { node: Reference }) =>
                          readField("id", nodesDataRoomRef) !== dataNodeId
                      ) || [],
                  };
                  return incoming;
                },
              },
              id: cache.identify({
                __typename: "DraftParticipant",
                id: participant?.id!,
              }),
            });
          },
          variables: {
            dataNodeId,
            participantId: participant?.id!,
          },
        });
      },
      [participant?.id, draftParticipantDeleteDataOwnerPermissionMutation]
    );
    if (loading) {
      return (
        <Box alignItems="center" display="flex" justifyContent="flex-start">
          <CircularProgress sx={{ "--CircularProgress-size": "1.5rem" }} />
        </Box>
      );
    }
    return (
      <DataRoomPermissionsSelect
        dataKey="dataset"
        disableSelectAll={isPublished}
        disabled={!canEditPermissions || !dataNodeOptions.length}
        onAddPermission={handleAddDataNodePermission}
        onRemovePermission={handleRemoveDataNodePermission}
        options={dataNodeOptions}
        permissions={permissions}
      />
    );
  });

DataNodesPermissionsSelect.displayName = "DataNodesPermissionsSelect";

export default DataNodesPermissionsSelect;
