import { Modal, type ModalProps } from "@mui/joy";
import { memo, useEffect } from "react";
import { useFormContext, useFormState } from "react-hook-form";

// NOTE: Can be exposed as generic `DqFormStateModal`
const CreateMeasurementDcrModal: React.FC<ModalProps> = memo(
  ({ children, open, onClose }) => {
    const { reset } = useFormContext();
    const { isDirty, isSubmitting } = useFormState();
    useEffect(() => (!open ? reset() : undefined), [open, reset]);
    return (
      <Modal
        disableEscapeKeyDown={isDirty}
        onClose={(...args) => !isSubmitting && onClose?.(...args)}
        open={open}
      >
        {children}
      </Modal>
    );
  }
);
CreateMeasurementDcrModal.displayName = "CreateMeasurementDcrModal";

export default CreateMeasurementDcrModal;
