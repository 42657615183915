// TODO: Fix joy migration
import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  ModalDialog,
  Typography,
} from "@mui/joy";
import { useCallback, useState } from "react";

interface DeprovisionConfirmationDialogProps {
  open: boolean;
  onCancel: () => void;
  onDeprovision: () => Promise<void>;
}

const DeprovisionConfirmationDialog: React.FC<
  DeprovisionConfirmationDialogProps
> = ({ open, onCancel, onDeprovision }) => {
  const [deprovisioning, setDeprovisioning] = useState(false);
  const handleDeprovision = useCallback(async () => {
    try {
      setDeprovisioning(true);
      await onDeprovision();
      onCancel();
    } finally {
      setDeprovisioning(false);
    }
  }, [setDeprovisioning, onDeprovision, onCancel]);
  return (
    <Modal open={open}>
      <ModalDialog role="alertdialog">
        <DialogTitle>
          <Box sx={{ color: "error.main", mb: 1, textAlign: "center" }}>
            <FontAwesomeIcon
              fixedWidth={true}
              icon={faExclamationTriangle}
              size="4x"
            />
          </Box>
          <Typography
            align="center"
            level="title-md"
            sx={{ textWrap: "balance" }}
          >
            <strong>
              Are you sure you want to deprovision the advertiser audience data?
            </strong>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography
            align="center"
            level="body-sm"
            sx={{ textWrap: "balance" }}
          >
            Deprovisoning this data means it is no longer available to this
            clean room. This will delete any insights and the dashboard will no
            longer be viewable. Any audiences generated through remarketing or
            lookalike modeling will no longer be available to export. Previously
            exported audiences will still be available.
            <br />
            <br />
            If you provision new data to this clean room, any insights or
            audiences will be recalculated. This may take some time, especially
            if using a lookalike audience, as the model needs to be retrained.
          </Typography>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button disabled={deprovisioning} onClick={onCancel}>
            Cancel
          </Button>
          <Button
            color="danger"
            disabled={deprovisioning}
            loading={deprovisioning}
            onClick={handleDeprovision}
            startDecorator={<FontAwesomeIcon icon={faExclamationTriangle} />}
            variant="solid"
          >
            Deprovision
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

DeprovisionConfirmationDialog.displayName = "DeprovisionConfirmationDialog";

export default DeprovisionConfirmationDialog;
