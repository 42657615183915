import { Chip, Stack, Typography } from "@mui/joy";
import { memo } from "react";

const MatchingId: React.FC = memo(() => {
  return (
    <Stack>
      <Typography level="title-md">4. Matching ID</Typography>
      <Chip size="lg">Hashed email</Chip>
    </Stack>
  );
});
MatchingId.displayName = "MatchingId";

export default MatchingId;
