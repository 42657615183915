import { testIds } from "@decentriq/utils";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Tooltip } from "@mui/joy";
import { useBoolean } from "ahooks";
import { memo } from "react";
import { CreateActivationConfigurationDialog } from "features/publisherPortal";
import { usePublisherPortal } from "features/publisherPortal/contexts";

const CreateActivationConfigurationButton: React.FC = () => {
  const [isDialogOpen, { setTrue: openDialog, setFalse: closeDialog }] =
    useBoolean(false);
  const { publisherDisplayConfiguration } = usePublisherPortal();
  const disabled = !publisherDisplayConfiguration;
  return (
    <>
      <Tooltip
        placement="top"
        title={
          disabled ? "Please first create a display configuration" : undefined
        }
      >
        <span>
          <Button
            color="primary"
            data-testid={
              testIds.publisherPortal.activationConfigurations.createButton
            }
            disabled={disabled}
            onClick={openDialog}
            startDecorator={<FontAwesomeIcon fixedWidth={true} icon={faPlus} />}
            variant="solid"
          >
            DCR configuration
          </Button>
        </span>
      </Tooltip>
      <CreateActivationConfigurationDialog
        onClose={closeDialog}
        open={isDialogOpen}
      />
    </>
  );
};

CreateActivationConfigurationButton.displayName =
  "CreateActivationConfigurationButton";

export default memo(CreateActivationConfigurationButton);
