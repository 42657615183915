// TODO: Fix joy migration
import { InfoTooltip } from "@decentriq/components";
import { faArrowRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Grid, LinearProgress, Typography } from "@mui/joy";
import { memo, useMemo } from "react";
import { useAdvertiserAudienceGenerator } from "features/MediaInsightsDcr/components/AdvertiserAudienceGenerator";

interface PositiveCurveScoreBarProps {
  withChartButton?: boolean;
}

const PositiveCurveScoreBar: React.FC<PositiveCurveScoreBarProps> = ({
  withChartButton = true,
}) => {
  const { openAdvertiserAudienceQualityDrawer, selectedAudienceQuality } =
    useAdvertiserAudienceGenerator();

  const positiveCurveScorePercentage: number = useMemo(
    () => selectedAudienceQuality?.roc_curve?.tpr || 0,
    [selectedAudienceQuality?.roc_curve?.tpr]
  );

  return (
    <Grid container={true} pl={0.5} pr={0.5} xs={12}>
      <Grid xs={12}>
        <Typography fontWeight={500} level="body-sm">
          Recall
          <InfoTooltip tooltip="This is a measure of how much of the seed/retargeting audience the model would recover at this size. For example, If the Recall score is 46% and the “exclude seed audience” option is not selected, the output will include about 46% of the seed audience." />
        </Typography>
        <Typography fontWeight={400} level="body-sm">
          Percentage of "True Positives" from the seed audience recovered in the
          lookalike audience
        </Typography>
      </Grid>
      <Grid alignItems="center" container={true} mt={1} xs={12}>
        <Grid xs={8}>
          <Box alignItems="center" display="flex" mr={1} width="100%">
            <LinearProgress
              color="warning"
              determinate={true}
              sx={({ radius }) => ({
                borderRadius: radius["sm"],
                height: "12px",
                mr: 1,
                width: "100%",
              })}
              value={positiveCurveScorePercentage * 100}
            />
            <Typography color="text.secondary" level="body-sm">
              {(positiveCurveScorePercentage * 100).toFixed(1)}%
            </Typography>
          </Box>
        </Grid>
        {withChartButton && (
          <Grid container={true} justifyContent="flex-end" xs={4}>
            <Button
              color="secondary"
              onClick={openAdvertiserAudienceQualityDrawer}
              startDecorator={<FontAwesomeIcon icon={faArrowRight} />}
            >
              See graph
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

PositiveCurveScoreBar.displayName = "PositiveCurveScoreBar";

export default memo(PositiveCurveScoreBar);
