import { InfoTooltip } from "@decentriq/components";
import { Slider, Stack, Typography } from "@mui/joy";
import { memo, useMemo } from "react";
import { useAdvertiserAudienceGenerator } from "features/MediaInsightsDcr/components/AdvertiserAudienceGenerator";

const AudienceGeneratorReachSlider: React.FC = () => {
  const { reach, setReach, selectedLookalikeAudience } =
    useAdvertiserAudienceGenerator();

  const sliderMarks = useMemo(
    () =>
      (selectedLookalikeAudience?.filtered_audiences || []).reduce(
        (marksAccumulator, { reach }, i, arr) => {
          return [
            {
              label: i === 0 || i === arr.length - 1 ? `${reach}%` : "",
              value: reach,
            },
            ...marksAccumulator,
          ];
        },
        []
      ),
    [selectedLookalikeAudience]
  );

  return (
    <Stack alignItems="center" direction="row" spacing={4}>
      <Typography fontWeight={600} level="body-md">
        Precision
      </Typography>
      <Slider
        marks={sliderMarks}
        max={30}
        min={1}
        onChange={(event, newReach: number | number[]) =>
          setReach(newReach as number)
        }
        step={null}
        sx={{
          "& .MuiSlider-markLabel": { top: "1.5rem" },
          minWidth: 200,
        }}
        value={reach}
        valueLabelDisplay="auto"
        valueLabelFormat={(value) => `${value}%`}
      />
      <Typography display="inline-flex" fontWeight={600} level="body-md">
        Reach
        <InfoTooltip tooltip="The generated top affinity audiences will include segments until the estimated reach is achieved." />
      </Typography>
    </Stack>
  );
};

AudienceGeneratorReachSlider.displayName = "AudienceGeneratorReachSlider";

export default memo(AudienceGeneratorReachSlider);
