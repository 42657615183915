import { Button } from "@mui/joy";
import { useBoolean } from "ahooks";
import { memo, useCallback, useMemo } from "react";
import { type ReportErrorInput } from "hooks";
import ErrorReportDialog from "./ErrorReportDialog";

interface ErrorReportButtonProps {
  origin: string;
  error: string;
  details: string;
  closeSnackbar: () => void;
}

const ErrorReportButton: React.FC<ErrorReportButtonProps> = ({
  origin,
  error,
  details,
  closeSnackbar,
}) => {
  const [isDialogOpen, { setFalse: closeDialog, setTrue: openDialog }] =
    useBoolean(false);
  const handleFinish = useCallback(() => {
    closeSnackbar();
    closeDialog();
  }, [closeDialog, closeSnackbar]);
  const defaultDetails = useMemo(
    () =>
      `Context: ${origin}\nError message: ${error}\nError details: ${details}`,
    [error, details, origin]
  );
  const handleGenerateReport = useCallback(
    async (details: string) => {
      const report: ReportErrorInput = {
        details: details || "",
        errorContext: [],
        origin,
      };
      return report;
    },
    [origin]
  );
  return (
    <>
      <Button onClick={openDialog}>Report issue to Decentriq</Button>
      <ErrorReportDialog
        defaultDetails={defaultDetails}
        generateReport={handleGenerateReport}
        onCancel={closeDialog}
        onFinish={handleFinish}
        open={isDialogOpen}
      />
    </>
  );
};

ErrorReportButton.displayName = "ErrorReportButton";

export default memo(ErrorReportButton);
