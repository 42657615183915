import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Option,
  Select,
  type SelectOption,
  Tooltip,
} from "@mui/joy";
import { memo, useCallback } from "react";
import {
  Controller,
  type FieldValues,
  type Path,
  useFormContext,
} from "react-hook-form";
import { type OrganizationUser } from "../../hooks/useOrganizationUsers/useOrganizationUsers";

interface DisplayConfigurationCollaborativeUsersFieldProps<
  F extends FieldValues,
> {
  fieldName: Path<F>;
  organizationUsers: OrganizationUser[];
  testId?: string;
  optionTestId?: string;
}

type DisplayConfigurationCollaborativeUsersFieldComponent = <
  F extends FieldValues,
>(
  props: DisplayConfigurationCollaborativeUsersFieldProps<F>
) => React.ReactNode;

const DisplayConfigurationCollaborativeUsersField = memo(
  <F extends FieldValues>({
    fieldName,
    organizationUsers,
    testId,
    optionTestId,
  }: DisplayConfigurationCollaborativeUsersFieldProps<F>) => {
    const { control } = useFormContext<F>();
    const handleRenderValue = useCallback(
      (selectedEmailOptions: SelectOption<never>[]) => {
        if (selectedEmailOptions.length === 1) {
          return selectedEmailOptions[0].value;
        }
        return (
          <Tooltip
            placement="top"
            title={selectedEmailOptions.map(({ value }) => value).join(", ")}
          >
            <span>{`${selectedEmailOptions.length} participants`}</span>
          </Tooltip>
        );
      },
      []
    );
    return (
      <Controller
        control={control}
        name={fieldName}
        render={({ field, fieldState }) => {
          const { error } = fieldState;
          return (
            <FormControl
              disabled={field.disabled}
              error={Boolean(error)}
              required={true}
            >
              <FormLabel>
                Contact request email(s)
                <Box ml={0.5}>
                  <Tooltip
                    placement="top"
                    sx={{ maxWidth: 300 }}
                    title="Contact requests by advertisers will be sent to these email addresses."
                  >
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </Tooltip>
                </Box>
              </FormLabel>
              <Select
                multiple={true}
                placeholder="No participants selected"
                renderValue={handleRenderValue}
                {...field}
                data-testid={testId}
                onChange={(event, value) => field.onChange(value)}
              >
                {organizationUsers.map(({ id, email }) => (
                  <Option
                    data-testid={`${optionTestId}${email.toLowerCase()}`}
                    key={id}
                    value={email}
                  >
                    <FormControl>
                      <Checkbox checked={field.value.includes(email)} />
                    </FormControl>
                    {email}
                  </Option>
                ))}
              </Select>
              <FormHelperText>{error?.message}</FormHelperText>
            </FormControl>
          );
        }}
      />
    );
  }
);
DisplayConfigurationCollaborativeUsersField.displayName =
  "DisplayConfigurationCollaborativeUsersField";

export default DisplayConfigurationCollaborativeUsersField as DisplayConfigurationCollaborativeUsersFieldComponent;
