// TODO: Fix joy migration
import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  ModalDialog,
  Typography,
} from "@mui/joy";
import { useCallback, useState } from "react";

interface DeleteConfirmationDialogProps {
  open: boolean;
  onCancel: () => void;
  onDelete: () => Promise<void>;
}

const DeleteConfirmationDialog: React.FC<DeleteConfirmationDialogProps> = ({
  open,
  onCancel,
  onDelete,
}) => {
  const [loading, setLoading] = useState(false);
  const handleDelete = useCallback(async () => {
    try {
      setLoading(true);
      await onDelete();
      onCancel();
    } finally {
      setLoading(false);
    }
  }, [setLoading, onDelete, onCancel]);
  return (
    <Modal open={open}>
      <ModalDialog role="alertdialog">
        <DialogTitle>
          <Box sx={{ color: "error.main", mb: 1, textAlign: "center" }}>
            <FontAwesomeIcon
              fixedWidth={true}
              icon={faExclamationTriangle}
              size="4x"
            />
          </Box>
          <Typography
            align="center"
            level="title-md"
            sx={{ textWrap: "balance" }}
          >
            <strong>
              Are you sure you want to delete the advertiser audience data?
            </strong>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography
            align="center"
            level="body-sm"
            sx={{ textWrap: "balance" }}
          >
            Deleting this data means it is no longer available to any clean
            room. This will delete any insights and the dashboard will no longer
            be viewable for all clean rooms that used this data. Any audiences
            generated through remarketing or lookalike modeling will no longer
            be available to export. Previously exported audiences will still be
            available.
            <br />
            <br />
            If you provision new data to this clean room, any insights or
            audiences will be recalculated. This may take some time, especially
            if using a lookalike audience, as the model needs to be retrained.
          </Typography>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button disabled={loading} onClick={onCancel}>
            Cancel
          </Button>
          <Button
            color="danger"
            disabled={loading}
            loading={loading}
            onClick={handleDelete}
            startDecorator={<FontAwesomeIcon icon={faExclamationTriangle} />}
            variant="solid"
          >
            Delete
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

DeleteConfirmationDialog.displayName = "DeleteConfirmationDialog";

export default DeleteConfirmationDialog;
