import {
  type DataPartnerDisplayConfiguration,
  type PublisherDisplayConfiguration,
} from "@decentriq/graphql/dist/types";
import { faTrashCan } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/joy";
import { useBoolean } from "ahooks";
import { memo, useCallback, useState } from "react";
import { mapErrorToGeneralSnackbar, useGeneralSnackbar } from "hooks";
import ConfigurationDeleteDialog from "../ConfigurationDeleteDialog/ConfigurationDeleteDialog";

interface DeleteDisplayConfigurationButtonProps {
  displayConfiguration:
    | PublisherDisplayConfiguration
    | DataPartnerDisplayConfiguration;
  deleteConfiguration: (id: string) => Promise<void>;
  snackbarOrigin: string;
  errorMessage: string;
  dialogTitle: string;
  dialogDescription: string;
  testId?: string;
  confirmationTestId?: string;
}

const DeleteDisplayConfigurationButton: React.FC<
  DeleteDisplayConfigurationButtonProps
> = ({
  displayConfiguration,
  deleteConfiguration,
  snackbarOrigin,
  errorMessage,
  dialogTitle,
  dialogDescription,
  testId,
  confirmationTestId,
}) => {
  const [isDialogOpen, { setTrue: openDialog, setFalse: closeDialog }] =
    useBoolean(false);
  const [deleting, setDeleting] = useState(false);
  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: snackbarOrigin,
  });
  const displayConfigurationId = displayConfiguration?.id;
  const handleDelete = useCallback(async () => {
    if (!displayConfigurationId) {
      return;
    }
    try {
      setDeleting(true);
      await deleteConfiguration(displayConfigurationId!);
    } catch (error) {
      enqueueSnackbar(...mapErrorToGeneralSnackbar(error, errorMessage));
    } finally {
      setDeleting(false);
    }
  }, [
    displayConfigurationId,
    deleteConfiguration,
    enqueueSnackbar,
    errorMessage,
    setDeleting,
  ]);
  if (!displayConfiguration) {
    return null;
  }
  return (
    <>
      <Button
        color="danger"
        data-testid={testId}
        onClick={openDialog}
        startDecorator={<FontAwesomeIcon fixedWidth={true} icon={faTrashCan} />}
        sx={{ mr: 2 }}
      >
        Delete
      </Button>
      <ConfigurationDeleteDialog
        confirmationTestId={confirmationTestId}
        description={dialogDescription}
        loading={deleting}
        onCancel={closeDialog}
        onConfirm={handleDelete}
        open={isDialogOpen}
        title={dialogTitle}
      />
    </>
  );
};

DeleteDisplayConfigurationButton.displayName =
  "DeleteDisplayConfigurationButton";

export default memo(DeleteDisplayConfigurationButton);
