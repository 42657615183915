import {
  Alert,
  Box,
  Chip,
  Grid,
  styled,
  type Theme,
  Typography,
} from "@mui/joy";
import { grey } from "@mui/material/colors";
import { memo } from "react";
import { usePublishedMediaInsightsDcr } from "features/MediaInsightsDcr";
import {
  AudienceQualityStatisticsDrawer,
  PositiveCurveScoreBar,
  QualityScore,
  useAdvertiserAudienceGenerator,
} from "features/MediaInsightsDcr/components/AdvertiserAudienceGenerator";

export const AudienceQualityStatisticsItemBox = styled(Box)(
  ({
    theme: { radius, spacing },
    height,
  }: { theme: Theme } & { height?: string | number }) => ({
    alignItems: "center",
    border: `1px solid ${grey[300]}`,
    borderRadius: radius["sm"],
    display: "flex",
    flexDirection: "column",
    height: height || "100%",
    justifyContent: "center",
    padding: spacing(1.5),
    width: "100%",
  })
);

const AudienceQualityStatistics: React.FC = () => {
  const { estimatedAudienceSize, selectedLookalikeAudience } =
    useAdvertiserAudienceGenerator();
  const {
    supportedFeatures: {
      enableExtendedLalQualityStats: enableDcrExtendedLalQualityStats = false,
    },
    publisherOrganization,
  } = usePublishedMediaInsightsDcr();
  const enableOrganizationExtendedLalQualityStats =
    publisherOrganization?.enableExtendedLookalikeQualityStatistics || false;
  const formatter = Intl.NumberFormat("en", { notation: "compact" });

  const { test_set_size, test_set_positive_examples_size } =
    selectedLookalikeAudience?.quality?.roc_curve || {};
  const enableExtendedLookalikeQualityStatistics =
    enableDcrExtendedLalQualityStats &&
    enableOrganizationExtendedLalQualityStats;

  return (
    <>
      {enableExtendedLookalikeQualityStatistics && (
        <Typography level="body-sm" mb={1} mt={4}>
          Audience preview reflecting the applied filters
          <Chip color="warning" sx={{ ml: 1 }}>
            Beta
          </Chip>
        </Typography>
      )}
      <Grid
        columnSpacing={enableExtendedLookalikeQualityStatistics ? 2 : 0}
        container={true}
        justifyContent={
          enableExtendedLookalikeQualityStatistics ? "flex-start" : "flex-end"
        }
        mt={1}
        xs={12}
      >
        <Grid xs={enableExtendedLookalikeQualityStatistics ? 2 : 5}>
          <AudienceQualityStatisticsItemBox>
            <Typography fontWeight={500} level="body-sm">
              Estimated audience size
            </Typography>
            <Typography fontSize={36} fontWeight={600}>
              {formatter.format(estimatedAudienceSize)}
            </Typography>
          </AudienceQualityStatisticsItemBox>
        </Grid>
        {enableExtendedLookalikeQualityStatistics && (
          <>
            <Grid xs={2}>
              <AudienceQualityStatisticsItemBox>
                <QualityScore />
              </AudienceQualityStatisticsItemBox>
            </Grid>
            <Grid container={true} mt={0} xs={8}>
              <AudienceQualityStatisticsItemBox>
                <PositiveCurveScoreBar />
              </AudienceQualityStatisticsItemBox>
            </Grid>
          </>
        )}
      </Grid>
      {(test_set_positive_examples_size || test_set_size) < 50 && (
        <Alert color="warning" sx={{ mt: 1 }}>
          There were too few individuals in the overlapping audience to create a
          large holdout group for testing. The quality metric was evaluated with
          only {test_set_positive_examples_size || test_set_size} individuals
          {test_set_positive_examples_size ? "in the seed audience" : ""}, which
          may result in a less accurate assessment of model quality
        </Alert>
      )}
      {enableExtendedLookalikeQualityStatistics && (
        <AudienceQualityStatisticsDrawer />
      )}
    </>
  );
};

AudienceQualityStatistics.displayName = "AudienceQualityStatistics";

export default memo(AudienceQualityStatistics);
