import { testIds } from "@decentriq/utils";
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Stack,
  Typography,
} from "@mui/joy";
import { memo } from "react";
import { Controller, useFormContext } from "react-hook-form";

const DataRoomName: React.FC = memo(() => {
  const { control } = useFormContext();
  return (
    <Stack>
      <Typography level="title-md">1. Data clean room configuration</Typography>
      <Controller
        control={control}
        name="dataRoomName"
        render={({ field, fieldState }) => {
          const { error } = fieldState;
          return (
            <FormControl
              disabled={field.disabled}
              error={Boolean(error)}
              required={true}
            >
              <FormLabel>Name</FormLabel>
              <Input
                data-testid={testIds.measurementDcr.dataRoomName.nameInput}
                error={Boolean(error)}
                placeholder={`e.g. "My media clean room"`}
                {...field}
              />
              <FormHelperText>{error?.message}</FormHelperText>
            </FormControl>
          );
        }}
      />
    </Stack>
  );
});
DataRoomName.displayName = "DataRoomName";

export default DataRoomName;
