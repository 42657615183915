import {
  type DqDatasetImportFormProviderSchemaType,
  DqDatasetSyncActions,
  DqDatasetSyncForm,
  DqDatasetSyncFormControls,
  type DqDatasetSyncFormProps,
  DqDatasetSyncTitle,
  SyncDirectionType,
} from "@decentriq/components";
import { type DataSourceType } from "@decentriq/graphql/dist/types";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  type DrawerProps,
  ModalDialog,
} from "@mui/joy";
import { memo } from "react";
import { useWizard } from "react-use-wizard";

const FORM_ID = "import-dataset-form";

interface CreateImportStepProps {
  onClose: DrawerProps["onClose"];
  onSubmit: DqDatasetSyncFormProps<DqDatasetImportFormProviderSchemaType>["onSubmit"];
  syncType: DataSourceType;
}

const CreateImportStep: React.FC<CreateImportStepProps> = memo(
  ({ syncType, onSubmit }) => {
    const { previousStep, nextStep } = useWizard();
    return (
      <DqDatasetSyncForm<DqDatasetImportFormProviderSchemaType>
        id={FORM_ID}
        onSubmit={(formValues, event) =>
          onSubmit(formValues, event).then(() => nextStep())
        }
      >
        <ModalDialog>
          <DialogTitle>
            <DqDatasetSyncTitle
              syncDirection={SyncDirectionType.IMPORT}
              syncType={syncType}
            />
          </DialogTitle>
          <Divider />
          <DialogContent>
            <DqDatasetSyncFormControls
              syncDirection={SyncDirectionType.IMPORT}
              syncType={syncType}
            />
          </DialogContent>
          <Divider />
          <DialogActions>
            <DqDatasetSyncActions
              form={FORM_ID}
              resetButtonProps={{
                children: "Back",
                onClick: previousStep,
              }}
              submitButtonProps={{ children: "Import" }}
            />
          </DialogActions>
        </ModalDialog>
      </DqDatasetSyncForm>
    );
  }
);
CreateImportStep.displayName = "CreateImportStep";

export default CreateImportStep;
