import { Box, Grid } from "@mui/joy";
import { memo } from "react";
import {
  OrganizationArchiveButton,
  OrganizationDataConnectorConfigurationsEditor,
  OrganizationDetails,
  OrganizationDomainEditor,
  OrganizationEnclaveWorkersEditor,
  OrganizationFeaturesEditor,
  OrganizationLicenseEditor,
  OrganizationLogoEditor,
  OrganizationMediaScopeEditor,
  OrganizationNameEditor,
} from "containers";
import { useUserRole } from "hooks";

const OrganizationForm: React.FC<{ organizationId: string }> = ({
  organizationId,
}) => {
  const { isSuperAdmin, isSuperAdminReadOnly } = useUserRole();
  return (
    <Box
      sx={{
        alignItems: "flex-start",
        background: "white",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid container={true} justifyContent="space-between" mb={2} xs={12}>
        <OrganizationLogoEditor organizationId={organizationId} />
        {isSuperAdmin && (
          <OrganizationArchiveButton organizationId={organizationId} />
        )}
      </Grid>
      <Grid columnSpacing={{ md: 6, xs: 0 }} container={true} xs={12}>
        <Grid md={7} xs={12}>
          <OrganizationNameEditor organizationId={organizationId} />
          <OrganizationLicenseEditor organizationId={organizationId} />
        </Grid>
        {(isSuperAdmin || isSuperAdminReadOnly) && (
          <Grid mb={1} md={5} xs={12}>
            <OrganizationDetails organizationId={organizationId} />
          </Grid>
        )}
      </Grid>
      <Grid container={true} mb={1} xs={12}>
        <OrganizationEnclaveWorkersEditor organizationId={organizationId} />
      </Grid>
      <Grid container={true} mb={1} xs={12}>
        <OrganizationFeaturesEditor organizationId={organizationId} />
      </Grid>
      <Grid container={true}>
        <OrganizationDomainEditor organizationId={organizationId} />
      </Grid>
      {(isSuperAdmin || isSuperAdminReadOnly) && (
        <>
          <Grid md={8} mt={1} xs={11}>
            <OrganizationMediaScopeEditor organizationId={organizationId} />
          </Grid>
          <Grid md={8} mt={1} xs={11}>
            <OrganizationDataConnectorConfigurationsEditor
              organizationId={organizationId}
            />
          </Grid>
        </>
      )}
    </Box>
  );
};

OrganizationForm.displayName = "OrganizationForm";

export default memo(OrganizationForm);
