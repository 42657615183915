import { InfoTooltip } from "@decentriq/components";
import { testIds } from "@decentriq/utils";
import {
  faCheck,
  faFileCheck,
  faFolderOpen,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  Input,
  Modal,
  ModalDialog,
  Radio,
  RadioGroup,
  Tooltip,
} from "@mui/joy";
import { type Theme } from "@mui/material";
import { format, isValid as isDateValid } from "date-fns";
import { type ChangeEvent, useEffect, useState } from "react";
import { Controller, useForm, useFormState } from "react-hook-form";
import { makeStyles } from "tss-react/mui";
import * as yup from "yup";
import { CommonSnackbarOrigin, useGeneralSnackbar } from "hooks";
import { getDcrProperties } from "utils/apicore";

const useDataRoomCreateDialogStyles = makeStyles()((theme: Theme) => ({
  configurationSourceImportFileWrapper: {
    "&:hover": {
      background: theme.palette.action.selected,
    },
    alignItems: "center",
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    lineHeight: 1,
    margin: theme.spacing(-0.5),
    marginLeft: 0,
    padding: theme.spacing(0.5),
  },
  configurationSourceImportFromWrapper: {
    marginRight: theme.spacing(2),
  },
  configurationSourceImportWrapper: {
    alignItems: "center",
    display: "flex",
    flexWrap: "wrap",
  },
}));

const CONFIGURATION_SOURCE_NONE = "none";
const CONFIGURATION_SOURCE_IMPORT = "import";
const CONFIGURATION_SOURCE_CLONE = "clone";
const CONFIGURATION_SOURCE_TEMPLATE = "template";
const IS_FEATURE_CONFIGURATION_SOURCE_NONE_ENABLED = true;
const IS_FEATURE_CONFIGURATION_SOURCE_IMPORT_ENABLED = true;
const IS_FEATURE_CONFIGURATION_SOURCE_CLONE_ENABLED = false;
const IS_FEATURE_CONFIGURATION_SOURCE_TEMPLATE_ENABLED = false;
const IS_DETAILED_IMPORT_FILE_PREVIEW = false;

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required("Must specify name")
    .min(1, "Minimum 1 character"),
});

const FORM_ID = "create-data-science-data-room-form";

interface DataScienceDataRoomCreateDialogProps {
  open: boolean;
  initialConfigurationSource?: string;
  loading: boolean;
  onCancel: () => void;
  onConfirm: (
    config: {
      name: string;
      description?: string;
      configuration?: any;
    },
    onComplete: () => void
  ) => void;
  restartCreation: () => void;
}

const DataScienceDataRoomCreateDialog: React.FC<
  DataScienceDataRoomCreateDialogProps
> = ({
  initialConfigurationSource = CONFIGURATION_SOURCE_NONE,
  open,
  loading,
  onCancel,
  onConfirm,
  restartCreation,
}) => {
  const { classes: dataRoomCreateDialogClasses } =
    useDataRoomCreateDialogStyles();
  const {
    configurationSourceImportWrapper,
    configurationSourceImportFromWrapper,
    configurationSourceImportFileWrapper,
  } = dataRoomCreateDialogClasses;
  const [configuration, setConfiguration] = useState();
  const [configurationSource, setConfigurationSource] = useState(
    initialConfigurationSource
  );
  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: CommonSnackbarOrigin.DASHBOARD,
  });
  const { handleSubmit, control, reset, watch, setValue } = useForm({
    defaultValues: {
      description: "",
      name: "",
    },
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(validationSchema),
  });
  // Name that is entered manually to the input
  const dcrName = watch("name");
  const { isSubmitting } = useFormState({ control });
  // TODO: use a hidden input with React ref instead of programatically creating one
  const [importFile, setImportFile] = useState();
  const { lastModifiedDate, name, size, type } = importFile || {};
  const selectFile = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".json,application/json";
    input.onchange = (event) => {
      const file = event.target.files[0];
      setImportFile(file);
      event.target.value = "";
    };
    input.click();
  };
  const changeFile = (event) => {
    event.stopPropagation();
    selectFile();
  };
  const changeConfigurationSource = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (
      !IS_DETAILED_IMPORT_FILE_PREVIEW &&
      value === CONFIGURATION_SOURCE_IMPORT
    ) {
      selectFile();
      return;
    }
    if (value === CONFIGURATION_SOURCE_NONE && configuration) {
      setConfiguration(undefined);
      setImportFile(undefined);
      // Reset should ne only when changing from import to scratch
      reset({ description: "", name: "" });
    }
    setConfigurationSource(event.target.value);
  };
  useEffect(() => {
    setConfigurationSource(initialConfigurationSource);
  }, [initialConfigurationSource]);
  useEffect(() => {
    if (importFile) {
      const reader = new FileReader();
      reader.onload = function (event) {
        try {
          const content = JSON.parse(event.target.result);
          if (!IS_DETAILED_IMPORT_FILE_PREVIEW) {
            setConfigurationSource(CONFIGURATION_SOURCE_IMPORT);
          }
          const { title: dataRoomTitle } = getDcrProperties(
            content.dataScienceDataRoom
          );
          if (!dcrName) {
            setValue("name", dataRoomTitle);
          }
          setConfiguration(content);
        } catch (error) {
          enqueueSnackbar("Failed to import configuration", {
            context: error?.message,
            persist: true,
            variant: "error",
          });
        }
      };
      reader.readAsText(importFile);
    }
  }, [importFile]);
  useEffect(() => {
    if (open) {
      setConfigurationSource(initialConfigurationSource);
    } else {
      setConfiguration(undefined);
      setImportFile(undefined);
    }
  }, [open, initialConfigurationSource]);
  const onSubmit = ({ name, description }: any) => {
    onConfirm(
      {
        configuration,
        description,
        name,
      },
      () => reset({ description: "", name: "" })
    );
  };
  return (
    <form id={FORM_ID} onSubmit={handleSubmit(onSubmit)}>
      <Modal onClose={!loading ? onCancel : undefined} open={open}>
        <ModalDialog>
          <DialogTitle>
            Start drafting an Advanced Analytics Clean Room
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Box>
              <Grid
                alignItems="center"
                container={true}
                justifyContent="center"
                spacing={1}
              >
                <Grid xs={true}>
                  <span>Name</span>
                </Grid>
              </Grid>
              <Grid
                alignItems="center"
                container={true}
                justifyContent="center"
                spacing={1}
              >
                <Grid xs={true}>
                  <Controller
                    control={control}
                    name="name"
                    render={({ field }) => (
                      <FormControl>
                        <Input
                          {...field}
                          autoComplete="off"
                          autoFocus={true}
                          data-testid={
                            testIds.dataScienceDataRoom
                              .dataScienceDataRoomCreateDialog.nameInput
                          }
                          placeholder="E.g. Collaborative analytics"
                        />
                      </FormControl>
                    )}
                  />
                </Grid>
              </Grid>
              <Box style={{ padding: "0.5rem 0" }}>
                <Grid
                  alignItems="center"
                  container={true}
                  justifyContent="center"
                  spacing={1}
                >
                  <Grid xs={true}>
                    <FormControl>
                      <RadioGroup
                        onChange={changeConfigurationSource}
                        value={configurationSource}
                      >
                        {IS_FEATURE_CONFIGURATION_SOURCE_NONE_ENABLED ? (
                          <Radio
                            label="Start from scratch"
                            value={CONFIGURATION_SOURCE_NONE}
                          />
                        ) : null}
                        {IS_FEATURE_CONFIGURATION_SOURCE_IMPORT_ENABLED ? (
                          <Radio
                            label={
                              <div className={configurationSourceImportWrapper}>
                                <div
                                  className={
                                    configurationSourceImportFromWrapper
                                  }
                                  data-testid={
                                    testIds.dataScienceDataRoom
                                      .dataScienceDataRoomCreateDialog
                                      .importConfig
                                  }
                                >
                                  Import existing data clean room configuration
                                  <InfoTooltip tooltip="Please choose a data clean room configuration JSON file. You can export a valid data clean room configuration from any existing data clean room." />
                                </div>
                                {importFile ? (
                                  <Tooltip
                                    disableHoverListener={
                                      configurationSource !==
                                      CONFIGURATION_SOURCE_IMPORT
                                    }
                                    placement="top"
                                    title={
                                      <div>
                                        <div>
                                          {name} will be used as the start
                                          configuration
                                        </div>
                                        <div
                                          style={{
                                            opacity: 0.75,
                                          }}
                                        >
                                          ({type}, {size} bytes,
                                          {isDateValid(lastModifiedDate)
                                            ? ` last modified on ${format(
                                                lastModifiedDate,
                                                "PPPP'\nat 'pppp"
                                              )}`
                                            : null}
                                          )
                                        </div>
                                      </div>
                                    }
                                  >
                                    <div
                                      className={
                                        configurationSourceImportFileWrapper
                                      }
                                      onClick={changeFile}
                                    >
                                      <FontAwesomeIcon
                                        icon={faFileCheck}
                                        style={{ marginRight: "4px" }}
                                      />
                                      {name}
                                    </div>
                                  </Tooltip>
                                ) : IS_DETAILED_IMPORT_FILE_PREVIEW ? (
                                  <div
                                    className={
                                      configurationSourceImportFileWrapper
                                    }
                                    onClick={changeFile}
                                  >
                                    <FontAwesomeIcon
                                      icon={faFolderOpen}
                                      style={{ marginRight: "4px" }}
                                    />
                                    Select file
                                  </div>
                                ) : undefined}
                              </div>
                            }
                            value={CONFIGURATION_SOURCE_IMPORT}
                          />
                        ) : null}
                        {IS_FEATURE_CONFIGURATION_SOURCE_CLONE_ENABLED ? (
                          <Radio
                            disabled={true}
                            label="Clone data clean room"
                            value={CONFIGURATION_SOURCE_CLONE}
                          />
                        ) : null}
                        {IS_FEATURE_CONFIGURATION_SOURCE_TEMPLATE_ENABLED ? (
                          <Radio
                            disabled={true}
                            label="Use template"
                            value={CONFIGURATION_SOURCE_TEMPLATE}
                          />
                        ) : null}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button form={FORM_ID} onClick={restartCreation} type="reset">
              Back
            </Button>
            <Button
              color="primary"
              data-testid={
                testIds.dataScienceDataRoom.dataScienceDataRoomCreateDialog
                  .createButton
              }
              disabled={!dcrName || isSubmitting}
              form={FORM_ID}
              loading={loading}
              loadingPosition="start"
              startDecorator={<FontAwesomeIcon icon={faCheck} />}
              type="submit"
              variant="solid"
            >
              Create
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </form>
  );
};

export default DataScienceDataRoomCreateDialog;
