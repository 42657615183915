import { InlineEditor } from "@decentriq/components";
import {
  useCreateStaticScriptMutation,
  useDeleteStaticScriptMutation,
  useUpdateScriptContentMutation,
  useUpdateStaticScriptNameMutation,
} from "@decentriq/graphql/dist/hooks";
import {
  type DraftScript,
  type PublishedScript,
  StaticScriptFragment,
} from "@decentriq/graphql/dist/types";
import {
  faCaretLeft,
  faCaretRight,
  faPen,
  faPlus,
  faTimes,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid, IconButton, Tab, TabList, Tabs, Tooltip } from "@mui/joy";
import { grey } from "@mui/material/colors";
import { useBoolean, useDebounceFn } from "ahooks";
import { useCallback, useRef, useState } from "react";
import {
  type ImperativePanelHandle,
  Panel,
  PanelGroup,
} from "react-resizable-panels";
import { PlainTextEditorField, ScriptingEditorField } from "components";
import { mapDraftDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import { DEFAULT_SCRIPT } from "models";
import { FileExplorer, InputsEditor } from "./components";
import useScriptingComputeNode from "./useScriptingComputeNode";

const DataRoomComputeNodeTabPanel = (props: any) => {
  const { children, value, index, ...rest } = props;
  return (
    <div
      hidden={value !== index}
      role="tabpanel"
      style={{ height: "100%" }}
      {...rest}
    >
      {value === index && (
        <Box sx={{ height: "100%" }}>
          <Box
            sx={{
              alignItems: "stretch",
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "stretch",
            }}
          >
            {children}
          </Box>
        </Box>
      )}
    </div>
  );
};

interface ScriptingComputeNodeEditorProps {
  computeNodeId: string;
  readOnly?: boolean;
  editorOptions?: object;
  fullHeight?: boolean;
}

const ScriptingComputeNodeEditor: React.FC<ScriptingComputeNodeEditorProps> = ({
  computeNodeId,
  readOnly,
  editorOptions,
  fullHeight = false,
}) => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const [
    isFileExplorerExpanded,
    { setTrue: expandFileExplorer, setFalse: collapseFileExplorer },
  ] = useBoolean(true);
  const { scriptingLanguage, scripts } = useScriptingComputeNode(computeNodeId);
  const mainScript = scripts?.find(
    ({ isMainScript }: DraftScript | PublishedScript) => !!isMainScript
  );
  const hasMainScript = !!mainScript;
  const mainScriptDefaultValue =
    mainScript?.content ?? DEFAULT_SCRIPT.get(scriptingLanguage!);
  const staticScripts =
    scripts?.filter(
      ({ isMainScript }: DraftScript | PublishedScript) => !isMainScript
    ) || [];
  const staticScriptsOffset = hasMainScript ? 1 : 0;
  // Tabs
  const [activeTab, setActiveTab] = useState<number>(0);
  const onChangeTab = useCallback((event: any, value: number) => {
    setActiveTab(value);
  }, []);
  // Create static script
  const [createStaticScriptMutation] = useCreateStaticScriptMutation({
    onError: (error) => {
      enqueueSnackbar(
        ...mapDraftDataRoomErrorToSnackbar(
          error,
          "The new script could not be created."
        )
      );
    },
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          scripts: (existing = {}) => {
            const scriptRef = cache.writeFragment({
              data: data?.draftScriptingNode?.createScript?.record,
              fragment: StaticScriptFragment,
            });
            return {
              ...existing,
              nodes: [...(existing?.nodes || []), scriptRef],
            };
          },
        },
        id: cache.identify({
          __typename: "DraftScriptingNode",
          id: computeNodeId,
        }),
      });
    },
    variables: {
      input: {
        draftScriptingNodeId: computeNodeId,
        isMainScript: !hasMainScript,
      },
    },
  });
  const onAddTab = useCallback(
    () => createStaticScriptMutation(),
    [createStaticScriptMutation]
  );
  // Update static script name
  const [updateStaticScriptNameMutation] = useUpdateStaticScriptNameMutation({
    onError: (error) => {
      enqueueSnackbar(
        ...mapDraftDataRoomErrorToSnackbar(
          error,
          "The script could not be renamed."
        )
      );
    },
  });
  const onRenameTab = useCallback(
    (name: string, scriptId: string) => {
      updateStaticScriptNameMutation({
        variables: {
          input: {
            id: scriptId,
            name,
          },
        },
      });
    },
    [updateStaticScriptNameMutation]
  );
  // Delete static script
  const [deleteStaticScriptMutation] = useDeleteStaticScriptMutation({
    onError: (error) => {
      enqueueSnackbar(
        ...mapDraftDataRoomErrorToSnackbar(
          error,
          "The script could not be deleted."
        )
      );
    },
  });
  const onDeleteTab = useCallback(
    (scriptId: string, index: number) => {
      deleteStaticScriptMutation({
        onCompleted: () => {
          if (activeTab === index) {
            setActiveTab(index - 1);
          }
        },
        update: (cache) => {
          cache.evict({
            id: cache.identify({
              __typename: "DraftScript",
              id: scriptId,
            }),
          });
          cache.gc();
        },
        variables: {
          scriptId,
        },
      });
    },
    [activeTab, deleteStaticScriptMutation]
  );
  // Update script content
  const [updateScriptContentMutation] = useUpdateScriptContentMutation({
    onError: (error) => {
      enqueueSnackbar("The script content could not be updated.", {
        context: error?.message,
        persist: true,
        variant: "error",
      });
    },
  });
  const updateScriptContent = useCallback(
    (scriptId: string, value?: string) => {
      return updateScriptContentMutation({
        variables: {
          input: {
            content: value,
            id: scriptId,
          },
        },
      });
    },
    [updateScriptContentMutation]
  );
  const { run: debouncedUpdateScriptContent } = useDebounceFn(
    updateScriptContent,
    { wait: 750 }
  );

  const fileExplorerRef = useRef<ImperativePanelHandle>(null);

  return (
    <Grid
      columnSpacing={isFileExplorerExpanded ? 1 : 0}
      container={true}
      sx={{ height: "100%" }}
    >
      <PanelGroup direction="horizontal">
        <Panel defaultSize={70}>
          <Grid container={true} direction="row" height="100%">
            <Box
              display="flex"
              flexDirection="column"
              height="100%"
              marginRight={1}
              width="calc(100% - 24px)"
            >
              <Box sx={{ borderBottom: 0, borderColor: "divider" }}>
                <Tabs onChange={onChangeTab} value={activeTab}>
                  <TabList>
                    {mainScript ? <Tab>Main script</Tab> : null}
                    {staticScripts.map(
                      (
                        script: DraftScript | PublishedScript,
                        index: number
                      ) => (
                        <Tab key={index} value={script.name}>
                          <Box sx={{ display: "flex" }}>
                            <Box sx={{ display: "flex", m: 0.5 }}>
                              <InlineEditor
                                cancelEditingButtonEnabled={false}
                                onChange={(name: string) =>
                                  onRenameTab(name, (script as DraftScript).id)
                                }
                                placeholder="Name"
                                readOnly={readOnly}
                                saveEditingButtonEnabled={false}
                                saveEditingOnClickAway={true}
                                startEditingButtonEnabled={!readOnly}
                                startEditingButtonIcon={faPen}
                                startEditingButtonTooltipTitle="Rename"
                                startEditingOnContentClick={false}
                                validate={(value: string) => {
                                  if (!value?.trim()) {
                                    return "Name must be set";
                                  }
                                  if (
                                    staticScripts.some(
                                      ({
                                        name,
                                      }: DraftScript | PublishedScript) =>
                                        name === value.trim()
                                    )
                                  ) {
                                    return "Name must be unique";
                                  }
                                }}
                                value={script.name || undefined}
                              />
                            </Box>
                            {!readOnly ? (
                              <Tooltip placement="top" title="Delete">
                                <IconButton
                                  onClick={() =>
                                    onDeleteTab(
                                      (script as DraftScript).id,
                                      index
                                    )
                                  }
                                >
                                  <FontAwesomeIcon
                                    fixedWidth={true}
                                    icon={faTimes}
                                  />
                                </IconButton>
                              </Tooltip>
                            ) : null}
                          </Box>
                        </Tab>
                      )
                    )}
                    {!readOnly ? (
                      <IconButton
                        onClick={onAddTab}
                        sx={{ pb: 1, pl: 0.375, pr: 0.375, pt: 0.375 }}
                      >
                        <FontAwesomeIcon fixedWidth={true} icon={faPlus} />
                      </IconButton>
                    ) : null}
                  </TabList>
                </Tabs>
              </Box>
              {mainScript ? (
                <DataRoomComputeNodeTabPanel index={0} value={activeTab}>
                  <ScriptingEditorField
                    defaultLanguage={scriptingLanguage!.toLowerCase()}
                    defaultValue={mainScriptDefaultValue}
                    editorOptions={editorOptions}
                    onChange={(value) =>
                      debouncedUpdateScriptContent(
                        (mainScript as DraftScript | undefined)?.id || "",
                        value
                      )
                    }
                  />
                </DataRoomComputeNodeTabPanel>
              ) : null}
              {staticScripts.map(
                (script: DraftScript | PublishedScript, index: number) => (
                  <DataRoomComputeNodeTabPanel
                    index={index + staticScriptsOffset}
                    key={`${script.name}-${index + staticScriptsOffset}`}
                    value={activeTab}
                  >
                    <PlainTextEditorField
                      defaultValue={script.content || ""}
                      editorOptions={{
                        ...(readOnly ? { height: 560 } : {}),
                        readOnly: readOnly,
                        ...editorOptions,
                      }}
                      onChange={(value) =>
                        debouncedUpdateScriptContent(
                          (script as DraftScript).id,
                          value || ""
                        )
                      }
                    />
                  </DataRoomComputeNodeTabPanel>
                )
              )}
            </Box>
            <Box
              sx={{
                alignItems: "center",
                background: grey[100],
                display: "flex",
                height: "100%",
                justifyContent: "center",
                width: "16px",
              }}
            >
              <FontAwesomeIcon
                color={grey[500]}
                fixedWidth={true}
                icon={isFileExplorerExpanded ? faCaretRight : faCaretLeft}
                onClick={() => {
                  if (isFileExplorerExpanded) {
                    fileExplorerRef.current?.collapse();
                    collapseFileExplorer();
                    return;
                  }
                  fileExplorerRef.current?.expand();
                  expandFileExplorer();
                }}
                style={{
                  cursor: "pointer",
                }}
              />
            </Box>
          </Grid>
        </Panel>
        <Panel
          collapsedSize={0}
          collapsible={true}
          id="file-explorer"
          minSize={10}
          onCollapse={collapseFileExplorer}
          onExpand={expandFileExplorer}
          ref={fileExplorerRef}
        >
          <Grid height="auto" overflow="auto">
            <InputsEditor computeNodeId={computeNodeId} readOnly={readOnly} />
            <FileExplorer
              computeNodeId={computeNodeId}
              fullHeight={fullHeight}
              readOnly={readOnly}
            />
          </Grid>
        </Panel>
      </PanelGroup>
    </Grid>
  );
};
ScriptingComputeNodeEditor.displayName = "ScriptingComputeNodeEditor";

export default ScriptingComputeNodeEditor;
