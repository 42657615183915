// TODO: Fix joy migration
import { type Reference } from "@apollo/client";
import { useUnpublishDatasetMutation } from "@decentriq/graphql/dist/hooks";
import {
  PublishedNodeIdDocument,
  type PublishedNodeIdQuery,
} from "@decentriq/graphql/dist/types";
import { faLinkSlash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress, IconButton, Tooltip } from "@mui/joy";
import { type Theme } from "@mui/material";
import { memo, useCallback } from "react";
import { makeStyles } from "tss-react/mui";
import { mapErrorToGeneralSnackbar, useDataRoomSnackbar } from "hooks";

const useIconButtonStyles = makeStyles()((theme: Theme) => ({
  root: {
    borderRadius: "100%",
    minWidth: 0,
    padding: theme.spacing(0.5),
  },
}));

type DeprovisionDatasetButtonProps = {
  datasetId: string;
  dataRoomId: string;
  driverAttestationHash: string;
  leaf: {
    commitId: string | null;
    computeNodeId: string;
  };
};

const DeprovisionDatasetButton: React.FC<DeprovisionDatasetButtonProps> = ({
  datasetId,
  dataRoomId,
  leaf,
  driverAttestationHash,
}) => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { classes: iconButtonClasses } = useIconButtonStyles();
  const [unpublishDatasetMutation, { loading: deprovisioning }] =
    useUnpublishDatasetMutation({
      onCompleted: () => {
        enqueueSnackbar(
          "Dataset has been successfully deprovisioned from the data clean room."
        );
      },
      onError: (error) => {
        enqueueSnackbar(
          ...mapErrorToGeneralSnackbar(
            error,
            `Dataset could not be deprovisioned from the data clean room.`
          )
        );
      },
    });
  const dataNodeId = leaf.computeNodeId.replace("_leaf", "");
  const handleDeprovisionDataset = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      await unpublishDatasetMutation({
        update: (cache, { data }) => {
          cache.modify({
            fields: {
              publications: (existing = { nodes: [] }, { readField }) => {
                return {
                  ...existing,
                  nodes: existing.nodes.filter(
                    ({ dataRoom }: { dataRoom: Reference }) =>
                      readField("id", dataRoom) !== dataRoomId
                  ),
                };
              },
            },
            id: cache.identify({
              __typename: "Dataset",
              id: datasetId,
            }),
          });
          const node = cache.readQuery<PublishedNodeIdQuery>({
            query: PublishedNodeIdDocument,
            variables: {
              commitId: null,
              dcrHash: dataRoomId,
              driverAttestationHash,
              nodeId: dataNodeId,
            },
          });
          if (node?.publishedNode) {
            cache.modify({
              fields: {
                dataset: (existing = {}) => {
                  return null;
                },
              },
              id: cache.identify({
                __typename: node!.publishedNode!.__typename,
                commitId: null,
                dcrHash: dataRoomId,
                driverAttestationHash,
                id: dataNodeId,
              }),
            });
          }
        },
        variables: {
          input: {
            dataNodeId,
            dcrHash: dataRoomId,
            driverAttestationHash,
          },
        },
      });
    },
    [
      unpublishDatasetMutation,
      dataNodeId,
      datasetId,
      dataRoomId,
      driverAttestationHash,
    ]
  );
  return (
    <Tooltip placement="top" title={"Deprovision dataset"}>
      <IconButton
        classes={iconButtonClasses}
        disabled={deprovisioning}
        onClick={handleDeprovisionDataset}
      >
        {deprovisioning ? (
          <CircularProgress sx={{ "--CircularProgress-size": "15px" }} />
        ) : (
          <FontAwesomeIcon fixedWidth={true} icon={faLinkSlash} />
        )}
      </IconButton>
    </Tooltip>
  );
};

DeprovisionDatasetButton.displayName = "DeprovisionDatasetButton";

export default memo(DeprovisionDatasetButton);
