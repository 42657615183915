import { testIds } from "@decentriq/utils";
import { Box, Tab, TabList, TabPanel, Tabs, Typography } from "@mui/joy";
import { Link } from "react-router-dom";
import { DataRoomsList } from "components";
import { DataLabs } from "features/dataLabs";
import { PublisherConfigurations } from "features/publisherPortal/components";
import { PublisherPortalWrapper } from "features/publisherPortal/contexts";
import { DataRoomTypeNames } from "models";

export enum PublisherPortalTabs {
  DATAROOMS = "datarooms",
  DATA_LABS = "datalabs",
  PUBLISHER_CONFIGURATIONS = "publisher-configurations",
}

interface PublisherPortalProps {
  activeTab: PublisherPortalTabs;
  baseUrl: string;
}

const PublisherPortal: React.FC<PublisherPortalProps> = ({
  activeTab,
  baseUrl,
}) => {
  return (
    <Tabs value={activeTab}>
      <Box
        sx={{
          backgroundColor: "background.bar",
          display: "flex",
          justifyContent: "space-between",
          padding: 2,
        }}
      >
        <Typography level="title-md">
          <strong>Publisher portal</strong>
        </Typography>
      </Box>
      <Box sx={{ backgroundColor: "background.bar" }}>
        <TabList>
          <Tab
            component={Link}
            data-testid={testIds.publisherPortal.tabList.dcrList}
            to={`${baseUrl}/${PublisherPortalTabs.DATAROOMS}`}
            value={PublisherPortalTabs.DATAROOMS}
          >
            DCRs
          </Tab>
          <Tab
            component={Link}
            data-testid={testIds.publisherPortal.tabList.dataLabs}
            to={`${baseUrl}/${PublisherPortalTabs.DATA_LABS}`}
            value={PublisherPortalTabs.DATA_LABS}
          >
            Datalabs
          </Tab>
          <Tab
            component={Link}
            data-testid={
              testIds.publisherPortal.tabList.publisherConfigurations
            }
            to={`${baseUrl}/${PublisherPortalTabs.PUBLISHER_CONFIGURATIONS}`}
            value={PublisherPortalTabs.PUBLISHER_CONFIGURATIONS}
          >
            Configurations
          </Tab>
        </TabList>
      </Box>
      <Box sx={{ flex: 1, margin: 2.5, overflow: "hidden" }}>
        <TabPanel
          sx={{
            flexDirection: "column",
            height: "100%",
            padding: "0 !important",
          }}
          value={PublisherPortalTabs.DATAROOMS}
        >
          <DataRoomsList
            exposedDataRoomTypes={[
              DataRoomTypeNames.PublishedMediaDataRoom,
              DataRoomTypeNames.PublishedLookalikeMediaDataRoom,
              DataRoomTypeNames.PublishedMediaInsightsDcr,
            ]}
          />
        </TabPanel>
        <TabPanel
          sx={{
            flexDirection: "column",
            height: "100%",
            padding: "0 !important",
          }}
          value={PublisherPortalTabs.DATA_LABS}
        >
          <DataLabs />
        </TabPanel>
        <TabPanel
          sx={{
            flexDirection: "column",
            height: "100%",
            padding: "0 !important",
          }}
          value={PublisherPortalTabs.PUBLISHER_CONFIGURATIONS}
        >
          <PublisherPortalWrapper>
            <PublisherConfigurations />
          </PublisherPortalWrapper>
        </TabPanel>
      </Box>
    </Tabs>
  );
};

PublisherPortal.displayName = "PublisherPortal";

export default PublisherPortal;
