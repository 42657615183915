import { type IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faBookOpen,
  faFileSignature,
  faQuestionCircle,
  faRocketLaunch,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  type SxProps,
} from "@mui/material";
import { useBoolean } from "ahooks";
import { Fragment, memo } from "react";
import { SupportDialog } from "components";
import { useDocsLink } from "hooks";

type UserSupportMenuItemProps = {
  icon: IconProp;
  onClick?: () => void;
  title: string;
  component?: React.ElementType;
  href?: string;
  target?: string;
  sx?: SxProps;
};

const UserSupportMenuItem: React.FC<UserSupportMenuItemProps> = ({
  icon,
  onClick,
  title,
  ...restProps
}) => {
  return (
    <ListItem disableGutters={true}>
      <ListItemButton
        onClick={onClick}
        sx={{ flexDirection: "column" }}
        {...restProps}
      >
        <ListItemIcon sx={{ minWidth: "auto" }}>
          <FontAwesomeIcon fixedWidth={true} icon={icon} size="lg" />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            align: "center",
            color: "inherit",
            component: "span",
            noWrap: true,
            variant: "body2",
          }}
        >
          {title}
        </ListItemText>
      </ListItemButton>
    </ListItem>
  );
};

const UserSupportMenu: React.FC = () => {
  const [
    isSupportDialogOpen,
    { setFalse: closeSupportDialog, setTrue: openSupportDialog },
  ] = useBoolean(false);
  const docsBaseLink = useDocsLink();
  return (
    <Fragment>
      <List disablePadding={true} sx={{ display: "flex" }}>
        <UserSupportMenuItem
          component={Link}
          href={`${docsBaseLink}/release-notes`}
          icon={faRocketLaunch}
          target="_blank"
          title="Release notes"
        />
        <UserSupportMenuItem
          component={Link}
          href={docsBaseLink}
          icon={faBookOpen}
          target="_blank"
          title="Documentation"
        />
        <UserSupportMenuItem
          component={Link}
          href="https://www.decentriq.com/end-user-terms"
          icon={faFileSignature}
          target="_blank"
          title="End-user terms"
        />
        <UserSupportMenuItem
          icon={faQuestionCircle}
          onClick={openSupportDialog}
          title="Contact us"
        />
      </List>
      <SupportDialog onClose={closeSupportDialog} open={isSupportDialogOpen} />
    </Fragment>
  );
};
UserSupportMenu.displayName = "UserSupportMenu";

export default memo(UserSupportMenu);
