import { faArrowUpRightFromSquare } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@mui/joy";
import { useBoolean } from "ahooks";
import { memo } from "react";
import { DatasetPopup } from "features/datasets";

type PreviewDatasetButtonProps = {
  datasetHash: string;
};

const PreviewDatasetButton: React.FC<PreviewDatasetButtonProps> = ({
  datasetHash,
}) => {
  const [
    isDatasetPopupOpen,
    { setTrue: openDatasetPopup, setFalse: closeDatasetPopup },
  ] = useBoolean(false);

  return (
    <>
      <IconButton onClick={openDatasetPopup}>
        <FontAwesomeIcon fixedWidth={true} icon={faArrowUpRightFromSquare} />
      </IconButton>
      <DatasetPopup
        datasetHash={datasetHash}
        onDismiss={closeDatasetPopup}
        open={isDatasetPopupOpen}
      />
    </>
  );
};

PreviewDatasetButton.displayName = "PreviewDatasetButton";

export default memo(PreviewDatasetButton);
