import { Grid } from "@mui/joy";
import { memo } from "react";
import { MediaDataRoomInsightsComparisonItem } from "./components";

const MediaDataRoomInsightsComparison: React.FC = () => {
  return (
    <Grid container={true} spacing={4} xs={12}>
      <Grid xs={6}>
        <MediaDataRoomInsightsComparisonItem />
      </Grid>
      <Grid xs={6}>
        <MediaDataRoomInsightsComparisonItem />
      </Grid>
    </Grid>
  );
};
MediaDataRoomInsightsComparison.displayName = "MediaDataRoomInsightsComparison";

export default memo(MediaDataRoomInsightsComparison);
