import { useTestAllComputationsMutation } from "@decentriq/graphql/dist/hooks";
import { testIds } from "@decentriq/utils";
import { faListCheck, faPlay } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, CircularProgress } from "@mui/joy";
import { memo, useCallback } from "react";
import { useComputeNodesVars, useDataRoom } from "contexts";
import useTestDatasets from "features/dataNodes/hooks/useTestDatasets";
import { useDataRoomSnackbar, useNodes } from "hooks";
import { getEffectiveErrorMessage } from "utils";

const ALLOW_RUNNING_ALL = false;

type ComputeNodesActionsButtonProps = {
  dataRoomId: string;
  handleRunAllComputations: () => void;
  handleValidateComputations: () => void;
  isActionMode: boolean;
  isComputeNodeSaving?: boolean;
  isEditing: boolean;
  isRunAllButtonDisabled?: boolean;
  isRunAllButtonVisible?: boolean;
  isRunningComputations?: boolean;
  isValidateButtonDisabled?: boolean;
  isValidateButtonVisible?: boolean;
  isValidatingComputations: boolean;
};

const ComputeNodesActionsButton: React.FC<ComputeNodesActionsButtonProps> =
  memo(
    ({
      dataRoomId,
      handleRunAllComputations,
      handleValidateComputations,
      isActionMode = false,
      isComputeNodeSaving: isQuerySaving = false,
      isEditing = true,
      isRunAllButtonDisabled = false,
      isRunAllButtonVisible = true,
      isRunningComputations = false,
      isValidateButtonDisabled = false,
      isValidateButtonVisible = true,
    }) => {
      const { isPublished } = useDataRoom();
      const { computeNodesCount } = useNodes();
      const { enqueueSnackbar } = useDataRoomSnackbar();
      const { hasComputeNodeActions, readOnly } = useComputeNodesVars();
      const testDatasets = useTestDatasets();
      const [testAllComputationsMutation, { loading: isTestAllLoading }] =
        useTestAllComputationsMutation();
      const testAllComputations = useCallback(async () => {
        try {
          const testDatasetsInput = await testDatasets();
          await testAllComputationsMutation({
            variables: {
              input: { id: dataRoomId, testDatasets: testDatasetsInput },
            },
          });
          enqueueSnackbar("All computations were successfully validated");
        } catch (error) {
          enqueueSnackbar("Computation(s) did not pass the validation.", {
            context: getEffectiveErrorMessage(error),
            persist: true,
            variant: "error",
          });
        }
      }, [
        dataRoomId,
        enqueueSnackbar,
        testAllComputationsMutation,
        testDatasets,
      ]);
      // Test All Computations Button
      if (
        !isPublished &&
        !readOnly &&
        computeNodesCount > 0 &&
        isEditing &&
        isValidateButtonVisible
      ) {
        return (
          <Button
            color="secondary"
            data-testid={
              testIds.computeNode.computeNodesActionButton.testComputeNodes
            }
            disabled={
              isValidateButtonDisabled || isTestAllLoading || isQuerySaving
            }
            onClick={testAllComputations}
            startDecorator={
              isTestAllLoading || isQuerySaving ? (
                <CircularProgress sx={{ "--CircularProgress-size": "18px" }} />
              ) : (
                <FontAwesomeIcon fixedWidth={true} icon={faListCheck} />
              )
            }
            variant="solid"
          >
            Run all tests
          </Button>
        );
      }
      // Run all computations button
      return ALLOW_RUNNING_ALL &&
        isRunAllButtonVisible &&
        isActionMode &&
        hasComputeNodeActions ? (
        <Button
          data-testid="run_all_queries_btn"
          disabled={
            isRunAllButtonDisabled || isRunningComputations || isQuerySaving
          }
          onClick={handleRunAllComputations}
          startDecorator={
            isRunningComputations || isQuerySaving ? (
              <CircularProgress sx={{ "--CircularProgress-size": "18px" }} />
            ) : (
              <FontAwesomeIcon fixedWidth={true} icon={faPlay} />
            )
          }
          sx={{ mb: 1 }}
        >
          Run all
        </Button>
      ) : null;
    }
  );

ComputeNodesActionsButton.displayName = "ComputeNodesActionsButton";

export default ComputeNodesActionsButton;
