import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Modal,
  ModalDialog,
  Typography,
} from "@mui/joy";
import { memo, useCallback } from "react";
import MediaDataRoomSummary from "./MediaDataRoomSummary";

interface MediaDataRoomViewConfigurationDialogProps {
  dataRoomId: string;
  open: boolean;
  onCancel: () => void;
}

const MediaDataRoomViewConfigurationDialog: React.FC<MediaDataRoomViewConfigurationDialogProps> =
  memo(({ dataRoomId, open, onCancel }) => {
    const cancelOnClick = useCallback(() => {
      onCancel();
    }, [onCancel]);
    const cancelLabel = "Close";
    const cancelProps = {
      color: "neutral" as "neutral",
      onClick: cancelOnClick,
      variant: "plain" as "plain",
    };
    return (
      <Modal open={open}>
        <ModalDialog>
          <DialogTitle
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              display: "flex",
              justifyContent: "space-between",
              lineHeight: "1.5rem",
              p: 1,
            }}
          >
            <Typography sx={{ fontSize: "20px", fontWeight: 500 }}>
              Media Insights Clean Room configuration
            </Typography>
            <IconButton onClick={onCancel}>
              <FontAwesomeIcon fixedWidth={true} icon={faTimes} />
            </IconButton>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Box p={1}>
              <MediaDataRoomSummary />
            </Box>
            <Box
              sx={{
                borderColor: "divider",
                borderTop: 1,
                display: "flex",
                justifyContent: "space-between",
                mt: "auto",
                p: 1,
              }}
            >
              <Button {...cancelProps}>{cancelLabel}</Button>
            </Box>
          </DialogContent>
        </ModalDialog>
      </Modal>
    );
  });

export default MediaDataRoomViewConfigurationDialog;
