import { usePublishedMediaInsightsDcrQuery as usePublishedMediaInsightsDcrApolloQuery } from "@decentriq/graphql/dist/hooks";
import { memo } from "react";
import { Navigate } from "react-router-dom";
import MediaDataRoomMainbar from "../components/MediaDataRoomMainbar/MediaDataRoomMainbar";
import { MediaInsightsDcrWrapper } from "../contexts/MediaInsightsDcrContext/MediaInsightsDcrContext";
import MediaDataRoomActiveTab from "./MediaDataRoomActiveTab";
import MediaDataRoomErrorReport from "./MediaDataRoomErrorReport";

interface MediaDataRoomProps {
  id: string;
}

const MediaDataRoom: React.FC<MediaDataRoomProps> = memo(
  ({ id: dataRoomId }) => {
    const { data, loading, error } = usePublishedMediaInsightsDcrApolloQuery({
      variables: { id: dataRoomId },
    });
    if (loading) {
      return null;
    }
    if (error || !data) {
      return <Navigate replace={true} to="/datarooms" />;
    }
    const dcrData = data!.publishedMediaInsightsDcr!;
    const isDeactivated = Boolean(dcrData.deactivatedAt);
    const isStopped = dcrData.isStopped;
    const createdAt = dcrData.createdAt;
    return (
      <MediaDataRoomErrorReport
        createdAt={createdAt}
        dataRoomId={dcrData.id}
        driverAttestationHash={dcrData.driverAttestationHash}
      >
        <MediaInsightsDcrWrapper
          dataRoomId={dataRoomId}
          driverAttestationHash={dcrData.driverAttestationHash}
          isDeactivated={isDeactivated}
          isStopped={isStopped}
        >
          <MediaDataRoomMainbar id={dataRoomId} />
          <MediaDataRoomActiveTab />
        </MediaInsightsDcrWrapper>
      </MediaDataRoomErrorReport>
    );
  }
);

export default MediaDataRoom;
