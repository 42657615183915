import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  ModalDialog,
  Typography,
} from "@mui/joy";
import { JsonEditorField, Loading } from "components";

interface AdvertiserValidationReportDialogProps {
  data?: string;
  loading: boolean;
  onClose: () => void;
  open: boolean;
}

const AdvertiserValidationReportDialog: React.FC<
  AdvertiserValidationReportDialogProps
> = ({ data, loading, open, onClose }) => {
  const formattedValue =
    data && typeof data === "string"
      ? JSON.stringify(JSON.parse(data), null, 2)
      : data;
  return (
    <Modal onClose={onClose} open={open}>
      <ModalDialog>
        <DialogTitle>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography level="title-md">
              Validation report for Advertiser audience table
            </Typography>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent>
          {loading ? (
            <Loading />
          ) : (
            <JsonEditorField
              editorOptions={{
                lineNumbers: "off",
                readOnly: true,
                resizable: false,
              }}
              height={400}
              value={formattedValue}
            />
          )}
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

AdvertiserValidationReportDialog.displayName =
  "AdvertiserValidationReportDialog";
export default AdvertiserValidationReportDialog;
