// TODO: Fix joy migration
import { Box, Typography } from "@mui/joy";
import { Fragment, memo } from "react";
import MaskingConstructor from "./components/MaskingConstructor/MaskingConstructor";
import {
  AccuracySelect,
  DataSourceSelect,
  IncludeStatisticsCheckbox,
} from "./components";
import useSyntheticComputeNode from "./useSyntheticComputeNode";

interface SyntheticComputeNodeEditorProps {
  computeNodeId: string;
  readOnly?: boolean;
}

const SyntheticComputeNodeEditor: React.FC<SyntheticComputeNodeEditorProps> =
  memo(({ computeNodeId, readOnly }) => {
    const { hasDependency, dependencyId } =
      useSyntheticComputeNode(computeNodeId);
    return (
      <Box style={{ height: "100%", overflow: "auto" }}>
        <div style={{ alignItems: "center", display: "flex" }}>
          <DataSourceSelect computeNodeId={computeNodeId} readOnly={readOnly} />
          {hasDependency && (
            <AccuracySelect computeNodeId={computeNodeId} readOnly={readOnly} />
          )}
        </div>
        {hasDependency && (
          <Fragment>
            <IncludeStatisticsCheckbox
              computeNodeId={computeNodeId}
              readOnly={readOnly}
            />
            <MaskingConstructor
              computeNodeId={computeNodeId}
              dependencyId={dependencyId}
              readOnly={readOnly}
            />
          </Fragment>
        )}
        <Typography color="textSecondary" level="body-sm" mt={2}>
          The synthetic data generation produces artificial data with the same
          data schema and similar statistical properties as the original data
          source.
          <br />
          Only values of columns where 'Mask values' is deselected will appear
          in the result.
          <br />
          <br />
          To produce synthetic data at least 50 rows are required.
          <br />
          For 'Strict' or 'Strictest' modes, Differential Privacy is applied. In
          this case, at most 50.000 rows of the original data source are used as
          input to the synthetic data generation.
        </Typography>
      </Box>
    );
  });

SyntheticComputeNodeEditor.displayName = "SyntheticComputeNodeEditor";

export default SyntheticComputeNodeEditor;
