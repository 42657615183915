import { useDatasetByHashQuery } from "@decentriq/graphql/dist/hooks";
import { faFileLines, faTable } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Tooltip } from "@mui/joy";
import { format, isValid, parseISO } from "date-fns";
import { memo, useState } from "react";
import { DatasetPopup } from "features/datasets";
import { type DataType } from "models";
import { ellipsisify } from "utils";

interface DataNodeDataButtonProps {
  id: string;
  dataType: DataType;
  datasetHash?: string;
  onClick?: () => void;
  testing?: boolean;
  withActions?: boolean;
}

const DataNodeDataButton: React.FC<DataNodeDataButtonProps> = ({
  id,
  dataType,
  datasetHash,
  onClick,
  testing,
  withActions,
  ...rest
}) => {
  const { data } = useDatasetByHashQuery({
    skip: !datasetHash,
    variables: { manifestHash: datasetHash! },
  });
  const [isPopupOpen, setPopupOpen] = useState(false);
  const dataset = data?.datasetByManifestHash;
  if (!dataset) {
    return <></>;
  }
  const isDatasetOwner = dataset && dataset?.isOwner;
  const ownerEmail = dataset?.owner?.email;
  const formattedDate =
    dataset && isValid(parseISO(dataset?.createdAt || ""))
      ? format(parseISO(dataset?.createdAt || ""), "MMM d, HH:mm")
      : null;
  const dataIngestionLabel = dataType === "table" ? "dataset" : "file";
  const name = ellipsisify(dataset.name, 23);
  return (
    <>
      <Tooltip
        placement="top"
        title={
          <span>
            {`${
              isDatasetOwner ? "You" : ownerEmail
            } provisioned a ${dataIngestionLabel}, uploaded on ${formattedDate}.`}
            <br />
            Click to see more details.
          </span>
        }
      >
        <Button
          onClick={(e) => {
            e.stopPropagation();
            setPopupOpen(true);
            onClick?.();
          }}
          startDecorator={
            <FontAwesomeIcon
              fixedWidth={true}
              icon={dataType === "table" ? faTable : faFileLines}
            />
          }
          style={{
            justifyContent: "flex-start",
            maxWidth: "221px",
            minWidth: "221px",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
          {...rest}
        >
          {name}
        </Button>
      </Tooltip>
      <DatasetPopup
        datasetHash={dataset?.manifestHash}
        onDismiss={() => setPopupOpen(false)}
        open={isPopupOpen}
        testing={testing ? { dataNodeId: id } : undefined}
        withActions={withActions}
      />
    </>
  );
};

export default memo(DataNodeDataButton);
