// TODO: Fix joy migration
import { InfoTooltip } from "@decentriq/components";
import {
  useOrganizationMicrosoftDspMemberQuery,
  useUpdateOrganizationDataConnectorConfigurationsMutation,
} from "@decentriq/graphql/dist/hooks";
import {
  Box,
  FormControl,
  FormLabel,
  Option,
  Select,
  Typography,
} from "@mui/joy";
import { type ChangeEvent, memo, useCallback } from "react";
import { useUserRole } from "hooks";

export enum MICROSOFT_DSP_MEMBER {
  DROP8 = "drop8",
  NONE = "None",
}

type UpdateOrganizationDataConnectorConfigurationsVars = {
  microsoftDspMember: MICROSOFT_DSP_MEMBER;
};

type OrganizationDataConnectorConfigurationsEditorProps = {
  organizationId: string;
};

const OrganizationDataConnectorConfigurationsEditor: React.FC<
  OrganizationDataConnectorConfigurationsEditorProps
> = ({ organizationId }) => {
  const { data: organizationMicrosoftDspMemberQueryData } =
    useOrganizationMicrosoftDspMemberQuery({ variables: { organizationId } });
  const microsoftDspMember = organizationMicrosoftDspMemberQueryData
    ?.organization?.microsoftDspMember as MICROSOFT_DSP_MEMBER;

  const { isSuperAdminReadOnly } = useUserRole();

  const [updateOrganizationDataConnectorConfigurationsMutation] =
    useUpdateOrganizationDataConnectorConfigurationsMutation();

  const updateOrganizationDataConnectorConfigurations = useCallback(
    ({
      microsoftDspMember,
    }: UpdateOrganizationDataConnectorConfigurationsVars) => {
      updateOrganizationDataConnectorConfigurationsMutation({
        update: (cache) => {
          cache.modify({
            fields: {
              microsoftDspMember: () => {
                return microsoftDspMember;
              },
            },
            id: cache.identify({
              __typename: "Organization",
              id: organizationId,
            }),
          });
        },
        variables: {
          input: {
            id: organizationId,
            microsoftDspMember,
          },
        },
      });
    },
    [organizationId, updateOrganizationDataConnectorConfigurationsMutation]
  );

  return (
    <Box display="flex" flexDirection="column">
      <Typography level="title-md" sx={{ marginBottom: 1 }}>
        Data connector configurations
      </Typography>
      <FormControl>
        <FormLabel>
          Microsoft DSP seat
          <InfoTooltip tooltip="This selects the account created for Decentriq by the Microsoft DSP seat owner. The Microsoft DSP connector can only be used if this value is set. This value determines where audiences are pushed to if a user of this organisation uses the Microsoft DSP connector. Only set it if you are certain that all audiences of this organisation should go into this seat." />
        </FormLabel>
        <Select
          disabled={isSuperAdminReadOnly}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            updateOrganizationDataConnectorConfigurations({
              microsoftDspMember: event.target.value as MICROSOFT_DSP_MEMBER,
            })
          }
          value={microsoftDspMember || MICROSOFT_DSP_MEMBER.NONE}
        >
          {Object.values(MICROSOFT_DSP_MEMBER).map((members) => (
            <Option key={members} value={members}>
              {members}
            </Option>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

OrganizationDataConnectorConfigurationsEditor.displayName =
  "OrganizationDataConnectorConfigurationsEditor";

export default memo(OrganizationDataConnectorConfigurationsEditor);
