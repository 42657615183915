import { Box, Typography } from "@mui/joy";
import { memo } from "react";
import { UsersList } from "components";

const UsersPage = () => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: 1,
        }}
      >
        <Typography level="title-md">
          <strong>Manage Users</strong>
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          gap: 2,
          overflow: "hidden",
          padding: 1,
        }}
      >
        <UsersList />
      </Box>
    </>
  );
};

export default memo(UsersPage);
