// TODO: Fix joy migration
import { useDatasetByHashQuery } from "@decentriq/graphql/dist/hooks";
import { DataSourceType } from "@decentriq/graphql/dist/types";
import {
  faClock,
  faCopy,
  faDatabase,
  faFileLines,
  faGlobe,
  faHardDrive,
  faHashtag,
  faHashtagLock,
  faInputText,
  faTable,
  faUser,
  faWarning,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Grid, Tooltip, Typography } from "@mui/joy";
import { List, ListItemAvatar, ListItemButton } from "@mui/material";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { Fragment, memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { KeychainItemKind } from "services/keychain";
import { DetailsGridValue } from "components";
import { useKeychainService } from "contexts";
import {
  dataSourceTypePresentation,
  DeprovisionDatasetButton,
  ExternalConnectionsIcon,
  ExternalConnectionsIconSize,
  formatRows,
  formatSize,
  useDatasetDetailsConfig,
} from "features/datasets";
import { useCopyToClipboard } from "hooks";
import { type DataRoomTypeNames, dataRoomTypePrefix } from "models";

type DatasetInfoTabPanelProps = {
  datasetHash: string;
  visible: boolean;
};

const DatasetInfoTabPanel: React.FC<DatasetInfoTabPanelProps> = ({
  datasetHash,
  visible,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [, copyToClipboard] = useCopyToClipboard();
  const { data } = useDatasetByHashQuery({
    skip: !datasetHash || !visible,
    variables: { manifestHash: datasetHash },
  });
  const { keychain } = useKeychainService();
  const [hasSchema, setHasSchema] = useState<boolean | undefined>(undefined);
  useEffect(() => {
    if (visible) {
      keychain
        .getItem(datasetHash, KeychainItemKind.DatasetMetadata)
        .then(() => setHasSchema(true))
        .catch(() => setHasSchema(false));
    }
  }, [visible, setHasSchema, datasetHash, keychain]);
  const { disableDeprovision, disableProvisionedDataRoomNavigation } =
    useDatasetDetailsConfig();
  const dataset = data?.datasetByManifestHash;
  if (!dataset) {
    return <></>;
  }
  const publications = dataset.publications?.nodes || [];
  const isOwner = !!dataset.isOwner;
  const isTabular = hasSchema;
  return (
    <Grid columnSpacing={2} container={true} rowSpacing={2}>
      <Grid sm={6} xs={12}>
        <Typography component="div" gutterBottom={true} level="body-sm">
          <strong>Owner</strong>
        </Typography>
        <DetailsGridValue icon={faUser} value={dataset.owner.email} />
      </Grid>
      <Grid sm={6} xs={12}>
        <Typography component="div" gutterBottom={true} level="body-sm">
          <strong>Upload date</strong>
        </Typography>
        <DetailsGridValue
          icon={faClock}
          value={format(new Date(dataset?.createdAt), "dd-MM-yyyy HH:mm:ss")}
        />
      </Grid>
      {isOwner && (
        <Grid sm={6} xs={12}>
          <Typography component="div" gutterBottom={true} level="body-sm">
            <strong>Imported from</strong>
          </Typography>
          <Box sx={{ alignItems: "center", display: "flex", gap: 1 }}>
            <ExternalConnectionsIcon
              connectionType={dataset?.sourceType!}
              size={ExternalConnectionsIconSize.xs}
            />
            <Typography level="body-sm">
              {dataSourceTypePresentation.get(dataset?.sourceType!)}
            </Typography>
          </Box>
        </Grid>
      )}
      {hasSchema !== undefined && (
        <Grid sm={6} xs={12}>
          <Typography component="div" gutterBottom={true} level="body-sm">
            <strong>Type</strong>
          </Typography>
          <DetailsGridValue
            icon={isTabular ? faTable : faFileLines}
            value={isTabular ? "Tabular" : "File"}
          />
        </Grid>
      )}
      {dataset.sourceType === DataSourceType.S3 &&
        dataset.datasetImport?.config && (
          <Fragment>
            <Grid sm={6} xs={12}>
              <Typography component="div" gutterBottom={true} level="body-sm">
                <strong>Bucket</strong>
              </Typography>
              <DetailsGridValue
                icon={faDatabase}
                value={dataset.datasetImport?.config.bucket}
              />
            </Grid>
            <Grid sm={6} xs={12}>
              <Typography component="div" gutterBottom={true} level="body-sm">
                <strong>Region</strong>
              </Typography>
              <DetailsGridValue
                icon={faGlobe}
                value={dataset?.datasetImport?.config?.region}
              />
            </Grid>
          </Fragment>
        )}
      {!!dataset.statistics?.numberOfRows && (
        <Grid xs={dataset.size ? 6 : 12}>
          <Typography component="div" gutterBottom={true} level="body-sm">
            <strong>Rows</strong>
          </Typography>
          <DetailsGridValue
            icon={faHashtag}
            value={formatRows(dataset.statistics.numberOfRows)}
          />
        </Grid>
      )}
      {!!dataset.size && (
        <Grid xs={dataset.statistics?.numberOfRows ? 6 : 12}>
          <Typography component="div" gutterBottom={true} level="body-sm">
            <strong>File size</strong>
          </Typography>
          <DetailsGridValue
            icon={faHardDrive}
            value={formatSize(dataset.size)}
          />
        </Grid>
      )}
      {!!dataset.description && (
        <Grid xs={12}>
          <Typography component="div" gutterBottom={true} level="body-sm">
            <strong>Description</strong>
          </Typography>
          <DetailsGridValue icon={faInputText} value={dataset.description} />
        </Grid>
      )}
      <Grid xs={12}>
        <Typography component="div" gutterBottom={true} level="body-sm">
          <strong>Provisioned to</strong>
        </Typography>
        {publications.length ? (
          <List sx={{ p: 0 }}>
            {publications.map(({ dataRoom, leaf }) => (
              <ListItemButton
                key={dataRoom.id}
                onClick={
                  disableProvisionedDataRoomNavigation
                    ? undefined
                    : () =>
                        navigate(
                          `/datarooms/${
                            dataRoomTypePrefix[
                              dataRoom.__typename as DataRoomTypeNames
                            ]
                          }/${dataRoom.id}`
                        )
                }
                sx={{ paddingLeft: disableDeprovision ? "8px" : 0 }}
              >
                {dataset?.isOwner && !disableDeprovision ? (
                  <ListItemAvatar sx={{ minWidth: "28px" }}>
                    <DeprovisionDatasetButton
                      dataRoomId={dataRoom.id}
                      datasetId={dataset.id}
                      driverAttestationHash={dataRoom.driverAttestationHash}
                      leaf={leaf}
                    />
                  </ListItemAvatar>
                ) : null}
                <Typography level="body-sm">{dataRoom.title}</Typography>
              </ListItemButton>
            ))}
          </List>
        ) : (
          <Box sx={{ alignItems: "center", display: "flex", gap: 1 }}>
            <FontAwesomeIcon fixedWidth={true} icon={faWarning} />
            <Typography color="grey.600" fontStyle="italic" level="body-sm">
              This dataset is not provisioned to any data clean room
            </Typography>
          </Box>
        )}
      </Grid>
      <Grid sm={6} xs={12}>
        <Typography component="div" gutterBottom={true} level="body-sm">
          <strong>ID</strong>
        </Typography>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            gap: 1,
            width: "100%",
          }}
        >
          <FontAwesomeIcon fixedWidth={true} icon={faHashtagLock} />
          <Tooltip placement="top" title={datasetHash}>
            <Typography level="body-sm" noWrap={true}>
              {datasetHash}
            </Typography>
          </Tooltip>
          <Tooltip placement="top" title={<span>Copy dataset hash</span>}>
            <Button
              onClick={() => {
                if (datasetHash) {
                  copyToClipboard(datasetHash);
                  enqueueSnackbar("Dataset hash copied");
                }
              }}
              sx={{ minWidth: "14px" }}
            >
              <FontAwesomeIcon fixedWidth={true} icon={faCopy} />
            </Button>
          </Tooltip>
        </Box>
      </Grid>
    </Grid>
  );
};

DatasetInfoTabPanel.displayName = "DatasetInfoTabPanel";

export default memo(DatasetInfoTabPanel);
