import { useAuth0 } from "@auth0/auth0-react";
import { InfoTooltip } from "@decentriq/components";
import { useSubmittedRequestSigneesQuery } from "@decentriq/graphql/dist/hooks";
import { Box, Typography } from "@mui/joy";
import { memo } from "react";
import DataRoomTableParticipantAvatar from "components/entities/dataRoom/DataRoomParticipantAvatar/DataRoomParticipantAvatar";

interface SubmittedRequestSigneesProps {
  submittedRequestId: string;
}

const SubmittedRequestSignees: React.FC<SubmittedRequestSigneesProps> = ({
  submittedRequestId,
}) => {
  const { user } = useAuth0();
  const currentUserEmail = user?.email;
  const { data } = useSubmittedRequestSigneesQuery({
    variables: {
      id: submittedRequestId,
    },
  });
  const signees = data?.submittedDataRoomRequest?.signers?.nodes;
  return (
    <>
      <Typography level="body-sm">
        <strong>Affected Data Owners:</strong>
        <InfoTooltip tooltip="All participants with permissions to provision datasets to dependencies of this computation are required to approve the request." />
      </Typography>
      {signees?.length ? (
        signees.map((s) => (
          <Box alignItems="center" display="flex" gap={1} key={s.id}>
            <Box alignItems="center" display="flex">
              <DataRoomTableParticipantAvatar
                active={!!s.signature}
                name={s.userEmail}
              />
              <Typography
                style={{
                  marginLeft: "0.25rem",
                }}
              >
                {s.userEmail}
                {s.userEmail === currentUserEmail && (
                  <strong style={{ marginLeft: ".5rem" }}>(You)</strong>
                )}
              </Typography>
            </Box>
            <Box>{s.signature ? "Approved" : "Pending"}</Box>
          </Box>
        ))
      ) : (
        <Typography level="body-sm">
          {signees
            ? "no dependencies yet"
            : "Please validate the computation to determine the affected data owners."}
        </Typography>
      )}
    </>
  );
};

SubmittedRequestSignees.displayName = "SubmittedRequestSignees";

export default memo(SubmittedRequestSignees);
