import { useAuth0 } from "@auth0/auth0-react";
import { DqTable } from "@decentriq/components";
import { useDataRoomsListQuery } from "@decentriq/graphql/dist/hooks";
import { type DataRoomsListQuery } from "@decentriq/graphql/dist/types";
import { faEyeSlash, faStar } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid, Tooltip, Typography } from "@mui/joy";
import { useTheme } from "@mui/material";
import { filter } from "lodash";
import {
  type MRT_ColumnDef,
  type MRT_Row,
  type MRT_RowSelectionState,
} from "material-react-table";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { EmptyData, StateIcon, TimeAgoFormatted } from "components";
import { DataRoomInformation } from "components/entities/dataRoom";
import { dataRoomPathBuilder, DataRoomTypeNames } from "models";
import { type Organization, type User } from "types";
import { DataRoomsListFilter, FILTER_OPTIONS } from "./components";

const dataRoomsListColumns: MRT_ColumnDef<DataRoomsListItem>[] = [
  {
    Cell: ({ row }) => {
      const { palette } = useTheme();
      const {
        original: {
          __typename,
          name,
          userSettings = {
            isArchived: false,
            isFavorite: false,
          },
        },
        original,
      } = row;
      const isStopped = "isStopped" in original && original.isStopped;
      const isLocked =
        __typename === DataRoomTypeNames.PublishedDataRoom ||
        __typename === DataRoomTypeNames.PublishedMediaDataRoom ||
        __typename === DataRoomTypeNames.PublishedLookalikeMediaDataRoom ||
        __typename === DataRoomTypeNames.PublishedMediaInsightsDcr;
      return (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            maxWidth: "100%",
          }}
        >
          <StateIcon
            status={isLocked ? (isStopped ? "STOPPED" : "PUBLISHED") : "DRAFT"}
          />
          <Typography
            fontWeight="500"
            level="body-sm"
            sx={{
              margin: "0 8px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {name}
          </Typography>
          <Box sx={{ alignItems: "center", display: "flex" }}>
            {userSettings?.isFavorite && (
              <Tooltip placement="top" title="Added to favorites">
                <FontAwesomeIcon
                  color={palette.primary.main}
                  fixedWidth={true}
                  icon={faStar}
                  style={{ marginRight: "4px" }}
                />
              </Tooltip>
            )}
            {userSettings?.isArchived && (
              <FontAwesomeIcon fixedWidth={true} icon={faEyeSlash} />
            )}
          </Box>
        </div>
      );
    },
    accessorKey: "name",
    enableSorting: false,
    header: "Name",
    id: "name",
    size: 270,
  },
  {
    Cell: ({ cell }) => {
      const createdAt = cell.getValue<string>();
      return (
        <Box>
          {createdAt ? (
            <TimeAgoFormatted
              date={createdAt}
              style={{ whiteSpace: "nowrap" }}
            />
          ) : (
            "—"
          )}
        </Box>
      );
    },
    accessorKey: "createdAt",
    enableGlobalFilter: false,
    header: "Creation date",
    id: "createdAt",
  },
  {
    Cell: ({ cell }) => {
      const owner =
        cell.getValue<User & { organization: Organization }>() || {};
      return (
        <Typography level="body-sm">
          {owner?.organization?.name || "-"}
        </Typography>
      );
    },
    accessorKey: "owner",
    enableGlobalFilter: false,
    header: "Created by",
    id: "createdBy",
  },
];

type DataRoomsListItem = DataRoomsListQuery["dataRooms"]["nodes"][number];

type DataRoomsListProps = {
  exposedDataRoomTypes?: DataRoomTypeNames[];
};

const DataRoomsList: React.FC<DataRoomsListProps> = ({
  exposedDataRoomTypes = [],
}) => {
  const navigate = useNavigate();
  const { user = {} } = useAuth0();
  const { email: currentUserEmail } = user || {};
  const {
    data: { dataRooms: { nodes: dataRoomsList = [] } = {} } = {},
    loading,
  } = useDataRoomsListQuery({ fetchPolicy: "network-only" });
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const selectedDataRooms = Object.keys(rowSelection)
    .filter((key) => rowSelection[key])
    .map((key) => dataRoomsList.find(({ id }) => id === key));
  // Showing only dataroom types that are passed in the prop. If empty array - show all
  const [filterKey, setFilterKey] = useState(FILTER_OPTIONS.active.key);
  const filteredDataRoomsListByTypename = useMemo(() => {
    return exposedDataRoomTypes.length === 0
      ? dataRoomsList
      : dataRoomsList.filter(({ __typename }) =>
          exposedDataRoomTypes.includes(__typename as DataRoomTypeNames)
        );
  }, [exposedDataRoomTypes, dataRoomsList]);
  const filteredDataRoomsList = useMemo(() => {
    const {
      equalsTo,
      field: filterField,
      value: filterValue,
    } = FILTER_OPTIONS[filterKey];
    return filterKey === FILTER_OPTIONS.all.key
      ? filteredDataRoomsListByTypename
      : (filter(
          filteredDataRoomsListByTypename,
          equalsTo
            ? (dataRoom) => equalsTo!(dataRoom, currentUserEmail)
            : [filterField, filterValue]
        ) as DataRoomsListItem[]);
  }, [filteredDataRoomsListByTypename, filterKey, currentUserEmail]);
  const onTableBodyRowDoubleClick = useCallback(
    ({ row }: { row: MRT_Row<DataRoomsListItem> }) => {
      const { id, __typename } = row?.original || {};
      const dataRoomLink = dataRoomPathBuilder(
        id,
        __typename as DataRoomTypeNames
      );
      navigate(dataRoomLink);
    },
    [navigate]
  );
  // Empty state
  if (!loading && !filteredDataRoomsListByTypename.length) {
    return (
      <EmptyData
        primaryText="DCRs"
        secondaryText="You haven't created any data clean rooms yet."
      />
    );
  }
  return (
    <Box
      sx={{
        alignItems: "stretch",
        backgroundColor: "common.white",
        display: "flex",
        flex: 1,
        flexDirection: "column",
        justifyContent: "stretch",
        overflow: "hidden",
      }}
    >
      <Grid
        columnSpacing={0}
        container={true}
        sx={{ flex: 1, overflow: "hidden" }}
      >
        <Grid
          sx={{
            borderColor: "divider",
            borderRightStyle: "solid",
            borderRightWidth: "1px",
            height: "100%",
            overflow: "hidden",
          }}
          xs={6}
        >
          <DqTable
            autoSelectFirstRow={true}
            columns={dataRoomsListColumns}
            data={filteredDataRoomsList}
            enableBatchRowSelection={false}
            enableGlobalFilter={true}
            enableMultiRowSelection={false}
            enableRowSelection={true}
            enableSelectAll={false}
            enableSorting={true}
            enableTopToolbar={true}
            getRowId={(row) => row.id}
            initialState={{
              showGlobalFilter: true,
              sorting: [{ desc: true, id: "createdAt" }],
            }}
            localization={{
              noRecordsToDisplay: "No data clean rooms found",
            }}
            muiSearchTextFieldProps={{
              placeholder: "Search data clean rooms",
            }}
            muiTableBodyRowProps={({
              row,
              row: { getToggleSelectedHandler, getIsSelected },
            }) => {
              return {
                onClick: getIsSelected()
                  ? () => onTableBodyRowDoubleClick({ row })
                  : getToggleSelectedHandler(),
                onDoubleClick: () => onTableBodyRowDoubleClick({ row }),
                sx: {
                  "&:hover td:after": {
                    backgroundColor: "primary.light",
                    content: '""',
                    height: "100%",
                    left: 0,
                    opacity: 0.125,
                    position: "absolute",
                    top: 0,
                    width: "100%",
                    zIndex: -1,
                  },
                  cursor: "pointer",
                },
              };
            }}
            muiTablePaperProps={{
              sx: {
                display: "flex",
                flex: 1,
                flexDirection: "column",
                height: "100%",
                overflow: "hidden",
                width: "100%",
              },
            }}
            onRowSelectionChange={setRowSelection}
            renderTopToolbarCustomActions={() => (
              <DataRoomsListFilter
                filterKey={filterKey}
                setFilterKey={setFilterKey}
              />
            )}
            state={{
              columnVisibility: { "mrt-row-select": false },
              isLoading: loading,
              rowSelection,
              showLoadingOverlay: false,
              showProgressBars: false,
              showSkeletons: loading,
            }}
          />
        </Grid>
        {!loading ? (
          <Grid sx={{ height: "100%", overflow: "hidden" }} xs={6}>
            {selectedDataRooms.map((selectedDataRoom) =>
              selectedDataRoom ? (
                <DataRoomInformation
                  __typename={selectedDataRoom.__typename! as DataRoomTypeNames}
                  dataRoomId={selectedDataRoom.id!}
                  key={selectedDataRoom.id}
                />
              ) : null
            )}
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
};

export default DataRoomsList;
