// TODO: Fix joy migration
import { InfoTooltip } from "@decentriq/components";
import {
  faBullhorn,
  faDatabase,
  faGlasses,
  faNewspaper,
  faPlus,
  faUserTie,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { zodResolver } from "@hookform/resolvers/zod";
import { Alert, Button, Grid, Typography } from "@mui/joy";
import { memo, useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import { MediaDataRoomUserRole } from "features/mediaDataRoom/types";
import {
  MediaInsightsDcrCreateOrganizationRole,
  useCreationWizardConfiguration,
  useCreationWizardDataPartner,
  useCreationWizardPublisher,
  useCreationWizardStepper,
} from "features/MediaInsightsDcr/contexts";
import CollaborationConfigurationStepRole from "./CollaborationConfigurationStepRole";

type RoleEmailFieldName =
  | "advertiserEmails"
  | "publisherEmails"
  | "dataPartnerEmails"
  | "agencyEmails"
  | "observerEmails";

const arrayOfParticipantsEmailsSchema = z
  .array(z.string().email("Email is invalid"))
  .min(1, "At least one email is required")
  .superRefine((items, ctx) => {
    const uniqueValues = new Map<string, number>();
    items.forEach((item, idx) => {
      const firstAppearanceIndex = uniqueValues.get(item);
      if (firstAppearanceIndex !== undefined) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Email is not unique`,
          path: [idx],
        });
        return;
      }
      uniqueValues.set(item, idx);
    });
  });

const schema = z
  .object({
    advertiserEmails: arrayOfParticipantsEmailsSchema,
    agencyEmails: arrayOfParticipantsEmailsSchema,
    dataPartnerEmails: arrayOfParticipantsEmailsSchema,
    observerEmails: arrayOfParticipantsEmailsSchema,
    publisherEmails: arrayOfParticipantsEmailsSchema,
  })
  .superRefine((items, ctx) => {
    const uniqueEmailsArrayKeys = [
      "advertiserEmails" as "advertiserEmails",
      "agencyEmails" as "agencyEmails",
      "dataPartnerEmails" as "dataPartnerEmails",
      "observerEmails" as "observerEmails",
      "publisherEmails" as "publisherEmails",
    ];
    const uniqueValues = new Map<string, number>();
    uniqueEmailsArrayKeys.forEach((key) => {
      items[key].forEach((item, idx) => {
        const firstAppearanceIndex = uniqueValues.get(item);
        if (firstAppearanceIndex !== undefined) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `Email is not unique`,
            path: [key, idx],
          });
        }
        uniqueValues.set(item, idx);
      });
    });
  });
type CreateMediaCleanRoomSchemaType = z.infer<typeof schema>;

const RolesAndParticipants: React.FC = () => {
  const {
    withAgency,
    withObserver,
    setWithAgency,
    setWithObserver,
    participantsEmails,
    addParticipantEmail,
    removeParticipantEmail,
    setParticipantsEmails,
    setMainAdvertiserUserEmail,
    setMainPublisherUserEmail,
    mainPublisherUserEmail,
    mainAdvertiserUserEmail,
    withDataPartner,
  } = useCreationWizardConfiguration();
  // NOTE: Are these used for blocking the input?
  const { selectedPublisher } = useCreationWizardPublisher();
  const { selectedDataPartner } = useCreationWizardDataPartner();
  const { organizationRole } = useCreationWizardStepper();
  const defaultValues = useMemo(
    () => ({
      advertiserEmails: participantsEmails.get(
        MediaDataRoomUserRole.Advertiser
      ),
      agencyEmails: participantsEmails.get(MediaDataRoomUserRole.Agency),
      dataPartnerEmails: participantsEmails.get(
        MediaDataRoomUserRole.DataPartner
      ),
      observerEmails: participantsEmails.get(MediaDataRoomUserRole.Observer),
      publisherEmails: participantsEmails.get(MediaDataRoomUserRole.Publisher),
    }),
    [participantsEmails]
  );
  const form = useForm<CreateMediaCleanRoomSchemaType>({
    defaultValues,
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: async (data, context, options) =>
      await zodResolver(schema)(data, context, {
        ...options,
        /*
          NOTE: Clearing the names to force the nesting routine
          to use the validation error keys to determine nesting,
          since field arrays are not used and the names are "simple".
        */
        names: undefined,
      }),
  });
  const { watch } = form;
  useEffect(() => {
    const subscription = watch((data, { name, type }) => {
      if (
        name &&
        type === "change" &&
        [
          "advertiserEmails",
          "agencyEmails",
          "dataPartnerEmails",
          "observerEmails",
          "publisherEmails",
        ].includes(name) &&
        data[name as RoleEmailFieldName]
      ) {
        setParticipantsEmails((participantsEmails) => {
          const newParticipantsEmails = new Map(participantsEmails);
          const emails = (data[name as RoleEmailFieldName] || []) as string[]; // NOTE: It's weird that casting is required here
          switch (name) {
            case "advertiserEmails":
              newParticipantsEmails.set(
                MediaDataRoomUserRole.Advertiser,
                emails
              );
              break;
            case "agencyEmails":
              newParticipantsEmails.set(MediaDataRoomUserRole.Agency, emails);
              break;
            case "dataPartnerEmails":
              newParticipantsEmails.set(
                MediaDataRoomUserRole.DataPartner,
                emails
              );
              break;
            case "observerEmails":
              newParticipantsEmails.set(MediaDataRoomUserRole.Observer, emails);
              break;
            case "publisherEmails":
              newParticipantsEmails.set(
                MediaDataRoomUserRole.Publisher,
                emails
              );
              break;
            default:
              break;
          }
          return newParticipantsEmails;
        });
      }
    });
    return () => subscription.unsubscribe();
  }, [setParticipantsEmails, watch]);
  return (
    <Grid container={true} mb={1} mt={1} rowSpacing={1.5}>
      <Grid sx={{ pb: 0 }} xs={12}>
        <Typography fontWeight="fontWeightSemiBold" level="body-md">
          2. Roles and participants
        </Typography>
      </Grid>
      <FormProvider {...form}>
        <Grid xs={12}>
          <Alert sx={{ mb: 1.5 }}>
            All participants need to have a Decentriq account. Invite external
            participants via the Admin portal.
          </Alert>
          <CollaborationConfigurationStepRole
            emailValues={
              participantsEmails.get(MediaDataRoomUserRole.Publisher)!
            }
            fieldName="publisherEmails"
            icon={faNewspaper}
            mainParticipantValue={mainPublisherUserEmail}
            onMainParticipantChange={setMainPublisherUserEmail}
            roleTagLabel="Publisher"
          />
        </Grid>
        <Grid xs={12}>
          <CollaborationConfigurationStepRole
            emailValues={
              participantsEmails.get(MediaDataRoomUserRole.Advertiser)!
            }
            fieldName="advertiserEmails"
            icon={faBullhorn}
            mainParticipantValue={mainAdvertiserUserEmail}
            onMainParticipantChange={setMainAdvertiserUserEmail}
            roleTagLabel="Advertiser"
          />
        </Grid>
        {withDataPartner && (
          <Grid xs={12}>
            <CollaborationConfigurationStepRole
              emailValues={
                participantsEmails.get(MediaDataRoomUserRole.DataPartner)!
              }
              fieldName="dataPartnerEmails"
              icon={faDatabase}
              roleTagLabel="Data Partner"
            />
          </Grid>
        )}
        {withObserver && (
          <Grid xs={12}>
            <CollaborationConfigurationStepRole
              emailValues={
                participantsEmails.get(MediaDataRoomUserRole.Observer)!
              }
              fieldName="observerEmails"
              icon={faGlasses}
              roleTagLabel={
                <span>
                  Observer
                  <InfoTooltip
                    tooltip={
                      <>
                        This participant can only view statistics about the
                        overlap and the segments.
                        <br />
                        They cannot view or create any audience for activation.
                      </>
                    }
                  />
                </span>
              }
            />
          </Grid>
        )}
        {withAgency && (
          <Grid xs={12}>
            <CollaborationConfigurationStepRole
              emailValues={
                participantsEmails.get(MediaDataRoomUserRole.Agency)!
              }
              fieldName="agencyEmails"
              icon={faUserTie}
              roleTagLabel={
                <span>
                  Agency
                  <InfoTooltip tooltip="A media agency can act on behalf of the advertiser, except uploading datasets" />
                </span>
              }
            />
          </Grid>
        )}
        <Grid sx={{ pt: 0 }} xs={12}>
          {!withObserver && (
            <Button
              color="primary"
              onClick={() => setWithObserver(true)}
              startDecorator={
                <FontAwesomeIcon fixedWidth={true} icon={faPlus} />
              }
            >
              Add Observer
            </Button>
          )}
          {!withAgency && (
            <Button
              color="primary"
              onClick={() => setWithAgency(true)}
              startDecorator={
                <FontAwesomeIcon fixedWidth={true} icon={faPlus} />
              }
              sx={{ ml: 1 }}
            >
              Add Agency
            </Button>
          )}
        </Grid>
      </FormProvider>
    </Grid>
  );
};

RolesAndParticipants.displayName = "RolesAndParticipants";

export default memo(RolesAndParticipants);
