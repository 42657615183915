// TODO: Fix joy migration
import { faChartSimple } from "@fortawesome/pro-light-svg-icons";
import { Box, Tab, TabList, Tabs, Typography, useTheme } from "@mui/joy";
import { Bar } from "@nivo/bar";
import { useSize } from "ahooks";
import { memo, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { AutoSizer } from "react-virtualized";
import { EmptyData } from "components";
import {
  type AggregationData,
  type InsightsDetailedViewFormValues,
  SuppressedSegmentsInformation,
} from "features/MediaInsightsDcr";
import { useAffinityRatioSegmentDemographicsData } from "features/MediaInsightsDcr/components/MediaDataRoomInsights/components/MediaDataRoomInsightsDetailedView/hooks";

const CHART_DATA_SORT_DIRECTION_BUTTONS = [
  {
    label: "Highest",
    value: "desc",
  },
  {
    label: "Lowest",
    value: "asc",
  },
];

const AffinityRatioSegmentDemographicsBarChart: React.FC = () => {
  const { control: formControl } =
    useFormContext<InsightsDetailedViewFormValues>();

  const {
    aggregationData: affinityRatioSegmentDemographicsData,
    suppressedValues,
    hasSuppressedValues,
    hiddenValues,
    hasHiddenValues,
    possibleValues,
  } = useAffinityRatioSegmentDemographicsData();

  const { palette, breakpoints } = useTheme();
  const screenSize = useSize(document.querySelector("body"));
  const screenWidth = screenSize?.width;

  const affinityRatioSegmentDemographicsBarData = useMemo(
    () =>
      affinityRatioSegmentDemographicsData.map(
        ({ affinityRatio, aggregationName }: AggregationData) => ({
          aggregationName,
          value: affinityRatio,
        })
      ),
    [affinityRatioSegmentDemographicsData]
  );

  const chartLabelLength = useMemo(
    () => (screenWidth! > breakpoints.values.md ? 10 : 4),
    [breakpoints.values.md, screenWidth]
  );

  return (
    <Box display="flex" flex={1} flexDirection="column" height="100%">
      <Box
        alignItems="baseline"
        display="flex"
        justifyContent="space-between"
        mb={1}
        width="100%"
      >
        <Box
          alignItems="baseline"
          display="flex"
          flexDirection={{ md: "row", xs: "column" }}
        >
          <Typography fontWeight="fontWeightSemiBold" mr={1}>
            Affinity vs. Segment + Demographics
          </Typography>
          {(hasSuppressedValues || hasHiddenValues) && (
            <SuppressedSegmentsInformation
              hiddenValues={hiddenValues}
              possibleValues={possibleValues}
              suppressedValues={suppressedValues}
            />
          )}
        </Box>
        <Controller
          control={formControl}
          name="dataSortDirection"
          render={({
            field: { onChange = () => {}, value, ...restField } = {},
          }) => (
            <Tabs
              onChange={(_, newValue: boolean | "asc" | "desc") =>
                onChange(newValue)
              }
              value={value}
            >
              <TabList>
                {CHART_DATA_SORT_DIRECTION_BUTTONS.map(
                  ({ label, value }, index) => (
                    <Tab key={index} value={value}>
                      {label}
                    </Tab>
                  )
                )}
              </TabList>
            </Tabs>
          )}
        />
      </Box>
      <Box flex={1} height="100%">
        {affinityRatioSegmentDemographicsBarData.length ? (
          <AutoSizer>
            {({ width, height }) => (
              <Bar
                axisBottom={{
                  format: (value) =>
                    value.length <= chartLabelLength
                      ? value
                      : `${value.substring(0, chartLabelLength)}...`,
                  legend: "Segment",
                  legendOffset: 40,
                  legendPosition: "middle",
                  tickPadding: 5,
                  tickSize: 5,
                }}
                axisLeft={{
                  legend: "Affinity",
                  legendOffset: -48,
                  legendPosition: "middle",
                  tickPadding: 5,
                  tickSize: 5,
                }}
                colors={palette.chart["500"]}
                data={affinityRatioSegmentDemographicsBarData}
                enableGridY={true}
                enableLabel={false}
                groupMode="stacked"
                height={height}
                indexBy="aggregationName"
                key="aggregationName"
                layout="vertical"
                margin={{
                  bottom: 48,
                  left: 64,
                  top: 8,
                }}
                padding={0.2}
                tooltipLabel={({ data }) => `${data.aggregationName}`}
                width={width}
              />
            )}
          </AutoSizer>
        ) : (
          <EmptyData
            icon={faChartSimple}
            secondaryText="No chart data available"
          />
        )}
      </Box>
    </Box>
  );
};

AffinityRatioSegmentDemographicsBarChart.displayName =
  "AffinityRatioSegmentDemographicsBarChart";

export default memo(AffinityRatioSegmentDemographicsBarChart);
