import { useBoolean } from "@decentriq/hooks";
import { faFileExport, faTrashCan } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/joy";
import { Portal } from "@mui/material";
import { memo, useCallback, useMemo } from "react";
import { Actions, type ActionsItemsDefinition } from "components";
import { ActionsRawButton } from "components/base/Actions/Actions";
import { DatasetDeletionDialog } from "features/dataNodes";
import { DatasetDeletionMode } from "features/dataNodes/components/DatasetDeletionDialog/DatasetDeletionDialog";
import DatasetExportDialog from "../DatasetExportDialog/DatasetExportDialog";
import useDeleteDataset from "../DeleteDatasetButton/useDeleteDataset";

interface DatasetActionsProps {
  datasetHash: string;
  actions?: (datasetHash: string) => ActionsItemsDefinition;
  customActions?: (datasetHash: string) => ActionsItemsDefinition;
}

const DatasetActions: React.FC<DatasetActionsProps> = ({
  datasetHash,
  actions = () => {},
  customActions = () => {},
}) => {
  const [deleteDataset, isDeletionLoading] = useDeleteDataset({
    manifestHash: datasetHash,
  });
  const [
    isDatasetExportDialogOpen,
    { setTrue: openDatasetExportDialog, setFalse: closeDatasetExportDialog },
  ] = useBoolean(false);
  const [
    isDeleteDialogOpen,
    { setTrue: openDeleteDialog, setFalse: closeDeleteDialog },
  ] = useBoolean(false);
  const handleDatasetDeletion = useCallback(async () => {
    if (await deleteDataset()) {
      closeDeleteDialog();
    }
  }, [deleteDataset, closeDeleteDialog]);
  // Set default actions that are basically used in the DatasetsPage
  const { buttons: actionsButtons = [], menuLists: menuListsButtons = [] } =
    actions(datasetHash) || {};
  const defaultDatasetActions = useMemo(
    () => ({
      buttons: [
        {
          childComponent: () => (
            <Button
              color="primary"
              onClick={openDatasetExportDialog}
              startDecorator={
                <FontAwesomeIcon fixedWidth={true} icon={faFileExport} />
              }
              variant="solid"
            >
              Export dataset
            </Button>
          ),
          component: ActionsRawButton,
          datasetManifestHash: datasetHash,
          name: "Export Dataset",
          type: "Export",
        },
        ...actionsButtons,
      ],
      menuLists: [
        [
          {
            disabled: isDeletionLoading,
            icon: faTrashCan,
            isRed: true,
            name: "Delete dataset",
            onClick: openDeleteDialog,
            type: "Delete",
          },
        ],
        ...menuListsButtons,
      ],
    }),
    [
      actionsButtons,
      datasetHash,
      isDeletionLoading,
      menuListsButtons,
      openDatasetExportDialog,
      openDeleteDialog,
    ]
  );
  // If customActions prop is passed, it will fully replace defaultActions merged with actions array
  const datasetActions = customActions(datasetHash) || defaultDatasetActions;
  return (
    <Actions actions={datasetActions} inline={false}>
      <Portal>
        <DatasetExportDialog
          datasetManifestHash={datasetHash}
          onClose={closeDatasetExportDialog}
          open={isDatasetExportDialogOpen}
        />
        <DatasetDeletionDialog
          dataType={"table"}
          loading={isDeletionLoading}
          mode={DatasetDeletionMode.delete}
          onCancel={closeDeleteDialog}
          onConfirm={handleDatasetDeletion}
          open={isDeleteDialogOpen}
        />
      </Portal>
    </Actions>
  );
};

DatasetActions.displayName = "DatasetActions";

export default memo(DatasetActions);
