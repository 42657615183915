import { DqDatasetSyncStatus, SyncDirectionType } from "@decentriq/components";
import {
  DataImportExportStatus,
  type DataTargetType,
} from "@decentriq/graphql/dist/types";
import {
  Button,
  DialogActions,
  Divider,
  type DrawerProps,
  ModalDialog,
} from "@mui/joy";
import { memo } from "react";
import { useWizard } from "react-use-wizard";
import { useDatasetExport } from "../../contexts";
import { useDatasetExportResult } from "./hooks";

interface ShowStatusStepProps {
  onClose: DrawerProps["onClose"];
  syncType: DataTargetType;
}

const ShowStatusStep: React.FC<ShowStatusStepProps> = memo(
  ({ syncType, onClose }) => {
    const { previousStep } = useWizard();
    const { datasetExportId, isExportCreated, datasetExportError } =
      useDatasetExport();
    const { status = DataImportExportStatus.Pending, result } =
      useDatasetExportResult(datasetExportId as string);
    return (
      <ModalDialog>
        <DqDatasetSyncStatus
          error={datasetExportError}
          isImportExportCreated={isExportCreated}
          result={result}
          status={status}
          syncDirection={SyncDirectionType.EXPORT}
          syncType={syncType}
        />
        <Divider />
        <DialogActions>
          {status === DataImportExportStatus.Failed && (
            <Button onClick={previousStep}>Back</Button>
          )}
          <Button
            color="neutral"
            disabled={
              !(
                datasetExportError ||
                isExportCreated ||
                status === DataImportExportStatus.Failed
              )
            }
            onClick={(event) => onClose?.(event, "closeClick")}
            variant="plain"
          >
            Close
          </Button>
        </DialogActions>
      </ModalDialog>
    );
  }
);
ShowStatusStep.displayName = "ShowStatusStep";

export default ShowStatusStep;
