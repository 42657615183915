import { Button, type ButtonProps } from "@mui/joy";
import { Fragment, memo } from "react";
import { useFormState } from "react-hook-form";

const defaultResetButtonProps: ButtonProps = { children: "Cancel" };
const defaultSubmitButtonProps: ButtonProps = { children: "Publish" };

interface CreateMeasurementDcrActionsProps extends ButtonProps {
  resetButtonProps?: ButtonProps | undefined;
  submitButtonProps?: ButtonProps | undefined;
}

// NOTE: Can be exposed as generic `DqFormStateActions`
const CreateMeasurementDcrActions: React.FC<CreateMeasurementDcrActionsProps> =
  memo(
    ({
      resetButtonProps = defaultResetButtonProps,
      submitButtonProps = defaultSubmitButtonProps,
      ...props
    }) => {
      const { disabled, isSubmitting } = useFormState();
      return (
        <Fragment>
          <Button
            {...props}
            {...defaultResetButtonProps}
            {...resetButtonProps}
            disabled={disabled}
            type="reset"
          />
          <Button
            {...props}
            {...defaultSubmitButtonProps}
            {...submitButtonProps}
            color="primary"
            disabled={disabled}
            loading={isSubmitting}
            loadingPosition="start"
            type="submit"
            variant="solid"
          />
        </Fragment>
      );
    }
  );
CreateMeasurementDcrActions.displayName = "CreateMeasurementDcrActions";

export default CreateMeasurementDcrActions;
