import { Switch } from "@mui/joy";
import { useDataRoom } from "contexts";
import usePublishedDataRoomTestMode from "../hooks/usePublishedDataRoomTestMode/usePublishedDataRoomTestMode";

const TestModeSwitch: React.FC = () => {
  const { allowTestMode } = useDataRoom();
  const [checked, handleChange] = usePublishedDataRoomTestMode();
  if (!allowTestMode) {
    return <></>;
  }
  return (
    <Switch
      checked={checked}
      endDecorator="Test mode"
      onChange={handleChange}
      sx={{
        margin: 0,
        marginRight: "1rem",
      }}
      variant="solid"
    />
  );
};

export default TestModeSwitch;
