import { testIds } from "@decentriq/utils";
import { Stack } from "@mui/joy";
import { memo } from "react";
import {
  ConfigurationFormNameField,
  DisplayConfigurationActiveMarketsField,
  DisplayConfigurationCollaborativeUsersField,
  DisplayConfigurationDescriptionField,
  DisplayConfigurationLogoField,
} from "features/mediaPortalShared";
import { useDataPartnerPortal } from "../../contexts/DataPartnerPortalContext/DataPartnerPortalContext";
import { type SchemaType } from "./model";

const DataPartnerDisplayConfigurationForm: React.FC = () => {
  const { dataPartnerMarkets, organizationUsers } = useDataPartnerPortal();
  return (
    <Stack>
      <DisplayConfigurationLogoField<SchemaType>
        fieldName="logo"
        testId={
          testIds.dataPartnerPortal.upsertDisplayConfigurationDialog.logoHelper
        }
      />
      <ConfigurationFormNameField<SchemaType>
        fieldName="name"
        placeholder={`e.g. "Goldbach Group"`}
        testId={testIds.dataPartnerPortal.upsertDisplayConfigurationDialog.name}
        title="Name"
      />
      <DisplayConfigurationCollaborativeUsersField<SchemaType>
        fieldName="collaborationRequestUsers"
        optionTestId={
          testIds.dataPartnerPortal.upsertDisplayConfigurationDialog
            .collaborativeUsersSelectorOptionHelper
        }
        organizationUsers={organizationUsers}
        testId={
          testIds.dataPartnerPortal.upsertDisplayConfigurationDialog
            .collaborativeUsersSelector
        }
      />
      <DisplayConfigurationActiveMarketsField<SchemaType>
        activeMarkets={dataPartnerMarkets}
        fieldName="marketIds"
        optionTestId={
          testIds.dataPartnerPortal.upsertDisplayConfigurationDialog
            .activeMarketsSelectorOptionHelper
        }
        testId={
          testIds.dataPartnerPortal.upsertDisplayConfigurationDialog
            .activeMarketsSelector
        }
        tooltip="This is a list of all markets you operate in. Brands can search for data partners in the markets they are interested in and will be able to find and activate with you."
      />
      <DisplayConfigurationDescriptionField<SchemaType>
        fieldName="description"
        placeholder={`e.g. "The Goldbach Group is the leading network for electronic communication solutions and the competence and logistics center for the marketing of private electronic, mobile, and interactive media."`}
        testId={
          testIds.dataPartnerPortal.upsertDisplayConfigurationDialog.description
        }
        title="Description"
      />
    </Stack>
  );
};
DataPartnerDisplayConfigurationForm.displayName =
  "DataPartnerDisplayConfigurationForm";

export default memo(DataPartnerDisplayConfigurationForm);
