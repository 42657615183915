import { memo, useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useMeasurement } from "features/measurementDcr/components/CreateMeasurementDcr/hooks";
import { type SchemaType } from "features/measurementDcr/components/CreateMeasurementDcr/models";
import {
  CommonSnackbarOrigin,
  mapErrorToGeneralSnackbar,
  useGeneralSnackbar,
} from "hooks";
import { dataRoomPathBuilder, DataRoomTypeNames } from "models";

interface CreateMeasurementDcrFormProps
  extends Omit<React.HTMLProps<HTMLFormElement>, "onSubmit"> {
  children: React.ReactNode;
}

const CreateMeasurementDcrForm: React.FC<CreateMeasurementDcrFormProps> = memo(
  ({ children, ...props }) => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useGeneralSnackbar({
      origin: CommonSnackbarOrigin.DASHBOARD,
    });
    const { publish } = useMeasurement();
    const { handleSubmit } = useFormContext<SchemaType>();
    const handleCreate = useCallback(
      async (payload: SchemaType) => {
        try {
          const id = await publish({ payload });
          const dataRoomLink = dataRoomPathBuilder(
            id,
            DataRoomTypeNames.PublishedDataRoom
          );
          navigate(dataRoomLink);
        } catch (error) {
          enqueueSnackbar(
            ...mapErrorToGeneralSnackbar(
              error,
              "Error creating data clean room"
            )
          );
        }
      },
      [enqueueSnackbar, navigate, publish]
    );
    return (
      <form noValidate={true} {...props} onSubmit={handleSubmit(handleCreate)}>
        {children}
      </form>
    );
  }
);
CreateMeasurementDcrForm.displayName = "CreateMeasurementDcrForm";

export default CreateMeasurementDcrForm;
