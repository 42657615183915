import {
  useOrganizationFeaturesQuery,
  useOrganizationLicenseQuery,
  useUpdateOrganizationFeaturesMutation,
  useUpdateOrganizationSettingsMutation,
} from "@decentriq/graphql/dist/hooks";
import { OrganizationState } from "@decentriq/graphql/dist/types";
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormLabel,
  List,
  ListItem,
} from "@mui/joy";
import { memo, useCallback } from "react";
import { CommonSnackbarOrigin, useGeneralSnackbar, useUserRole } from "hooks";

interface OrganizationFeaturesEditorProps {
  organizationId: string;
}

const OrganizationFeaturesEditor: React.FC<OrganizationFeaturesEditorProps> = ({
  organizationId,
}) => {
  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: CommonSnackbarOrigin.ADMIN,
  });
  const { isSuperAdmin } = useUserRole();

  const {
    data: {
      organization: {
        hasAdvertiserFeatures = false,
        hasAnalyticsFeatures = false,
        hasPublisherFeatures = false,
        hasDataPartnerFeatures = false,
        canViewDataPartners = false,
        canViewMeasurements = false,
        allowExcludingSeedAudience = false,
        enableExtendedLookalikeQualityStatistics = false,
      } = {},
    } = {},
  } = useOrganizationFeaturesQuery({
    variables: { organizationId },
  });

  const { data: organizationLicenseData } = useOrganizationLicenseQuery({
    variables: { organizationId },
  });
  const organizationLicense = organizationLicenseData?.organization?.state;

  const [updateOrganizationFeaturesMutation] =
    useUpdateOrganizationFeaturesMutation();
  const [updateOrganizationSettingsMutation] =
    useUpdateOrganizationSettingsMutation({
      onError: (error) => {
        enqueueSnackbar("Available feature settings could not be updated.", {
          context: error?.message,
          persist: true,
          variant: "error",
        });
      },
    });

  const updateOrganizationFeatures = useCallback(
    async ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      try {
        await updateOrganizationFeaturesMutation({
          variables: {
            input: {
              id: organizationId,
              [target.name]: target.checked,
            },
          },
        });
      } catch (error) {
        enqueueSnackbar("Available feature settings could not be updated.", {
          context: error?.message,
          persist: true,
          variant: "error",
        });
      }
    },
    [organizationId, enqueueSnackbar, updateOrganizationFeaturesMutation]
  );

  // Updating this checkbox has to be in a separate mutation due to Access Policies on a backend
  const handleUpdateOrganizationSettings = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      updateOrganizationSettingsMutation({
        variables: {
          input: {
            enableExtendedLookalikeQualityStatistics: event?.target?.checked,
            organizationId,
          },
        },
      });
    },
    [organizationId, updateOrganizationSettingsMutation]
  );

  const isEditorDisabled =
    !organizationLicense ||
    [OrganizationState.Archived].includes(organizationLicense);

  const isPublisherCheckboxEnabled =
    organizationLicense === OrganizationState.Passive && isSuperAdmin;

  return (
    <FormControl>
      <FormLabel sx={{ mb: 0 }}>Features</FormLabel>
      {isSuperAdmin ? (
        <List orientation="horizontal" wrap={true}>
          <ListItem>
            <Checkbox
              checked={!!hasAdvertiserFeatures}
              disabled={isEditorDisabled}
              label="Advertiser features"
              name="hasAdvertiserFeatures"
              onChange={updateOrganizationFeatures}
            />
          </ListItem>
          <ListItem>
            <Checkbox
              checked={!!hasAnalyticsFeatures}
              disabled={isEditorDisabled}
              label="Analytics features"
              name="hasAnalyticsFeatures"
              onChange={updateOrganizationFeatures}
            />
          </ListItem>
          <ListItem>
            <Checkbox
              checked={!!hasPublisherFeatures}
              disabled={isEditorDisabled && !isPublisherCheckboxEnabled}
              label="Publisher features"
              name="hasPublisherFeatures"
              onChange={updateOrganizationFeatures}
            />
          </ListItem>
          <ListItem>
            <Checkbox
              checked={!!hasDataPartnerFeatures}
              disabled={isEditorDisabled}
              label="Data partner features"
              name="hasDataPartnerFeatures"
              onChange={updateOrganizationFeatures}
            />
          </ListItem>
          <ListItem>
            <Checkbox
              checked={!!canViewDataPartners}
              disabled={isEditorDisabled}
              label="Can view data partners"
              name="canViewDataPartners"
              onChange={updateOrganizationFeatures}
            />
          </ListItem>
          <ListItem>
            <Checkbox
              checked={!!canViewMeasurements}
              disabled={isEditorDisabled}
              label="Can view measurements"
              name="canViewMeasurements"
              onChange={updateOrganizationFeatures}
            />
          </ListItem>
          <ListItem>
            <Checkbox
              checked={!!allowExcludingSeedAudience}
              disabled={isEditorDisabled && !isPublisherCheckboxEnabled}
              label="Allow Excluding Seed Audience"
              name="allowExcludingSeedAudience"
              onChange={updateOrganizationFeatures}
            />
          </ListItem>
        </List>
      ) : (
        <Box sx={{ mb: 0.5, mt: 0.5 }}>
          {hasAdvertiserFeatures && (
            <Chip sx={{ marginRight: 1 }}>Advertiser</Chip>
          )}
          {hasAnalyticsFeatures && (
            <Chip sx={{ marginRight: 1 }}>Analytics</Chip>
          )}
          {hasPublisherFeatures && (
            <Chip sx={{ marginRight: 1 }}>Publisher</Chip>
          )}
          {hasDataPartnerFeatures && (
            <Chip sx={{ marginRight: 1 }}>Data partner</Chip>
          )}
        </Box>
      )}
      <Checkbox
        checked={!!enableExtendedLookalikeQualityStatistics}
        disabled={isEditorDisabled}
        label="Enable Extended Lookalike Quality Statistics"
        name="enableExtendedLookalikeQualityStatistics"
        onChange={handleUpdateOrganizationSettings}
        sx={({ spacing }) => ({
          padding: spacing(1, 2),
        })}
      />
    </FormControl>
  );
};

OrganizationFeaturesEditor.displayName = "OrganizationFeaturesEditor";

export default memo(OrganizationFeaturesEditor);
